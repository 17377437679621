import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import channelsStore from '@src/modules/Pages/Channels/redux/selector';
import { loadingStore } from '@src/redux/loading/selector';
import { channelsActions } from '@src/modules/Pages/Channels/redux/reducer';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import ChannelUpdateWrapper from '@src/modules/Pages/Channels/ChannelUpdate/ChannelUpdateWrapper';
var mapStateToProps = function (state) { return ({
    currentChannel: channelsStore.getCurrentChannel(state),
    isLoadingLayoutWebChat: loadingStore.getIsLoadingLayoutWebChat(state),
    isLoadingCurrentChannel: loadingStore.getIsLoadingCurrentChannel(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.UPDATE_CHANNEL, null)); },
    updateChannel: function (data) { return dispatch(channelsActions.updateChannel(data)); },
    updateDefaultCustomizations: function (data) { return dispatch(channelsActions.updateDefaultCustomizations(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ChannelUpdateWrapper);
