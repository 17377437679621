var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { connect } from 'react-redux';
import { Messages } from '@public/locales/messages';
import { notificationActions } from '@src/redux/notification/reducer';
import { clientHistoryValues, notificationTypes, requiredFieldsValues, } from '@src/global-interfaces';
import Button from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import { StyledStepWrapper } from '@src/modules/Pages/Channels/CreateChannel/styled';
import { StyledCheckboxWrapper } from '@src/modules/Pages/Brands/BrandSettings/UpdateMailInbox/styled';
import { StyledPreviousBtn, StyledSaveBtn } from '@src/components/Button/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterTwoBtnWrapper } from '@src/app/styled';
var StepTwo = function (props) {
    var currentStep = props.currentStep, clientHistory = props.clientHistory, requiredFields = props.requiredFields, setCurrentStep = props.setCurrentStep, showNotification = props.showNotification, setClientHistory = props.setClientHistory, setRequiredFields = props.setRequiredFields, resetNotificationState = props.resetNotificationState;
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var handleClientHistoryChange = function (changeEvent) {
        setClientHistory(changeEvent.target.value);
    };
    var handleRequiredFieldsChange = function (changeEvent) {
        if (requiredFields.includes(changeEvent.target.value)) {
            setRequiredFields(__spreadArray([], __read(requiredFields.filter(function (el) { return el !== changeEvent.target.value; })), false));
            return;
        }
        setRequiredFields(__spreadArray(__spreadArray([], __read(requiredFields), false), [changeEvent.target.value], false));
    };
    var nextStep = function () {
        if (requiredFields.length <= 0 || !clientHistory) {
            showWarning();
            return;
        }
        setCurrentStep(currentStep + 1);
    };
    return (React.createElement(StyledStepWrapper, null,
        React.createElement(StyledModalContentWrapper, null,
            React.createElement(StyledModalContentFieldsWrapper, null,
                React.createElement("div", null, Messages.Labels.ClientHistory),
                React.createElement(StyledCheckboxWrapper, null, clientHistoryValues.map(function (el, index) { return (React.createElement(Checkbox, { key: index, checked: clientHistory === clientHistoryValues[index].value, label: el.text, onChange: handleClientHistoryChange, value: el.value })); })),
                React.createElement("div", null, Messages.Labels.ChooseRequiredFields),
                React.createElement(StyledCheckboxWrapper, null, requiredFieldsValues.map(function (el, index) { return (React.createElement(Checkbox, { isCircle: false, key: index, checked: requiredFields.includes(el.value), label: el.text, onChange: handleRequiredFieldsChange, value: el.value })); }))),
            React.createElement(StyledModalFooterTwoBtnWrapper, null,
                React.createElement(StyledPreviousBtn, null,
                    React.createElement(Button, { title: Messages.Buttons.previous, onClick: function () { return setCurrentStep(currentStep - 1); } })),
                React.createElement(StyledSaveBtn, null,
                    React.createElement(Button, { title: Messages.Buttons.next, onClick: nextStep }))))));
};
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(null, mapDispatchToProps)(StepTwo);
