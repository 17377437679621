var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import Steps from '@src/components/Steps/Steps';
import Loader from '@src/components/Loader';
import StepOne from '@src/modules/Pages/Channels/CreateChannel/StepOne';
import StepTwo from '@src/modules/Pages/Channels/CreateChannel/StepTwo';
import StepThree from '@src/modules/Pages/Channels/CreateChannel/StepThree';
import ModalWindow from '@src/components/ModalWindow';
import { StyledLoaderWrapper } from '@src/modules/Pages/Brands/BrandSettings/CreateSmsInbox/styled';
import { StyledCreateChannelWrapper } from '@src/modules/Pages/Channels/CreateChannel/styled';
import { Messages } from '@public/locales/messages';
var CreateChannel = function (props) {
    var toggleModal = props.toggleModal, createChannel = props.createChannel, isLoadingCreateChannel = props.isLoadingCreateChannel;
    var _a = __read(useState(0), 2), currentStep = _a[0], setCurrentStep = _a[1];
    var _b = __read(useState(''), 2), channelURL = _b[0], setChannelURL = _b[1];
    var _c = __read(useState(''), 2), channelName = _c[0], setChannelName = _c[1];
    var _d = __read(useState({ value: '', text: '' }), 2), selectedBrand = _d[0], setSelectedBrand = _d[1];
    var _e = __read(useState(''), 2), clientHistory = _e[0], setClientHistory = _e[1];
    var _f = __read(useState([]), 2), requiredFields = _f[0], setRequiredFields = _f[1];
    var _g = __read(useState([]), 2), selectedLanguages = _g[0], setSelectedLanguages = _g[1];
    var _h = __read(useState({}), 2), greetingsAndPhrase = _h[0], setGreetingsAndPhrase = _h[1];
    var _j = __read(useState({}), 2), phraseNumbers = _j[0], setPhraseNumbers = _j[1];
    var onCreateChannel = function (phrases) {
        createChannel({
            channelURL: channelURL,
            channelName: channelName,
            clientHistory: clientHistory,
            requiredFields: requiredFields,
            enabledLang: selectedLanguages.map(function (el) { return el.value; }),
            greetingsAndPhrase: phrases,
            selectedBrand: selectedBrand.value,
        });
    };
    return (React.createElement(ModalWindow, { closeModalWindow: toggleModal, title: Messages.Buttons.createChannel }, isLoadingCreateChannel ? (React.createElement(StyledLoaderWrapper, null,
        React.createElement(Loader, null))) : (React.createElement(StyledCreateChannelWrapper, null,
        React.createElement(Steps, { items: [{ title: Messages.Labels.General }, { title: Messages.Labels.Authorization }, { title: Messages.Labels.Languages }], current: currentStep }),
        currentStep === 0 && (React.createElement(StepOne, { currentStep: currentStep, setCurrentStep: setCurrentStep, channelName: channelName, setChannelName: setChannelName, channelURL: channelURL, setChannelURL: setChannelURL, selectedBrand: selectedBrand, setSelectedBrand: setSelectedBrand })),
        currentStep === 1 && (React.createElement(StepTwo, { requiredFields: requiredFields, setRequiredFields: setRequiredFields, clientHistory: clientHistory, setClientHistory: setClientHistory, currentStep: currentStep, setCurrentStep: setCurrentStep })),
        currentStep === 2 && (React.createElement(StepThree, { phraseNumbers: phraseNumbers, setPhraseNumbers: setPhraseNumbers, greetingsAndPhrase: greetingsAndPhrase, setGreetingsAndPhrase: setGreetingsAndPhrase, currentStep: currentStep, onCreateChannel: onCreateChannel, setCurrentStep: setCurrentStep, selectedLanguages: selectedLanguages, setSelectedLanguages: setSelectedLanguages }))))));
};
export default CreateChannel;
