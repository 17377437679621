import { connect } from 'react-redux';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { loadingStore } from '@src/redux/loading/selector';
import { channelsActions } from '@src/modules/Pages/Channels/redux/reducer';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import CreateChannel from '@src/modules/Pages/Channels/CreateChannel/CreateChannel';
var mapStateToProps = function (state) { return ({
    isLoadingCreateChannel: loadingStore.getIsLoadingCreateChannel(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_CHANNEL, null)); },
    createChannel: function (data) { return dispatch(channelsActions.createChannel(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(CreateChannel);
