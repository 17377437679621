import { connect } from 'react-redux';
import ChannelsPage from '@src/modules/Pages/Channels/Channels';
import { appStore } from '@src/redux/app/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
var mapStateToProps = function (state) { return ({
    currentPage: appStore.currentPage(state),
    isModalOpenCreateChannel: modalWindowsStore.getIsModalOpen(state, modalTypes.CREATE_CHANNEL),
    isModalOpenUpdateChannel: modalWindowsStore.getIsModalOpen(state, modalTypes.UPDATE_CHANNEL),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function () { return dispatch(modalWindowsActions.toggleModal(modalTypes.CREATE_CHANNEL, null)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ChannelsPage);
