var Messages = {
    Success: {
        Success: '성공',
        ForgotPasswordSend: '비밀번호 변경 링크가 귀하의 이메일로 전송되었습니다',
        ResetPasswordSuccess: '비밀번호가 성공적으로 업데이트되었습니다',
        BrandCreated: '브랜드가 탄생했습니다!',
        MailInboxCreated: '메일 받은편지함이 생성되었습니다!',
        PrivacyPolicyChanged: '개인정보취급방침이 변경되었습니다!',
        SmsInboxCreated: 'SMS 받은편지함이 생성되었습니다!',
        InboxDeleted: '받은편지함이 삭제되었습니다!',
        StatusChanged: '상태가 변경되었습니다!',
        FileLoaded: '파일이 로드되었습니다!',
        AuthMail: '인증 메일!',
        DomainSettings: '도메인 설정!',
        UpdatedSmsAccounts: 'SMS 계정이 업데이트되었습니다!',
        ChanelCreated: '채널이 생성되었습니다!',
        DeleteMessenger: '메신저 삭제!',
        AddMessenger: '메신저 추가!',
        ChangedMessenger: '변경된 메신저!',
        ClientCreated: '클라이언트가 생성되었습니다!',
        ClientsImports: '클라이언트 수입!',
        GroupCreated: '그룹이 생성되었습니다!',
        ChangedAssignedAgentsInGroups: '그룹에 할당된 상담원을 변경했습니다!',
        ChangedAssignedChannelsInGroups: '그룹에서 할당된 채널을 변경했습니다!',
        ChangedAssignedTagsInGroups: '그룹에서 할당된 태그를 변경했습니다!',
        CreateNewCategory: '새 카테고리를 만드세요!',
        CreateNewPhrase: '새로운 문구를 만드세요!',
        DeleteCategory: '카테고리 삭제!',
        DeletePhrase: '문구 삭제!',
        UpdatePhrase: '문구 업데이트!',
        EditCategory: '카테고리 수정!',
        DeleteAllCategory: '모든 카테고리 삭제!',
        TagCreated: '태그가 생성되었습니다!',
        TagUpdated: '태그가 업데이트되었습니다!',
        TelegramInboxCreated: '텔레그램 받은 편지함이 생성되었습니다!',
        HeaderTextChanged: '헤더 텍스트가 변경됨',
        LogoIconUrlChanged: '로고 아이콘 URL 변경됨',
        IconUrlChanged: '아이콘 URL이 변경됨',
        BackgroundImageUrlChanged: '배경 이미지 URL이 변경됨',
        GreetingImageUrlChanged: '인사말 이미지 URL이 변경되었습니다.',
        AgentImageUrlChanged: '에이전트 이미지 URL이 변경됨',
        InputPlaceholderChanged: '입력 자리 표시자가 변경됨',
        SendImageUrlChanged: '이미지 전송 URL이 변경됨',
        AgentStatusChanged: '에이전트 상태가 변경됨',
        SelectedAgentTagsChanged: '선택한 에이전트 태그가 변경됨',
        AssignedAgentsGroupsChanged: '할당된 상담원 그룹이 변경됨',
        TeamLeadChanged: '변경된 팀 리더',
        AgentRoleChanged: '에이전트 역할이 변경됨',
        AgentInfoChanged: '에이전트 정보가 변경됨',
    },
    Errors: {
        PasswordMatching: '비밀번호가 일치하지 않습니다!',
        IncorrectEmail: '이메일이 잘못되었습니다',
        IncorrectPhone: '전화가 올바르지 않습니다.',
        IncorrectUrl: 'URL이 잘못되었습니다.',
        FieldContainSpaces: '필드에 공백이 있습니다.',
        FieldIsRequired: '필드는 필수 항목입니다.',
        NameValidation: '필드 길이는 2~40자(영문 포함)여야 합니다.',
        NumberValidation: '필드는 숫자만 포함해야 합니다.',
        PasswordValidation: '비밀번호는 알파벳과 숫자를 포함해야 하며 공백을 포함할 수 없습니다.',
        InvoiceNumberValidation: '인보이스 번호에는 라틴 문자, 숫자 및 특수 문자가 포함되어야 합니다.',
        MinimumLength: '최소 길이 {0} 기호',
        MaximumLength: '최대 길이 {0} 기호',
        ServerError: '서버 오류입니다. 나중에 시도하십시오!',
        ServerErrorFileDownloadTimeOut: '서버 오류, 파일 다운로드 시간이 초과되었습니다. 나중에 시도하십시오!',
        NotValidEmailOrPassword: '유효하지 않은 이메일 또는 비밀번호',
        UserWasNotFound: '사용자를 찾을 수 없습니다!',
        UserIsAlreadyExist: '사용자가 이미 존재합니다!',
        AgentWasBanned: '에이전트가 금지되었습니다',
        TokenExpired: '세션이 만료되었습니다. 다시 로그인하십시오',
        TelegramIdIsIncorrect: '텔레그램 아이디가 잘못되었습니다',
        ErrorWrongFileFormat: '오류, 잘못된 파일 형식!',
        FileSizeExceededWarnings: '파일 크기가 경고를 초과했습니다!',
        BrandWithSuchNameIsAlreadyExisting: '그런 이름의 브랜드는 이미 존재합니다!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: '이미 필터를 선택했습니다',
        AgentCreate: '당신은 AskMe 시스템의 새로운 에이전트입니다. 관리자 역할이 할당된 후에만 로그인할 수 있습니다.',
        NoStatisticsForTheAgent: '선택한 기간 동안 에이전트에 대한 통계 데이터가 없습니다.',
        FieldIsRequired: '필드는 필수 항목입니다.',
        ChannelMustContainAtLeastOneLanguage: '채널에는 하나 이상의 언어가 포함되어야 합니다',
    },
    Info: {
        Copied: '복사',
        PhraseBotNameAndGreetingsChanged: '문구, 봇 이름 및 인사말이 변경되었습니다',
    },
    Confirm: {},
    Message: {
        Welcome: 'AskMe에 오신 것을 환영합니다',
        SignIn: '관리자 패널에 로그인하거나 ',
        SignUp: '관리자 패널에 가입하거나 ',
        Register: '계정이 없으신가요?',
        Login: '이미 계정이 있습니까?',
        Logout: '정말 떠나시겠습니까?',
        ForgotPassword: '비밀번호를 잊으 셨나요?',
        ExcelFilesDownloading: '엑셀 파일 다운로드',
        excelFileIs: '엑셀 파일',
        Created: '생성된다',
        Creating: '만들고있다',
    },
    Accordions: {
        MailInboxes: '메일 받은 편지함',
        TelegramAccounts: 'Telegram 계정',
        SmsAccounts: 'SMS 계정',
    },
    Controls: {
        Newbie: '초보자',
        Name: '이름',
        Email: '이메일',
        Password: '비밀번호',
        RepeatPassword: '비밀번호 반복',
        Register: '등록하다',
        Login: '로그인',
        Or: '또는',
        SignUp: '가입하기',
        Send: '보내다',
        SearchFilter: '검색 필터',
        Search: '검색',
        SearchMessengers: '메신저 검색',
        SearchAgent: '검색 에이전트',
        SearchLanguage: '검색 언어',
        SearchChannel: '채널 검색',
        SearchTag: '태그 검색',
        SearchStatus: '검색 상태',
        SearchBrand: '브랜드 검색',
        SearchWhiteListTags: '화이트리스트 태그 검색',
        SearchSessionStatus: '세션 상태 검색',
        SearchAccessLevel: '검색 액세스 수준',
        SearchHasActiveDialogs: '검색에 활성 대화 상자가 있습니다.',
        GroupName: '그룹 이름*',
        Description: '설명*',
        LinkAlias: '링크 별칭*',
        CategoryName: '카테고리 이름*',
        BrandName: '상표명',
        AccessKey: '액세스 키',
        ClientName: '고객 이름',
        ClientFirstName: '클라이언트 이름',
        ClientLastName: '클라이언트 성',
        ClientPhone: '클라이언트 전화',
        ClientEmail: '클라이언트 이메일',
        PleaseWrite: '써주세요',
        MailInbox: '메일 받은 편지함',
        SmsInbox: 'SMS 받은편지함',
        TelegramInbox: 'Telegram 받은 편지함',
        ApplicationID: '애플리케이션 ID',
        ApplicationToken: '애플리케이션 토큰',
        SenderID: '발신자 ID',
        ChangeName: '이름을 바꿔라',
        ChangeURL: 'URL 변경',
        TagName: '태그 이름',
        LinkUrl: '링크 URL*',
        Greetings: '인사말',
        FastPhrase: '빠른 문구 №',
        ClickOnPaperclip: '클립 아이콘을 클릭하여 파일 선택',
        UserName: '로그인',
        Domain: '도메인*',
        Imap: 'Imap (주인:포트)*',
        SmtpTLS: 'Smtp TLS (주인:포트)*',
        SmtpSSL: 'Smtp SSL (주인:포트)*',
        AttachFile: '파일을 첨부',
        NoData: '데이터 없음',
        NoRating: '등급 없음',
        IfYouWantFinishDialog: '대화를 끝내려면 확인 버튼을 클릭하십시오.',
        AllDialogsAreVisibleToAllAgents: '모든 대화 상자는 모든 상담원에게 표시됩니다.',
        DialogueIsAssignedAutomatically: '대화가 가장 적은 상담원에게 자동으로 할당됩니다.',
        AllDialogsAreVisibleToSeveralAgents: '대화는 대화 수가 가장 적은 여러 상담원에게 표시됩니다.',
        On: '켜짐',
        Off: '끄다',
        FirstName: '이름',
        InvoiceNumber: '송장 번호',
        LastName: '성',
        SMS: 'SMS',
        New: '새로운',
        Active: '활동적인',
        Finished: '완성 된',
        Assigned: '할당 된',
        Online: '온라인',
        Offline: '오프라인',
        Break: '부서지다',
        Low: '낮은',
        Medium: '중간',
        High: '높은',
        True: '진실',
        False: '거짓',
        Date: '날짜',
        AgentName: '에이전트 이름',
        Messenger: '전령',
        Language: '언어',
        Channel: '채널',
        Tag: '꼬리표',
        Status: '상태',
        Brand: '상표',
        AccessLevel: '액세스 수준',
        WhiteListTags: '화이트리스트 태그',
        SessionStatus: '세션 상태',
        LastTags: '마지막 태그',
        HasActiveDialogs: '활성 대화 상자 있음',
        ChannelName: '채널 이름',
        WriteYourFirstName: '* 이름을 적어주세요.',
        WriteYourLastName: '* 성을 써주세요',
        WriteYourUserName: '* 사용자 이름을 작성하십시오.',
        WriteYourPhoneNumber: '* 전화번호를 적어주세요',
        WriteYourEmail: '* 이메일을 작성해주세요',
        WriteYourInvoiceNumber: '* 송장번호를 적어주세요',
        WriteYourAccountNumber: '* 계좌번호를 적어주세요',
        BotName: '봇 이름',
        Character: '문자',
        Number: '숫자',
        IdExample: '아이디 예시',
        XCharecter: 'X자',
        YNumber: 'Y-숫자',
        ThisOperationCanTakeLongTime: '이 작업은 시간이 오래 걸릴 수 있습니다!',
        ClickTiSaveExcel: '클릭하여 엑셀로 저장',
        PrivacyPolicyText: '개인 정보 보호 정책 텍스트',
        PrivacyPolicyLink: '개인정보 보호정책 링크',
        PrivacyPolicyLinkText: '개인 정보 보호 정책 링크 텍스트',
        AgentPhone: '상담원 전화',
        HeaderTextTop: '헤더 텍스트 상단',
        HeaderTextBottom: '헤더 텍스트 하단',
        LogoIconUrl: '로고 아이콘 URL',
        IconUrl: '아이콘 URL',
        ChatWindowsLogo: '채팅 창 로고',
        GreetingIconBotUrl: '인사말 아이콘 봇 URL',
        AgentMessageIconUrl: '에이전트 메시지 아이콘 URL',
        WriteMessagesPlaceholder: '메시지 자리 표시자 쓰기',
        SendImageUrl: '이미지 URL 보내기',
        ChannelURL: '채널 URL',
    },
    Selects: {
        SelectAccessLevel: '액세스 수준 선택*',
        SelectChannel: '채널 선택*',
        SelectChannels: '채널 선택',
        SelectWhiteTagsList: '흰색 태그 목록 선택*',
        SelectAgents: '에이전트 선택',
        SelectGroups: '그룹 선택',
        SelectBrand: '브랜드 선택',
        SelectTags: '태그 선택',
        ChooseProviderCountry: '제공자 국가 선택',
        SelectMessengers: '메신저 선택',
        SelectTagStatus: '태그 상태 선택',
        SelectAgent: '에이전트 선택',
        SelectEnabledLanguages: '사용 가능한 언어 선택',
        ChannelName: '채널 이름',
        ChannelURL: '채널 URL',
        SelectLanguageWebChat: '언어 선택 웹 채팅',
    },
    Labels: {
        NumberOfNewAgents: '신규 에이전트 수',
        TextForBulkMessaging: '대량 메시지를 위한 문자',
        Name: '이름',
        LastName: '성',
        Phone: '핸드폰',
        GroupName: '그룹 이름*',
        SelectBrand: '브랜드 선택',
        Channel: '채널*',
        Description: '설명*',
        AccessLevel: '액세스 수준*',
        WhiteTagsList: '화이트 태그 목록*',
        BrandName: '상표명',
        CategoryName: '카테고리 이름*',
        ChooseProviderCountry: '제공자 국가 선택',
        AccessKey: '액세스 키',
        SelectChannels: '채널 선택',
        SelectGroups: '그룹 선택',
        SelectAgents: '에이전트 선택',
        SelectTags: '태그 선택',
        SelectAccessLevel: '액세스 수준 선택*',
        Password: '비밀번호*',
        Login: '로그인',
        SelectMessengers: '메신저 선택',
        UserName: '사용자 이름',
        ClientName: '고객 이름',
        ClientFirstName: 'Client first name',
        ClientLastName: '클라이언트 성',
        ClientPhone: '클라이언트 전화',
        ClientEmail: '클라이언트 이메일',
        ClientInfo: '클라이언트 정보',
        UpdateGroup: '그룹 업데이트',
        Agents: '자치령 대표',
        UsefulPhrases: '유용한 구절',
        Channels: '채널',
        Tags: '태그',
        AddNewPhrases: '새 구문 추가',
        Phrase: '구절',
        SelectCategory: '카테고리 선택',
        EditCategory: '카테고리 수정',
        EditPhrase: '구문 수정',
        Or: '또는',
        AddNewCategory: '새 카테고리 추가',
        Dialogs: '대화 상자',
        TotalDuration: '총 기간',
        Email: '이메일',
        ClientId: '클라이언트 ID',
        ClientBanType: '클라이언트 차단 유형',
        ClientBanTime: '클라이언트 차단 시간',
        Messengers: '메신저',
        IpAddress: 'IP 주소',
        ExternalID: '외부 ID',
        UpdateBrand: '브랜드 업데이트',
        CreateMailInbox: '메일 받은 편지함 계정 만들기',
        CreateTelegramInbox: '텔레그램 계정 만들기',
        CreateSmsInbox: 'SMS 계정 만들기',
        MailInbox: '메일 받은 편지함',
        TelegramInbox: '전보 받은 편지함',
        SmsInbox: 'SMS 받은편지함',
        ApplicationID: '애플리케이션 ID',
        ApplicationToken: '애플리케이션 토큰',
        SenderID: '발신자 ID',
        ChangeName: '이름을 바꿔라',
        ChangeURL: 'URL 변경',
        TagName: '태그 이름',
        SelectTagStatus: '태그 상태 선택',
        SelectAgent: '에이전트 선택',
        UpdateTag: '태그 업데이트',
        CreateTag: '태그 생성',
        UsefulLinks: '유용한 링크',
        LinkAlias: '링크 별칭',
        LinkUrl: '링크 URL',
        Roles: '역할',
        Groups: '여러 떼',
        CreateGroups: '그룹 생성',
        TeamLead: '팀 리더',
        Ban: 'Ban',
        SelectEnabledLanguages: '사용 가능한 언어 선택',
        Greetings: '인사말',
        FastPhrase: '빠른 문구 №',
        ClientHistory: '클라이언트 기록',
        ChooseRequiredFields: '필수 필드를 선택하십시오',
        GetAllDialogs: '모든 대화 상자 가져오기',
        GetCurrentDialog: '현재 대화 상자 가져오기',
        SaveInLocalStorage: 'LocalStorage에 저장',
        SaveInSessionStorage: 'SessionStorage에 저장',
        InvoiceNumber: '송장 번호',
        AccountNumber: '계좌 번호',
        General: '일반적인',
        Authorization: '권한 부여',
        Languages: '언어',
        New: '새로운',
        Active: '활동적인',
        Inactive: '비활성',
        WaitForActivation: '활성화 대기',
        ID: 'ID',
        SMS: 'SMS',
        Low: '낮은',
        Medium: '중간',
        High: '높은',
        Hidden: '숨겨진',
        Brand: '상표',
        Group: '그룹',
        ChangeStatus: '상태 변경',
        AuthMail: '인증 메일',
        SetDomainSettings: '도메인 설정 지정',
        Domain: '도메인*',
        Imap: 'Imap (주인:포트)*',
        SmtpTLS: 'Smtp TLS (주인:포트)*',
        SmtpSSL: 'Smtp SSL (주인:포트)*',
        AttachFile: '파일을 첨부',
        ChannelSettings: '채널 설정',
        GeneralSettings: '일반 설정',
        WebChatConstructor: '웹 채팅 생성자',
        BackgroundColor: '배경색',
        MessageColor: '메시지 색상',
        URL: 'URL',
        ChannelName: '채널 이름',
        ChannelURL: '채널 URL',
        AllowSMS: 'SMS 허용',
        AllowShowAllClientDialogs: '모든 클라이언트 대화 상자 표시 허용',
        AllowCaptcha: '보안문자 허용',
        InboxSettings: '받은 편지함 설정',
        DistributionOfNewDialogs: '온라인 에이전트 간의 새 대화 배포',
        ThereAreNoAvailableMessengers: '채널에 사용할 수 있는 메신저가 없습니다! 이 채널을 소유한 브랜드를 위해 만드세요!',
        HeaderColorScheme: '헤더 색 구성표',
        GreetingColorScheme: '인사말 색 구성표',
        QuickPhrases: '빠른 구문 색 구성표',
        ClientMessage: '클라이언트 메시지 색 구성표',
        AgentMessage: '에이전트 메시지 색 구성표',
        EndDialogModal: '대화 상자 모달 색 구성표 종료',
        IconColorScheme: '아이콘 색 구성표',
        ScrollColorScheme: '스크롤 색 구성표',
        ChatWindowColor: '채팅 창 색 구성표',
        FooterColorScheme: '바닥글 색 구성표',
        AuthColorScheme: '인증 색 구성표',
        HeaderBackground: '헤더 배경',
        HeaderBorderColor: '헤더 테두리 색상',
        ColorGreetingBotName: '인사말 봇 이름 색상',
        MessageGreetingIcon: '인사말 아이콘 배경',
        MessageGreetingColor: '인사말 텍스트 색상',
        MessageGreetingBorderColor: '인사말 테두리 색상',
        MessageGreetingBackgroundColor: '인사말 배경색',
        QuickPhrasesColor: '빠른 구문 텍스트 색상',
        QuickPhrasesBackground: '빠른 문구 배경',
        QuickPhrasesBorderColor: '빠른 문구 테두리 색상',
        QuickPhrasesColorHover: '빠른 문구 호버 색상',
        QuickPhrasesBackgroundHover: '빠른 문구 배경 호버',
        QuickPhrasesBorderColorHover: '빠른 문구 테두리 색상 호버',
        ClientMessageColor: '클라이언트 메시지 텍스트 색상',
        ClientTimeColor: '클라이언트 시간 색상',
        ClientMessageBackground: '클라이언트 메시지 배경색',
        ClientMessageBorderColor: '클라이언트 메시지 테두리 색상',
        ClientLink: '클라이언트 링크',
        ClientLinkHover: '클라이언트 링크 호버',
        ClientActiveLink: '클라이언트 활성 링크',
        ClientVisitedLink: '클라이언트 방문 링크',
        ColorAgentNameAndTime: '에이전트 이름 및 시간 색상',
        MessageAgentColor: '에이전트 메시지 색상',
        MessageAgentBackgroundColor: '에이전트 메시지 배경',
        MessageAgentBorderColor: '에이전트 메시지 테두리 색상',
        AgentLink: '에이전트 링크',
        AgentHoverLink: '에이전트 호버 링크',
        AgentActiveLink: '에이전트 활성 링크',
        AgentVisitedLink: '상담원 방문 링크',
        MessageIconBackgroundAgent: '에이전트 메시지 아이콘 배경색',
        TextColor: '텍스트 색상',
        BackdropColor: '배경색',
        BackdropColorHover: '배경색 호버',
        ButtonColor: '버튼 색상',
        BackdropColorBtn: '배경 버튼 배경색',
        IconBackground: '아이콘 배경',
        ChatWindowsBodyBackground: '채팅 창 본문 배경색',
        ChatWindowsBorderColor: '채팅 창 테두리 색상',
        FooterBackground: '바닥글 배경색',
        FooterBorderColor: '바닥글 테두리 색상',
        FooterBackgroundTextArea: '바닥글 텍스트 영역 배경색',
        InputBorderColor: '테두리 색상 입력',
        RegistrationBackgroundColor: '등록 배경색',
        LabelColor: '라벨 색상',
        LabelErrorColor: '라벨 오류 색상',
        InputColor: '입력 색상',
        InputBackground: '입력 배경',
        SignUpColor: '가입 색상',
        SignUpBackground: '가입 배경색',
        Chat: '채팅',
        Minimized: '최소화',
        ScrollBackground: '스크롤 배경',
        ScrollThumbBackground: '스크롤 엄지 배경',
        UniqueClientIdentifier: '고유한 클라이언트 식별자',
        AgentsSettings: '에이전트 설정',
        ThisAgentSuperAdmin: '이 에이전트는 최고 관리자입니다. 최고 관리자 권한을 제거하려면 버튼을 클릭하세요.',
        ThisAgentNotSuperAdmin: '이 에이전트는 최고 관리자가 아닙니다. 버튼을 클릭하여 최고 관리자로 지정하세요.',
        YouCanAssignBrandTheBrand: '브랜드를 선택하는 데 필요한 관리자의 브랜드를 지정할 수 있습니다.',
        Role: '역할',
        BanningThisAgent: '이 에이전트를 금지하면 플랫폼에 로그인할 수 없습니다. 그들은 여전히 시스템에 나타날 것입니다.',
        Report: '보고서',
        SelectLanguageWebChat: '언어 선택 웹 채팅',
        FirstName: '이름',
        PhoneNumber: '전화 번호',
        Info: '정보',
        WebChatSettings: '웹 채팅 설정',
        IntegrationWithOtherCRM: '다른 CRM과의 통합',
        SettingUpInteractionBetweenSystems: '시스템 간의 상호 작용 설정',
        QuickReplies: '빠른 답장',
        BotSettings: '봇 설정',
        BotName: '봇 이름',
        EnabledPrivacyPolicy: '활성화된 개인 정보 보호 정책',
        PleaseChooseRequiredFields: '필수 필드를 선택하십시오',
        ChangeGreetingsAndPhrases: '인사말 및 문구 변경, 개인정보 보호정책',
        ChangeSmsSettings: 'SMS 설정 변경',
        ChangeCountries: '국가 변경',
        ContactsAreWorkingWithClientUniqueness: '연락처는 클라이언트 고유성을 사용하여 작업하고 있습니다. 클라이언트 고유성이 동일한 경우 필드가 필요합니다.',
        ClientUniquenessIs: '클라이언트 고유성은 다음과 같습니다.',
        PrivacyPolicyText: '개인 정보 보호 정책 텍스트',
        PrivacyPolicyLink: '개인정보 보호정책 링크',
        PrivacyPolicyLinkText: '개인 정보 보호 정책 링크 텍스트',
        AgentPhone: '상담원 전화',
        HeaderSettings: '헤더 설정',
        DisableDraggable: '드래그 가능 비활성화',
        DisableHeaderFullScreen: '헤더 전체 화면 비활성화',
        DisableHeaderHideChatWindow: '헤더 채팅 창 숨기기 비활성화',
        DisableHeaderLogo: '헤더 로고 비활성화',
        DisableHeaderShowEndDialogModal: '헤더 표시 종료 대화 모달 비활성화',
        HeaderText: '헤더 텍스트',
        HeaderTextTop: '헤더 텍스트 상단',
        HeaderTextBottom: '헤더 텍스트 하단',
        HeaderHeight: '헤더 높이',
        LogoIconUrl: '로고 아이콘 URL',
        IconUrl: '아이콘 URL',
        IconSettings: '아이콘 설정',
        ScrollSettings: '스크롤 설정',
        ScrollWidth: '스크롤 너비',
        ChatWindowsLogo: '채팅 창 로고',
        BackgroundImageSize: '배경 이미지 크기',
        GreetingSettings: '인사말 설정',
        GreetingIconBotUrl: '인사말 아이콘 봇 URL',
        QuickPhrasesSettings: '빠른 구문 설정',
        ClientMessageSettings: '클라이언트 메시지 설정',
        AgentMessageSettings: '에이전트 메시지 설정',
        AgentMessageIconUrl: '에이전트 메시지 아이콘 URL',
        EndDialogModalSettings: '대화 상자 모달 설정 종료',
        AuthWindowSettings: '인증 창 설정',
        FooterSettings: '바닥글 설정',
        WriteMessagesPlaceholder: '메시지 자리 표시자 쓰기',
        SendImageUrl: '이미지 URL 보내기',
        SendButtonIconHeight: '보내기 버튼 아이콘 높이',
        SendButtonIconWidth: '보내기 버튼 아이콘 너비',
        ChatWindowSettings: '채팅 창 설정',
        ClearAllPhrase: '모든 구문 지우기',
        AreYouSure: '확실해?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: '이 작업은 모든 구문과 모든 카테고리를 제거합니다',
        AddAgentToGroup: '그룹에 상담원 추가',
        CreateClient: '클라이언트 생성',
        ClientsImport: '클라이언트 가져오기',
        UploadExcelFile: '엑셀 파일 업로드',
        ClickOnThePaperclipIconToSelectTheExcelFile: '클립 아이콘을 클릭하여 Excel 파일을 선택합니다',
        Contacts: '콘택트 렌즈',
        Personal: '개인적인',
        FileUploadHint: '전화번호가 기입된 엑셀 파일을 업로드 해주세요. Excel 파일에는 "전화"라는 열이 포함되어야 하며 이 열에 전화번호를 입력해야 합니다. .xls 또는 .xlsx 파일만 가져올 수 있습니다.',
        ChangeGreetingsAndPhrasePrivacyPolicy: '인사말 및 문구 변경, 개인정보 보호정책',
    },
    AdditionalText: {
        AgentProfile: '에이전트 프로필',
        OutOf: '밖으로',
    },
    Pages: {
        Dialogs: {
            Title: '대화 상자',
            TableColumns: {
                Status: '상태',
                Dialog: '대화',
                Messenger: '전령',
                Channel: '채널',
                Tag: '꼬리표',
                Agent: '에이전트',
                Rating: '평가',
                Language: '언어',
                MessagesCount: '메시지 수',
                FirstMessage: '첫 번째 메시지',
                LastActivity: '마지막 활동',
            },
            Messages: '메시지',
        },
        AgentGroups: {
            Title: '에이전트 그룹',
            TableColumns: {
                Groups: '여러 떼',
                Description: '설명',
                AccessLevel: '액세스 수준',
                WhiteListTags: '화이트리스트 태그',
                CreatedAt: '만든 시간',
                UpdatedAt: '업데이트 날짜',
            },
        },
        Agents: {
            Title: '자치령 대표',
            TableColumns: {
                AgentStatus: '에이전트 상태',
                Agents: '자치령 대표',
                Email: '이메일',
                Groups: '여러 떼',
                CreatedAt: '만든 시간',
                LastActivity: '마지막 활동',
            },
        },
        Tags: {
            Title: '태그',
            TableColumns: {
                Tags: '태그',
                Dialogs: '대화 상자',
                Agents: '자치령 대표',
                UsefulLinks: '유용한 링크',
                Status: '상태',
                CreatedAt: '만든 시간',
                UpdatedAt: '업데이트 날짜',
            },
        },
        Clients: {
            Title: '클라이언트',
            TableColumns: {
                SearchName: '이름',
                AgentName: '에이전트 이름',
                Messenger: '전령',
                LastTag: '마지막 태그',
                ActiveDialog: '활성 대화 상자 있음',
                Brand: '상표',
                InternalId: '내부 ID',
                ExternalId: '외부 ID',
                Name: '고객 이름',
                FirstName: '이름',
                LastName: '성',
                Email: '이메일',
                InvoiceNumber: '송장 번호',
                DialogsCount: '대화 수',
                FirstMessage: '첫 번째 메시지',
                LastActivity: '마지막 활동',
                Tag: '꼬리표',
                Status: '상태',
                Channel: '채널',
                WriteEmail: '이메일',
            },
        },
        Channels: {
            Title: '채널',
            TableColumns: {
                ChannelId: '채널 ID',
                ChannelKey: '채널 키',
                ChannelName: '채널 이름',
                Language: '언어',
                Url: 'Url',
                CreatedAt: '만든 시간',
                UpdatedAt: '업데이트 날짜',
            },
        },
        Brands: {
            Title: '브랜드',
            TableColumns: {
                BrandsId: '브랜드 아이디',
                BrandName: '상표명',
                ClientUniqueness: '클라이언트 고유성',
                BrandChannels: '브랜드 채널',
                DialogCount: '대화 수',
                CreatedAt: '만든 시간',
                UpdatedAt: '업데이트 날짜',
            },
        },
        Reports: {
            Title: '보고서',
            ByMessengersTab: {
                Title: '메신저로 보고',
                Columns: {
                    Messenger: '전령',
                    ReceivedDialogs: '수신된 대화',
                    PositiveDialogs: '긍정적인 대화, %',
                    NegativeDialogs: '부정적인 대화, %',
                    UnratedDialogs: '평가되지 않은 대화, %',
                    AvgResponseTime: '평균 응답 시간',
                },
            },
            ByAgentsTab: {
                Title: '에이전트에 의한 보고',
                Columns: {
                    agentFullName: '에이전트 전체 이름',
                    agentSystemId: '에이전트 시스템 ID',
                    brandName: '상표명',
                    receivedDialogs: '받은 대화 상자',
                    dialogsTelegram: '대화 Telegram',
                    dialogsWebChat: '대화 Web Chat',
                    dialogsViber: '대화 Viber',
                    dialogsWhatsApp: '대화 WhatsApp',
                    dialogsFacebook: '대화 Facebook',
                    dialogsEmail: '대화 Email',
                    dialogsSms: '대화 SMS',
                    positiveDialogs: '긍정적인 대화, %',
                    negativeDialogs: '부정적인 대화, %',
                    unratedDialogs: '평가되지 않은 대화, %',
                    avgResponseTime: '평균 응답 시간',
                },
                noDataForThisDay: '오늘의 데이터가 없습니다',
            },
            ByGroupsTab: {
                Title: '그룹별 보고',
                Columns: {
                    groupSystemId: '그룹 시스템 ID',
                    groupName: '그룹 이름',
                    brandName: '상표명',
                    receivedDialogs: '받은 대화 상자',
                    positiveDialogs: '긍정적인 대화, %',
                    negativeDialogs: '부정적인 대화, %',
                    unratedDialogs: '평가되지 않은 대화, %',
                    avgResponseTime: '평균 응답 시간',
                },
            },
        },
    },
    Buttons: {
        addFilter: '필터 추가',
        reset: '초기화',
        createClient: '클라이언트 생성',
        importClient: '클라이언트 가져오기',
        createChannel: '채널 만들기',
        previous: '이전의',
        next: '다음',
        addPhrase: '구문 추가',
        addGroup: '그룹 추가',
        createBrand: '브랜드 만들기',
        addTag: '태그 추가',
        save: '구하다',
        send: '보내다',
        getStatistics: '통계 가져오기',
        setCountries: '국가 설정',
        changeStatus: '상태 변경',
        upload: '업로드',
        setDomainSettings: '도메인 설정 지정',
        login: '로그인',
        update: '업데이트',
        resetPassword: '암호를 재설정',
        addNew: '새로운 걸 더하다',
        addNewPhrase: '새 구문 추가',
        addNewCategory: '새 카테고리 추가',
        clearAll: '모두 지우기',
        loginWithGoogle: '구글로 로그인',
        loginWithFacebook: '페이스북으로 로그인',
        onPage: '페이지에서',
        addNewUsefulLinks: '새로운 유용한 링크 추가',
        backToChannels: '채널로 돌아가기',
        defaultSettings: '기본 설정',
        upgradeChannel: '채널 업그레이드',
        apply: '적용하다',
        remove: '제거하다',
        removeSuperAdmin: '최고 관리자 삭제',
        makeSuperAdmin: '최고 관리자로 설정',
        makeBrandAdmin: '브랜드 관리자로 만들기',
        removeBrandAdmin: '브랜드 관리자 제거',
        ban: '금지령',
        unban: '금지 해제',
        makeTeamLead: '팀 리더로 만들기',
        removeTeamLead: '팀 리더 제거',
        signUp: '가입하기',
        ok: '오크',
        cancel: '취소',
        bulkMessaging: '대량 메시징',
        back: '뒤',
    },
    Countries: {
        UnitedStates: '미국',
        Canada: '캐나다',
        Australia: '호주',
        CzechRepublic: '체코 공화국',
        Denmark: '덴마크',
        Hungary: '헝가리',
        Netherlands: '네덜란드',
        Sweden: '스웨덴',
        UnitedKingdom: '영국',
        France: '프랑스',
        Germany: '독일',
        Poland: '폴란드',
        Italy: '이탈리아',
        Spain: '스페인',
    },
    Languages: {
        Ukrainian: '우크라이나 인',
        Russian: '러시아인',
        English: '영어',
        Polish: '광택',
        Deutsch: '독일어',
        Spanish: '스페인의',
        Vietnamese: '베트남어',
        Portuguese: '포르투갈 인',
        Korean: '한국어',
        Italian: '이탈리아 사람',
        Arabic: '아라비아 말',
        French: '프랑스 국민',
        Azerbaijan: '아제르바이잔',
        Hungarian: '헝가리 인',
    },
    BulkMessaging: {
        exclude: '들어오지 못하게 하다',
        typeText: '텍스트 입력',
        selectClients: '클라이언트 선택',
        uploadClients: '클라이언트 업로드',
        sendingMessagesToNewClients: '새 클라이언트에게 메시지 보내기',
        sendingMessagesToExistingClients: '기존 클라이언트에 메시지 보내기',
        clientsWhoHaveDialogsWithFollowingStatus: '다음 상태의 대화 상자가 있는 클라이언트',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: '이름 또는 성 또는 사용자 이름에 이 텍스트가 있는 클라이언트',
        limitQuantity: '수량 제한',
        quantityLimit: '수량 제한',
        limitQuantityText: '브로드캐스트의 수량을 지정된 수신자 수 이하로 제한하십시오. 시스템의 최신 클라이언트가 목록에 포함됩니다.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: '마지막 메시지 시간 이전에 AskMe에 편지를 보낸 고객',
    },
};
export default Messages;
