var Messages = {
    Success: {
        Success: 'Thành công',
        ForgotPasswordSend: 'Liên kết thay đổi mật khẩu đã được gửi đến email của bạn',
        ResetPasswordSuccess: 'Mật khẩu của bạn đã được cập nhật thành công',
        BrandCreated: 'Thương hiệu được tạo ra!',
        MailInboxCreated: 'Hộp thư đến đã được tạo!',
        PrivacyPolicyChanged: 'Chính sách quyền riêng tư đã thay đổi!',
        SmsInboxCreated: 'Đã tạo hộp thư SMS!',
        InboxDeleted: 'Hộp thư đến đã bị xóa!',
        StatusChanged: 'Trạng thái đã thay đổi!',
        FileLoaded: 'Đã tải tệp!',
        AuthMail: 'Thư xác thực!',
        DomainSettings: 'Thiết lập miền!',
        UpdatedSmsAccounts: 'Cập nhật tài khoản sms!',
        ChanelCreated: 'Đã tạo kênh!',
        DeleteMessenger: 'Xóa Messenger!',
        AddMessenger: 'Thêm Messenger!',
        ChangedMessenger: 'Messenger đã thay đổi!',
        ClientCreated: 'Khách hàng đã được tạo!',
        ClientsImports: 'Khách hàng nhập khẩu!',
        GroupCreated: 'Đã tạo nhóm!',
        ChangedAssignedAgentsInGroups: 'Đã thay đổi các đại lý được chỉ định trong nhóm!',
        ChangedAssignedChannelsInGroups: 'Đã thay đổi các kênh được chỉ định trong nhóm!',
        ChangedAssignedTagsInGroups: 'Đã thay đổi các thẻ được chỉ định trong nhóm!',
        CreateNewCategory: 'Tạo danh mục mới!',
        CreateNewPhrase: 'Tạo cụm từ mới!',
        DeleteCategory: 'Xóa danh mục!',
        DeletePhrase: 'Xóa cụm từ!',
        UpdatePhrase: 'Cập nhật cụm từ!',
        EditCategory: 'Chỉnh sửa danh mục!',
        DeleteAllCategory: 'Xóa tất cả danh mục!',
        TagCreated: 'Đã tạo thẻ!',
        TagUpdated: 'Đã cập nhật thẻ!',
        TelegramInboxCreated: 'Hộp thư đến Telegram đã được tạo!',
        HeaderTextChanged: 'Văn bản tiêu đề đã thay đổi',
        LogoIconUrlChanged: 'Url biểu tượng logo đã thay đổi',
        IconUrlChanged: 'Url biểu tượng đã thay đổi',
        BackgroundImageUrlChanged: 'Url hình nền đã thay đổi',
        GreetingImageUrlChanged: 'Url hình ảnh lời chào đã thay đổi',
        AgentImageUrlChanged: 'Url hình ảnh đại lý đã thay đổi',
        InputPlaceholderChanged: 'Trình giữ chỗ đầu vào đã thay đổi',
        SendImageUrlChanged: 'Gửi hình ảnh url đã thay đổi',
        AgentStatusChanged: 'Az ügynök státusza megváltozott',
        SelectedAgentTagsChanged: 'Các thẻ tác nhân đã chọn đã thay đổi',
        AssignedAgentsGroupsChanged: 'Đã thay đổi trưởng nhóm',
        TeamLeadChanged: 'Đã thay đổi trưởng nhóm',
        AgentRoleChanged: 'Vai trò đại lý đã thay đổi',
        AgentInfoChanged: 'Thông tin đại lý đã thay đổi',
    },
    Errors: {
        PasswordMatching: 'Mật khẩu không phù hợp!',
        IncorrectEmail: 'Email không chính xác',
        IncorrectPhone: 'Điện thoại không chính xác',
        IncorrectUrl: 'Url không chính xác',
        FieldContainSpaces: 'Trường chứa khoảng trắng',
        FieldIsRequired: 'Lĩnh vực được yêu cầu',
        NameValidation: 'Độ dài trường phải từ 2 đến 40 ký tự bao gồm',
        NumberValidation: 'Trường chỉ được chứa các số',
        PasswordValidation: 'Mật khẩu phải chứa bảng chữ cái và số và không được chứa khoảng trắng',
        InvoiceNumberValidation: 'Số hóa đơn phải chứa các chữ cái Latinh, số và các ký tự đặc biệt',
        MinimumLength: 'Độ dài tối thiểu {0} ký hiệu',
        MaximumLength: 'Độ dài tối đa {0} ký hiệu',
        ServerError: 'Lỗi máy chủ, vui lòng thử lại sau!',
        ServerErrorFileDownloadTimeOut: 'Lỗi máy chủ, hết thời gian tải xuống tệp, vui lòng thử lại sau!',
        NotValidEmailOrPassword: 'Email hoặc mật khẩu không hợp lệ',
        UserWasNotFound: 'Người dùng không được tìm thấy!',
        UserIsAlreadyExist: 'Người dùng đã tồn tại!',
        AgentWasBanned: 'Đại lý đã bị cấm',
        TokenExpired: 'Phiên đã hết hạn. Vui lòng đăng nhập lại',
        TelegramIdIsIncorrect: 'Id Telegram không chính xác',
        ErrorWrongFileFormat: 'Lỗi, định dạng tệp sai!',
        FileSizeExceededWarnings: 'Kích thước tệp vượt quá cảnh báo!',
        BrandWithSuchNameIsAlreadyExisting: 'Thương hiệu với tên như vậy đã tồn tại!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Bạn đã chọn một bộ lọc',
        AgentCreate: 'Bạn là đại lý mới trong hệ thống AskMe. Xin lưu ý rằng bạn chỉ có thể đăng nhập sau khi bạn đã được chỉ định vai trò quản trị viên!',
        NoStatisticsForTheAgent: 'Không có dữ liệu thống kê cho đại lý trong khoảng thời gian đã chọn',
        FieldIsRequired: 'Lĩnh vực được yêu cầu',
        ChannelMustContainAtLeastOneLanguage: 'Kênh phải chứa ít nhất một ngôn ngữ',
    },
    Info: {
        Copied: 'đã sao chép',
        PhraseBotNameAndGreetingsChanged: 'Các cụm từ, tên bot và lời chào đã thay đổi',
    },
    Confirm: {},
    Message: {
        Welcome: 'Chào mừng đến với AskMe',
        SignIn: 'Đăng nhập vào Bảng điều khiển quản trị hoặc truy cập ',
        SignUp: 'Đăng ký Bảng điều khiển quản trị hoặc truy cập ',
        Register: 'Không có tài khoản với chúng tôi?',
        Login: 'Bạn co săn san để tạo một tai khoản?',
        Logout: 'Bạn có thực sự muốn rời đi?',
        ForgotPassword: 'Quên mật khẩu?',
        ExcelFilesDownloading: 'Tải xuống tệp Excel',
        excelFileIs: 'tập tin excel',
        Created: 'được tạo ra',
        Creating: 'đang tạo',
    },
    Accordions: {
        MailInboxes: 'Hộp thư đến',
        TelegramAccounts: 'Tài khoản Telegram',
        SmsAccounts: 'Tài khoản SMS',
    },
    Controls: {
        Newbie: 'Thành viên mới',
        Name: 'Tên',
        Email: 'E-mail',
        Password: 'Mật khẩu',
        RepeatPassword: 'lặp lại mật khẩu',
        Register: 'Đăng ký',
        Login: 'Đăng nhập',
        Or: 'HOẶC LÀ',
        SignUp: 'Đăng ký',
        Send: 'Gửi',
        SearchFilter: 'Bộ lọc tìm kiếm',
        Search: 'Tìm kiếm',
        SearchMessengers: 'Tìm kiếm sứ giả',
        SearchAgent: 'Đại lý tìm kiếm',
        SearchLanguage: 'Tìm kiếm ngôn ngữ',
        SearchChannel: 'Kênh tìm kiếm',
        SearchTag: 'Thẻ tìm kiếm',
        SearchStatus: 'Trạng thái tìm kiếm',
        SearchBrand: 'Tìm kiếm thương hiệu',
        SearchWhiteListTags: 'Tìm kiếm thẻ danh sách trắng',
        SearchSessionStatus: 'Search session status',
        SearchAccessLevel: 'Search access level',
        SearchHasActiveDialogs: 'Search has active dialogs',
        GroupName: 'Tên nhóm*',
        Description: 'Sự miêu tả*',
        LinkAlias: 'Bí danh liên kết*',
        CategoryName: 'Tên danh mục*',
        BrandName: 'Tên thương hiệu',
        AccessKey: 'Khóa truy cập',
        ClientName: 'Tên người dùng khách hàng',
        ClientFirstName: 'Tên khách hàng',
        ClientLastName: 'Họ của khách hàng',
        ClientPhone: 'Điện thoại khách hàng',
        ClientEmail: 'Email khách hàng',
        PleaseWrite: 'Làm ơn hãy viết',
        MailInbox: 'Hộp thư đến',
        SmsInbox: 'Hộp thư đến SMS',
        TelegramInbox: 'Hộp thư đến Telegram',
        ApplicationID: 'ID ứng dụng',
        ApplicationToken: 'Mã thông báo ứng dụng',
        SenderID: 'ID người gửi',
        ChangeName: 'Đổi tên',
        ChangeURL: 'Thay đổi URL',
        TagName: 'Tag tên',
        LinkUrl: 'Liên kết url*',
        Greetings: 'Lời chào hỏi',
        FastPhrase: 'Cụm từ nhanh №',
        ClickOnPaperclip: 'Nhấp vào biểu tượng kẹp giấy để chọn tệp',
        UserName: 'đăng nhập',
        Domain: 'Lãnh địa*',
        Imap: 'Imap (máy chủ: cổng)*',
        SmtpTLS: 'Smtp TLS (máy chủ: cổng)*',
        SmtpSSL: 'Smtp SSL (máy chủ: cổng)*',
        AttachFile: 'Đính kèm tệp',
        NoData: 'không có dữ liệu',
        NoRating: 'không có giá',
        IfYouWantFinishDialog: 'Nếu bạn muốn kết thúc hộp thoại, hãy nhấp vào nút OK',
        AllDialogsAreVisibleToAllAgents: 'Tất cả các hộp thoại đều hiển thị cho tất cả các đại lý',
        DialogueIsAssignedAutomatically: 'Cuộc hội thoại được chỉ định tự động cho tác nhân có số cuộc đối thoại ít nhất',
        AllDialogsAreVisibleToSeveralAgents: 'Các hộp thoại được hiển thị cho một số tác nhân có số lượng hộp thoại ít nhất',
        On: 'Trên',
        Off: 'Tắt',
        FirstName: 'Tên đầu tiên',
        InvoiceNumber: 'Số hóa đơn',
        LastName: 'Họ',
        SMS: 'Tin nhắn',
        New: 'Mới mẻ',
        Active: 'Tích cực',
        Finished: 'Đã kết thúc',
        Assigned: 'Giao',
        Online: 'Trực tuyến',
        Offline: 'Ngoại tuyến',
        Break: 'Nghỉ',
        Low: 'Thấp',
        Medium: 'Trung bình',
        High: 'Cao',
        True: 'Thật',
        False: 'Sai',
        Date: 'Ngày tháng',
        AgentName: 'Tên đại lý',
        Messenger: 'Tin nhắn',
        Language: 'Ngôn ngữ',
        Channel: 'Kênh truyền hình',
        Tag: 'Nhãn',
        Status: 'Trạng thái',
        Brand: 'Thương hiệu',
        AccessLevel: 'Cấp độ truy cập',
        WhiteListTags: 'Thẻ danh sách trắng',
        SessionStatus: 'Trạng thái phiên',
        LastTags: 'Thẻ cuối cùng',
        HasActiveDialogs: 'Có hộp thoại hoạt động',
        ChannelName: 'Tên kênh',
        WriteYourFirstName: '* Vui lòng viết tên của bạn',
        WriteYourLastName: '* Vui lòng viết họ của bạn',
        WriteYourUserName: '* Vui lòng viết tên người dùng',
        WriteYourPhoneNumber: '* Vui lòng ghi số điện thoại',
        WriteYourEmail: '* Vui lòng viết email của bạn',
        WriteYourInvoiceNumber: '* Vui lòng viết số hóa đơn của bạn',
        WriteYourAccountNumber: '* Vui lòng ghi số tài khoản của bạn',
        BotName: 'Tên bot',
        Character: 'nhân vật',
        Number: 'con số',
        XCharecter: 'X-charecter',
        YNumber: 'Y-số',
        IdExample: 'Ví dụ về ID',
        ThisOperationCanTakeLongTime: 'Thao tác này có thể mất nhiều thời gian!',
        ClickTiSaveExcel: 'Bấm để lưu trong Excel',
        PrivacyPolicyText: 'Văn bản chính sách quyền riêng tư',
        PrivacyPolicyLink: 'Liên kết chính sách quyền riêng tư',
        PrivacyPolicyLinkText: 'Văn bản liên kết chính sách quyền riêng tư',
        AgentPhone: 'Điện thoại đại lý',
        HeaderTextTop: 'Tiêu đề văn bản trên cùng',
        HeaderTextBottom: 'Tiêu đề văn bản dưới cùng',
        LogoIconUrl: 'URL biểu tượng biểu tượng',
        IconUrl: 'URL biểu tượng',
        ChatWindowsLogo: 'Biểu trưng cửa sổ trò chuyện',
        GreetingIconBotUrl: 'Url bot biểu tượng lời chào',
        AgentMessageIconUrl: 'URL biểu tượng tin nhắn đặc vụ',
        WriteMessagesPlaceholder: 'Viết tin nhắn giữ chỗ',
        SendImageUrl: 'Gửi URL hình ảnh',
        ChannelURL: 'URL kênh',
    },
    Selects: {
        SelectAccessLevel: 'Chọn cấp độ truy cập*',
        SelectChannel: 'Chọn kênh*',
        SelectChannels: 'Chọn kênh',
        SelectWhiteTagsList: 'Chọn danh sách thẻ trắng*',
        SelectAgents: 'Chọn đại lý',
        SelectGroups: 'Chọn nhóm',
        SelectBrand: 'Chọn nhãn hiệu',
        SelectTags: 'Chọn thẻ',
        ChooseProviderCountry: 'Chọn quốc gia của nhà cung cấp',
        SelectMessengers: 'Chọn sứ giả',
        SelectTagStatus: 'Chọn trạng thái thẻ',
        SelectAgent: 'Chọn đại lý',
        SelectEnabledLanguages: 'Chọn ngôn ngữ đã bật',
        ChannelName: 'Tên kênh',
        ChannelURL: 'URL kênh',
        SelectLanguageWebChat: 'Chọn ngôn ngữ Trò chuyện trên web',
    },
    Labels: {
        NumberOfNewAgents: 'Số lượng đại lý mới',
        TextForBulkMessaging: 'Nhắn tin hàng loạt',
        Name: 'Tên',
        LastName: 'Họ',
        Phone: 'Điện thoại',
        GroupName: 'Tên nhóm*',
        SelectBrand: 'Chọn nhãn hiệu',
        Channel: 'Kênh*',
        Description: 'Sự miêu tả*',
        AccessLevel: 'Cấp độ truy cập*',
        WhiteTagsList: 'Danh sách thẻ trắng*',
        BrandName: 'Tên thương hiệu',
        CategoryName: 'Tên danh mục*',
        ChooseProviderCountry: 'Chọn quốc gia của nhà cung cấp',
        AccessKey: 'Khóa truy cập',
        SelectChannels: 'Chọn kênh',
        SelectGroups: 'Chọn nhóm',
        SelectAgents: 'Chọn đại lý',
        SelectTags: 'Chọn thẻ',
        SelectAccessLevel: 'Chọn cấp độ truy cập*',
        Password: 'Mật khẩu*',
        Login: 'Đăng nhập',
        SelectMessengers: 'Chọn sứ giả',
        UserName: 'Tên tài khoản',
        ClientName: 'Tên người dùng khách hàng',
        ClientFirstName: 'Tên khách hàng',
        ClientLastName: 'Họ của khách hàng',
        ClientPhone: 'Điện thoại khách hàng',
        ClientEmail: 'Email khách hàng',
        ClientInfo: 'Thông tin khách hàng',
        UpdateGroup: 'Cập nhật nhóm',
        Agents: 'Đại lý',
        UsefulPhrases: 'Cụm từ hữu ích',
        Channels: 'Kênh truyền hình',
        Tags: 'Thẻ',
        AddNewPhrases: 'Thêm các cụm từ mới',
        Phrase: 'Cụm từ',
        SelectCategory: 'Chọn danh mục',
        EditCategory: 'Chỉnh sửa danh mục',
        EditPhrase: 'Chỉnh sửa cụm từ',
        Or: 'hoặc là',
        AddNewCategory: 'Thêm danh mục mới',
        Dialogs: 'Hộp thoại',
        TotalDuration: 'Tổng thời gian',
        Email: 'E-mail',
        ClientId: 'Id khách hàng',
        ClientBanType: 'Loại cấm khách hàng',
        ClientBanTime: 'Thời gian cấm khách hàng',
        Messengers: 'Người đưa tin',
        IpAddress: 'địa chỉ IP',
        ExternalID: 'ID bên ngoài',
        UpdateBrand: 'Cập nhật thương hiệu',
        CreateMailInbox: 'Tạo tài khoản hộp thư đến',
        CreateTelegramInbox: 'Tạo tài khoản Telegram',
        CreateSmsInbox: 'Tạo tài khoản SMS',
        MailInbox: 'Hộp thư đến',
        TelegramInbox: 'Hộp thư đến Telegram',
        SmsInbox: 'Hộp thư đến SMS',
        ApplicationID: 'ID ứng dụng',
        ApplicationToken: 'Mã thông báo ứng dụng',
        SenderID: 'ID người gửi',
        ChangeName: 'Đổi tên',
        ChangeURL: 'Thay đổi URL',
        TagName: 'Tag tên',
        SelectTagStatus: 'Chọn trạng thái thẻ',
        SelectAgent: 'Chọn đại lý',
        UpdateTag: 'Cập nhật thẻ',
        CreateTag: 'Tạo thẻ',
        UsefulLinks: 'Liên kết hữu ích',
        LinkAlias: 'Bí danh liên kết',
        LinkUrl: 'Liên kết url',
        Roles: 'Vai trò',
        Groups: 'Các nhóm',
        CreateGroups: 'Tạo nhóm',
        TeamLead: 'Trưởng nhóm',
        Ban: 'Lệnh cấm',
        SelectEnabledLanguages: 'Chọn ngôn ngữ đã bật',
        Greetings: 'Lời chào hỏi',
        FastPhrase: 'Cụm từ nhanh №',
        ClientHistory: 'Lịch sử khách hàng',
        ChooseRequiredFields: 'Vui lòng chọn các trường bắt buộc',
        GetAllDialogs: 'Nhận tất cả các hộp thoại',
        GetCurrentDialog: 'Nhận hộp thoại hiện tại',
        SaveInLocalStorage: 'Lưu trong LocalStorage',
        SaveInSessionStorage: 'Lưu trong SessionStorage',
        InvoiceNumber: 'Số hóa đơn',
        AccountNumber: 'Số tài khoản',
        General: 'Tổng quan',
        Authorization: 'Ủy quyền',
        Languages: 'Ngôn ngữ',
        New: 'Mới',
        Active: 'Tích cực',
        Inactive: 'Không hoạt động',
        WaitForActivation: 'Chờ kích hoạt',
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Thấp',
        Medium: 'Trung bình',
        High: 'Cao',
        Hidden: 'Ẩn giấu',
        Brand: 'Nhãn hiệu',
        Group: 'Tập đoàn',
        ChangeStatus: 'Thay đổi trạng thái',
        AuthMail: 'Thư xác thực',
        SetDomainSettings: 'Đặt thiết lập miền',
        Domain: 'Lãnh địa*',
        Imap: 'Imap (máy chủ: cổng)*',
        SmtpTLS: 'Smtp TLS (máy chủ: cổng)*',
        SmtpSSL: 'Smtp SSL (máy chủ: cổng)*',
        AttachFile: 'Đính kèm tệp',
        ChannelSettings: 'Cài đặt kênh',
        GeneralSettings: 'Cài đặt chung',
        WebChatConstructor: 'Trình tạo trò chuyện web',
        BackgroundColor: 'Màu nền',
        MessageColor: 'Màu tin nhắn',
        URL: 'URL',
        ChannelName: 'Tên kênh',
        ChannelURL: 'URL kênh',
        AllowSMS: 'Cho phép SMS',
        AllowShowAllClientDialogs: 'Cho phép hiển thị tất cả các hộp thoại khách hàng',
        AllowCaptcha: 'Cho phép Captcha',
        InboxSettings: 'Cài đặt Hộp thư đến',
        DistributionOfNewDialogs: 'Phân phối các hộp thoại mới giữa các đại lý trực tuyến',
        ThereAreNoAvailableMessengers: 'Không có sứ giả nào cho kênh! Hãy tạo chúng cho thương hiệu sở hữu kênh này!',
        HeaderColorScheme: 'Bảng màu tiêu đề',
        GreetingColorScheme: 'Bảng màu lời chào',
        QuickPhrases: 'Bảng phối màu Cụm từ nhanh',
        ClientMessage: 'Bảng màu tin nhắn khách hàng',
        AgentMessage: 'Sơ đồ màu tin nhắn đại lý',
        EndDialogModal: 'Bảng phối màu phương thức hộp thoại kết thúc',
        IconColorScheme: 'Bảng màu biểu tượng',
        ScrollColorScheme: 'Bảng màu cuộn',
        ChatWindowColor: 'Bảng màu cửa sổ trò chuyện',
        FooterColorScheme: 'Bảng phối màu chân trang',
        AuthColorScheme: 'Phối màu Auth',
        HeaderBackground: 'Nền tiêu đề',
        HeaderBorderColor: 'Màu đường viền đầu trang',
        ColorGreetingBotName: 'Màu tên Bot chào mừng',
        MessageGreetingIcon: 'Biểu tượng chào mừng nền',
        MessageGreetingColor: 'Màu văn bản lời chào',
        MessageGreetingBorderColor: 'Lời chào mừng Màu viền',
        MessageGreetingBackgroundColor: 'Lời chào mừng Màu nền',
        QuickPhrasesColor: 'Cụm từ nhanh Màu văn bản',
        QuickPhrasesBackground: 'Nền cụm từ nhanh',
        QuickPhrasesBorderColor: 'Cụm từ nhanh Màu viền',
        QuickPhrasesColorHover: 'Cụm từ nhanh Màu di chuột',
        QuickPhrasesBackgroundHover: 'Cụm từ nhanh Di chuột qua nền',
        QuickPhrasesBorderColorHover: 'Cụm từ nhanh Di chuột màu viền',
        ClientMessageColor: 'Tin nhắn khách hàng Màu văn bản',
        ClientTimeColor: 'Màu thời gian khách hàng',
        ClientMessageBackground: 'Tin nhắn khách hàng Màu nền',
        ClientMessageBorderColor: 'Tin nhắn khách hàng Màu viền',
        ClientLink: 'Liên kết khách hàng',
        ClientLinkHover: 'Di chuột liên kết khách hàng',
        ClientActiveLink: 'Liên kết khách hàng đang hoạt động',
        ClientVisitedLink: 'Liên kết khách hàng đã ghé thăm',
        ColorAgentNameAndTime: 'Tên đại lý và màu thời gian',
        MessageAgentColor: 'Màu tin nhắn đại lý',
        MessageAgentBackgroundColor: 'Thông điệp đại lý Nền',
        MessageAgentBorderColor: 'Thông điệp đại lý Màu viền',
        AgentLink: 'Liên kết đại lý',
        AgentHoverLink: 'Liên kết Agent Hover',
        AgentActiveLink: 'Liên kết tác nhân đang hoạt động',
        AgentVisitedLink: 'Liên kết đã thăm đại lý',
        MessageIconBackgroundAgent: 'Màu nền biểu tượng tin nhắn đại lý',
        TextColor: 'Văn bản màu',
        BackdropColor: 'Màu phông nền',
        BackdropColorHover: 'Di chuột màu phông nền',
        ButtonColor: 'Nút màu',
        BackdropColorBtn: 'Màu nền của nút phông nền',
        IconBackground: 'Nền biểu tượng',
        ChatWindowsBodyBackground: 'Màu nền nội dung cửa sổ trò chuyện',
        ChatWindowsBorderColor: 'Màu đường viền cửa sổ trò chuyện',
        FooterBackground: 'Màu nền của chân trang',
        FooterBorderColor: 'Màu đường viền chân trang',
        FooterBackgroundTextArea: 'Màu nền khu vực văn bản chân trang',
        InputBorderColor: 'Màu đường viền đầu vào',
        RegistrationBackgroundColor: 'Màu nền đăng ký',
        LabelColor: 'Màu nhãn',
        LabelErrorColor: 'Màu lỗi nhãn',
        InputColor: 'Màu đầu vào',
        InputBackground: 'Nền đầu vào',
        SignUpColor: 'Màu đăng ký',
        SignUpBackground: 'Màu nền đăng ký',
        Chat: 'Trò chuyện',
        Minimized: 'Giảm thiểu',
        ScrollBackground: 'Nền cuộn',
        ScrollThumbBackground: 'Cuộn ngón tay cái nền',
        UniqueClientIdentifier: 'Mã định danh khách hàng duy nhất',
        AgentsSettings: 'Cài đặt đại lý',
        ThisAgentSuperAdmin: 'Tác nhân này là quản trị viên cấp cao, hãy nhấp vào nút để xóa quyền quản trị viên cấp cao',
        ThisAgentNotSuperAdmin: 'Người đại diện này không phải là quản trị viên cấp cao, hãy nhấp vào nút để biến anh ta thành quản trị viên cấp cao',
        YouCanAssignBrandTheBrand: 'Bạn có thể chỉ định thương hiệu của quản trị viên cho việc này, bạn cần chọn thương hiệu',
        Role: 'Vai diễn',
        BanningThisAgent: 'Cấm đại lý này có nghĩa là họ sẽ không thể đăng nhập vào nền tảng. Chúng vẫn sẽ xuất hiện trong hệ thống.',
        Report: 'Báo cáo',
        SelectLanguageWebChat: 'Chọn ngôn ngữ Trò chuyện trên web',
        FirstName: 'Tên đầu tiên',
        PhoneNumber: 'Số điện thoại',
        Info: 'Thông tin',
        WebChatSettings: 'Cài đặt trò chuyện trên web',
        IntegrationWithOtherCRM: 'Tích hợp với các CRM khác',
        SettingUpInteractionBetweenSystems: 'Thiết lập tương tác giữa các hệ thống',
        QuickReplies: 'Trả lời nhanh',
        BotSettings: 'Cài đặt bot',
        BotName: 'Tên bot',
        EnabledPrivacyPolicy: 'Chính sách quyền riêng tư được kích hoạt',
        PleaseChooseRequiredFields: 'Vui lòng chọn các trường bắt buộc',
        ChangeGreetingsAndPhrases: 'Thay đổi lời chào và cụm từ, Chính sách quyền riêng tư',
        ChangeSmsSettings: 'Thay đổi cài đặt sms',
        ChangeCountries: 'Thay đổi quốc gia',
        ContactsAreWorkingWithClientUniqueness: 'Các liên hệ đang hoạt động với tính duy nhất của ứng dụng khách. Trường là bắt buộc, nếu tính duy nhất của ứng dụng khách bằng với nó.',
        ClientUniquenessIs: 'Tính duy nhất của khách hàng là:',
        PrivacyPolicyText: 'Văn bản chính sách quyền riêng tư',
        PrivacyPolicyLink: 'Liên kết chính sách quyền riêng tư',
        PrivacyPolicyLinkText: 'Văn bản liên kết chính sách quyền riêng tư',
        AgentPhone: 'Điện thoại đại lý',
        HeaderSettings: 'Cài đặt tiêu đề',
        DisableDraggable: 'Tắt tính năng có thể kéo',
        DisableHeaderFullScreen: 'Tắt toàn màn hình tiêu đề',
        DisableHeaderHideChatWindow: 'Tắt ẩn cửa sổ trò chuyện tiêu đề',
        DisableHeaderLogo: 'Tắt biểu trưng tiêu đề',
        DisableHeaderShowEndDialogModal: 'Vô hiệu hóa phương thức hộp thoại hiển thị kết thúc tiêu đề',
        HeaderText: 'Tiêu đề văn bản',
        HeaderTextTop: 'Tiêu đề văn bản trên cùng',
        HeaderTextBottom: 'Tiêu đề văn bản dưới cùng',
        HeaderHeight: 'Chiều cao tiêu đề',
        LogoIconUrl: 'URL biểu tượng biểu tượng',
        IconUrl: 'URL biểu tượng',
        IconSettings: 'Cài đặt biểu tượng',
        ScrollSettings: 'Cài đặt cuộn',
        ScrollWidth: 'Chiều rộng cuộn',
        ChatWindowsLogo: 'Biểu trưng cửa sổ trò chuyện',
        BackgroundImageSize: 'Kích thước hình nền',
        GreetingSettings: 'Cài đặt lời chào',
        GreetingIconBotUrl: 'Biểu tượng chào mừng URL bot',
        QuickPhrasesSettings: 'Cài đặt Cụm từ Nhanh',
        ClientMessageSettings: 'Cài đặt tin nhắn khách hàng',
        AgentMessageSettings: 'Cài đặt tin nhắn đặc vụ',
        AgentMessageIconUrl: 'URL biểu tượng tin nhắn đặc vụ',
        EndDialogModalSettings: 'Kết thúc cài đặt phương thức hộp thoại',
        AuthWindowSettings: 'Cài đặt cửa sổ xác thực',
        FooterSettings: 'Cài đặt chân trang',
        WriteMessagesPlaceholder: 'Viết tin nhắn giữ chỗ',
        SendImageUrl: 'Gửi URL hình ảnh',
        SendButtonIconHeight: 'Chiều cao biểu tượng nút gửi',
        SendButtonIconWidth: 'Chiều rộng biểu tượng nút gửi',
        ChatWindowSettings: 'Cửa sổ trò chuyện Cài đặt',
        ClearAllPhrase: 'Xóa tất cả các cụm từ',
        AreYouSure: 'Bạn có chắc không?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Hành động này sẽ xóa tất cả các cụm từ và tất cả các danh mục',
        AddAgentToGroup: 'Thêm đại lý vào một nhóm',
        CreateClient: 'Tạo khách hàng',
        ClientsImport: 'Khách hàng nhập khẩu',
        UploadExcelFile: 'Tải lên tệp Excel',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Nhấp vào biểu tượng kẹp giấy để chọn tệp Excel',
        Contacts: 'Liên lạc',
        Personal: 'Riêng tư',
        FileUploadHint: 'Vui lòng tải lên một tệp excel chứa đầy số điện thoại. Tệp Excel phải chứa một cột có tên “Điện thoại” và số điện thoại phải được nhập vào cột này. Chỉ có thể nhập tệp .xls hoặc .xlsx.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Thay đổi lời chào và cụm từ, Chính sách quyền riêng tư',
    },
    AdditionalText: {
        AgentProfile: 'Hồ sơ đại lý',
        OutOf: 'ra khỏi',
    },
    Pages: {
        Dialogs: {
            Title: 'Hộp thoại',
            TableColumns: {
                Status: 'Trạng thái',
                Dialog: 'Hộp thoại',
                Messenger: 'tin nhắn',
                Channel: 'Kênh',
                Tag: 'Nhãn',
                Agent: 'Đại lý',
                Rating: 'Xếp hạng',
                Language: 'Ngôn ngữ',
                MessagesCount: 'Số lượng tin nhắn',
                FirstMessage: 'Tin nhắn đầu tiên',
                LastActivity: 'Hoạt động cuối',
            },
            Messages: 'Tin nhắn',
        },
        AgentGroups: {
            Title: 'Nhóm đại lý',
            TableColumns: {
                Groups: 'Các nhóm',
                Description: 'Sự miêu tả',
                AccessLevel: 'ACấp độ truy cập',
                WhiteListTags: 'Thẻ danh sách trắng',
                CreatedAt: 'Được tạo lúc',
                UpdatedAt: 'Cập nhật tại',
            },
        },
        Agents: {
            Title: 'Đại lý',
            TableColumns: {
                AgentStatus: 'Tình trạng đại lý',
                Agents: 'Đại lý',
                Email: 'E-mail',
                Groups: 'Các nhóm',
                CreatedAt: 'Được tạo lúc',
                LastActivity: 'Hoạt động cuối',
            },
        },
        Tags: {
            Title: 'Thẻ',
            TableColumns: {
                Tags: 'Thẻ',
                Dialogs: 'Hộp thoại',
                Agents: 'Đại lý',
                UsefulLinks: 'Liên kết hữu ích',
                Status: 'Trạng thái',
                CreatedAt: 'Được tạo lúc',
                UpdatedAt: 'Cập nhật tại',
            },
        },
        Clients: {
            Title: 'Khách hàng',
            TableColumns: {
                SearchName: 'Tên',
                AgentName: 'Tên đại lý',
                Messenger: 'tin nhắn',
                LastTag: 'Thẻ cuối cùng',
                ActiveDialog: 'Has Active Dialog',
                Brand: 'Nhãn hiệu',
                InternalId: 'Id nội bộ',
                ExternalId: 'ID bên ngoài',
                Name: 'Tên khách hàng',
                FirstName: 'Họ',
                LastName: 'Họ',
                Email: 'E-mail',
                InvoiceNumber: 'Số hóa đơn',
                DialogsCount: 'Số hộp thoại',
                FirstMessage: 'Tin nhắn đầu tiên',
                LastActivity: 'Hoạt động cuối',
                Tag: 'Nhãn',
                Status: 'Trạng thái',
                Channel: 'Kênh',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Kênh truyền hình',
            TableColumns: {
                ChannelId: 'Kênh ID',
                ChannelKey: 'Phím kênh',
                ChannelName: 'Tên kênh',
                Language: 'Ngôn ngữ',
                Url: 'Url',
                CreatedAt: 'Được tạo lúc',
                UpdatedAt: 'Cập nhật tại',
            },
        },
        Brands: {
            Title: 'Nhãn hiệu',
            TableColumns: {
                BrandsId: 'Nhãn hiệu id',
                BrandName: 'Tên thương hiệu',
                ClientUniqueness: 'Tính độc đáo của khách hàng',
                BrandChannels: 'Các kênh thương hiệu',
                DialogCount: 'Số lượng cuộc hội thoại',
                CreatedAt: 'Được tạo lúc',
                UpdatedAt: 'Cập nhật tại',
            },
        },
        Reports: {
            Title: 'Báo cáo',
            ByMessengersTab: {
                Title: 'Báo cáo của người đưa tin',
                Columns: {
                    Messenger: 'tin nhắn',
                    ReceivedDialogs: 'Hộp thoại đã nhận',
                    PositiveDialogs: 'Hộp thoại tích cực,%',
                    NegativeDialogs: 'Hộp thoại phủ định,%',
                    UnratedDialogs: 'Hộp thoại chưa được xếp hạng,%',
                    AvgResponseTime: 'Thời gian phản hồi trung bình',
                },
            },
            ByAgentsTab: {
                Title: 'Báo cáo của đại lý',
                Columns: {
                    agentFullName: 'Tên đầy đủ của đại lý',
                    agentSystemId: 'Id hệ thống đại lý',
                    brandName: 'Tên thương hiệu',
                    receivedDialogs: 'Các hộp thoại đã nhận',
                    dialogsTelegram: 'Hộp thoại Telegram',
                    dialogsWebChat: 'Hộp thoại Web Chat',
                    dialogsViber: 'Hộp thoại Viber',
                    dialogsWhatsApp: 'Hộp thoại WhatsApp',
                    dialogsFacebook: 'Hộp thoại Facebook',
                    dialogsEmail: 'Hộp thoại Email',
                    dialogsSms: 'Hộp thoại SMS',
                    positiveDialogs: 'Hộp thoại tích cực,%',
                    negativeDialogs: 'Hộp thoại phủ định,%',
                    unratedDialogs: 'Hộp thoại chưa được xếp hạng,%',
                    avgResponseTime: 'Thời gian phản hồi trung bình',
                },
                noDataForThisDay: 'Không có dữ liệu cho ngày này',
            },
            ByGroupsTab: {
                Title: 'Báo cáo theo nhóm',
                Columns: {
                    groupSystemId: 'Id hệ thống nhóm',
                    groupName: 'Tên nhóm',
                    brandName: 'Tên thương hiệu',
                    receivedDialogs: 'Các hộp thoại đã nhận',
                    positiveDialogs: 'Hộp thoại tích cực,%',
                    negativeDialogs: 'Hộp thoại phủ định,%',
                    unratedDialogs: 'Hộp thoại chưa được xếp hạng,%',
                    avgResponseTime: 'Thời gian phản hồi trung bình',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Thêm bộ lọc',
        reset: 'Cài lại',
        createClient: 'Tạo khách hàng',
        importClient: 'Nhập khách hàng',
        createChannel: 'Tạo kênh',
        previous: 'TrướcTrước',
        next: 'Kế tiếp',
        addPhrase: 'Thêm cụm từ',
        addGroup: 'Thêm nhóm',
        createBrand: 'Tạo thương hiệu',
        addTag: 'Thêm thẻ',
        save: 'Cứu',
        send: 'Gửi',
        getStatistics: 'Nhận thống kê',
        setCountries: 'Đặt quốc gia',
        changeStatus: 'Thay đổi trạng thái',
        upload: 'Tải lên',
        setDomainSettings: 'Đặt Cài đặt miền',
        login: 'Đăng nhập',
        update: 'Cập nhật',
        resetPassword: 'Đặt lại mật khẩu',
        addNew: 'Thêm mới',
        addNewPhrase: 'Thêm cụm từ mới',
        addNewCategory: 'Thêm danh mục mới',
        clearAll: 'Làm sạch tất cả',
        loginWithGoogle: 'Đăng nhập với Google',
        loginWithFacebook: 'Đăng nhập Facebook',
        onPage: 'tại trang',
        addNewUsefulLinks: 'Thêm các liên kết hữu ích mới',
        backToChannels: 'Quay lại kênh',
        defaultSettings: 'Thiết lập mặc định',
        upgradeChannel: 'Nâng cấp kênh',
        apply: 'Áp dụng',
        remove: 'Tẩy',
        removeSuperAdmin: 'Xóa quản trị viên cấp cao',
        makeSuperAdmin: 'Làm quản trị viên cấp cao',
        makeBrandAdmin: 'Đặt làm quản trị viên thương hiệu',
        removeBrandAdmin: 'Xóa quản trị viên thương hiệu',
        ban: 'Cấm',
        unban: 'Bỏ cấm',
        makeTeamLead: 'Làm Trưởng nhóm',
        removeTeamLead: 'Xóa Trưởng nhóm',
        signUp: 'Đăng ký',
        ok: 'Đồng ý',
        cancel: 'Hủy bỏ',
        bulkMessaging: 'Nhắn tin hàng loạt',
        back: 'Mặt sau',
    },
    Countries: {
        UnitedStates: 'Hoa Kỳ',
        Canada: 'Canada',
        Australia: 'Châu Úc',
        CzechRepublic: 'Cộng hòa Séc',
        Denmark: 'Đan mạch',
        Hungary: 'Hungary',
        Netherlands: 'nước Hà Lan',
        Sweden: 'Thụy Điển',
        UnitedKingdom: 'Vương quốc Anh',
        France: 'Nước pháp',
        Germany: 'nước Đức',
        Poland: 'Ba lan',
        Italy: 'Nước Ý',
        Spain: 'Tây Ban Nha',
    },
    Languages: {
        Ukrainian: 'Người Ukraina',
        Russian: 'tiếng Nga',
        English: 'Tiếng Anh',
        Polish: 'đánh bóng',
        Deutsch: 'Deutsch',
        Spanish: 'người Tây Ban Nha',
        Vietnamese: 'Tiếng Việt',
        Portuguese: 'Người Bồ Đào Nha',
        Korean: 'Hàn Quốc',
        Italian: 'người Ý',
        Arabic: 'tiếng Ả Rập',
        French: 'người Pháp',
        Azerbaijan: 'Azerbaijan',
        Hungarian: 'Người Hungary',
    },
    BulkMessaging: {
        exclude: 'Loại trừ',
        typeText: 'Gõ văn bản',
        selectClients: 'Chọn khách hàng',
        uploadClients: 'Tải lên khách hàng',
        sendingMessagesToNewClients: 'Gửi tin nhắn cho khách hàng mới',
        sendingMessagesToExistingClients: 'Gửi tin nhắn cho khách hàng hiện tại',
        clientsWhoHaveDialogsWithFollowingStatus: 'Khách hàng, những người có hộp thoại với trạng thái sau',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Khách hàng, những người có văn bản này trong tên hoặc họ hoặc tên người dùng',
        limitQuantity: 'Giới hạn số lượng',
        quantityLimit: 'Giới hạn số lượng',
        limitQuantityText: 'Giới hạn số lượng trong chương trình phát sóng không quá số lượng người nhận được chỉ định. Các khách hàng mới hơn của hệ thống sẽ được đưa vào danh sách.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Khách hàng, người đã viết thư cho AskMe trước giờ gửi tin nhắn cuối cùng',
    },
};
export default Messages;
