var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState, } from 'react';
import ReactDOM from 'react-dom';
import Header from '@src/modules/Header';
import Loader from '@src/components/Loader';
import ChannelUpdate from '@src/modules/Pages/Channels/ChannelUpdate/ChannelUpdate';
import { StyledChannelUpdateWrapper } from '@src/modules/Pages/Channels/ChannelUpdate/styled';
var ChannelUpdateWrapper = function (props) {
    var toggleModal = props.toggleModal, isLoadingCurrentChannel = props.isLoadingCurrentChannel, currentChannel = props.currentChannel, updateChannel = props.updateChannel, isLoadingLayoutWebChat = props.isLoadingLayoutWebChat, updateDefaultCustomizations = props.updateDefaultCustomizations;
    var _a = __read(useState(document.createElement('div')), 1), container = _a[0];
    useEffect(function () {
        document.body.appendChild(container);
        return function () {
            document.body.removeChild(container);
        };
    }, []);
    return ReactDOM.createPortal(React.createElement(StyledChannelUpdateWrapper, null,
        React.createElement(Header, null),
        isLoadingCurrentChannel ? (React.createElement(Loader, null)) : (React.createElement(ChannelUpdate, { toggleModal: toggleModal, updateChannel: updateChannel, currentChannel: currentChannel, isLoadingLayoutWebChat: isLoadingLayoutWebChat, updateDefaultCustomizations: updateDefaultCustomizations }))), container);
};
export default ChannelUpdateWrapper;
