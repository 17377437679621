var Messages = {
    Success: {
        Success: 'Erfolg',
        ForgotPasswordSend: 'Der Link zum Ändern des Passworts wurde an Ihre E-Mail gesendet',
        ResetPasswordSuccess: 'Ihr Passwort wurde erfolgreich aktualisiert',
        BrandCreated: 'Marke geschaffen!',
        MailInboxCreated: 'Posteingang erstellt!',
        PrivacyPolicyChanged: 'Datenschutzrichtlinie geändert!',
        SmsInboxCreated: 'SMS-Posteingang erstellt!',
        InboxDeleted: 'Posteingang gelöscht!',
        StatusChanged: 'Status geändert!',
        FileLoaded: 'Datei geladen!',
        AuthMail: 'Auth-Mail!',
        DomainSettings: 'Domäneneinstellungen',
        UpdatedSmsAccounts: 'Aktualisierte SMS-Konten!',
        ChanelCreated: 'Kanal erstellt!',
        DeleteMessenger: 'Messenger löschen!',
        AddMessenger: 'Messenger hinzufügen!',
        ChangedMessenger: 'Bote geändert!',
        ClientCreated: 'Kunde erstellt!',
        ClientsImports: 'Kunden importieren!',
        GroupCreated: 'Gruppe erstellt!',
        ChangedAssignedAgentsInGroups: 'Zugewiesene Agenten in Gruppen geändert!',
        ChangedAssignedChannelsInGroups: 'Zugewiesene Kanäle in Gruppen geändert!',
        ChangedAssignedTagsInGroups: 'Zugewiesene Tags in Gruppen geändert!',
        CreateNewCategory: 'Neue Kategorie erstellen!',
        CreateNewPhrase: 'Neuen Satz erstellen!',
        DeleteCategory: 'Kategorie löschen!',
        DeletePhrase: 'Satz löschen!',
        UpdatePhrase: 'Satz aktualisieren!',
        EditCategory: 'Kategorie bearbeiten!',
        DeleteAllCategory: 'Alle Kategorien löschen!',
        TagCreated: 'Schlagwort erstellt!',
        TagUpdated: 'Schlagwort aktualisiert!',
        TelegramInboxCreated: 'Telegramm-Postfach erstellt!',
        HeaderTextChanged: 'Kopfzeilentext geändert',
        LogoIconUrlChanged: 'Logo-Icon-URL geändert',
        IconUrlChanged: 'Symbol-URL geändert',
        BackgroundImageUrlChanged: 'Hintergrundbild-URL geändert',
        GreetingImageUrlChanged: 'Begrüßungsbild-URL geändert',
        AgentImageUrlChanged: 'Bild-URL des Agenten geändert',
        InputPlaceholderChanged: 'Eingabeplatzhalter geändert',
        SendImageUrlChanged: 'Bild-URL senden geändert',
        AgentStatusChanged: 'Agent statusu dəyişdi',
        SelectedAgentTagsChanged: 'Ausgewählte Agenten-Tags geändert',
        AssignedAgentsGroupsChanged: 'Zugewiesene Agentengruppen geändert',
        TeamLeadChanged: 'Teamleiter gewechselt',
        AgentRoleChanged: 'Agentenrolle geändert',
        AgentInfoChanged: 'Agenteninformationen geändert',
    },
    Errors: {
        PasswordMatching: 'Passwörter stimmen nicht überein!',
        IncorrectEmail: 'E-Mail ist falsch',
        IncorrectPhone: 'Telefon ist falsch',
        IncorrectUrl: 'URL ist falsch',
        FieldContainSpaces: 'Feld enthält Leerzeichen',
        FieldIsRequired: 'Feld ist erforderlich',
        NameValidation: 'Die Feldlänge muss 2 bis einschließlich 40 Zeichen betragen',
        NumberValidation: 'Das Feld darf nur Zahlen enthalten',
        PasswordValidation: 'Das Passwort muss Buchstaben und Zahlen enthalten und darf keine Leerzeichen enthalten',
        InvoiceNumberValidation: 'Rechnungsnummer muss lateinische Buchstaben, Zahlen und Sonderzeichen enthalten',
        MinimumLength: 'Mindestlänge {0} Symbole',
        MaximumLength: 'Maximale Länge {0} Symbole',
        ServerError: 'Serverfehler, bitte versuchen Sie es später!',
        ServerErrorFileDownloadTimeOut: 'Serverfehler, Zeitüberschreitung beim Herunterladen der Datei, bitte versuchen Sie es später!',
        NotValidEmailOrPassword: 'Ungültige E-Mail oder Passwort',
        UserWasNotFound: 'Benutzer wurde nicht gefunden!',
        UserIsAlreadyExist: 'Benutzer existiert bereits!',
        AgentWasBanned: 'Agent wurde gesperrt',
        TokenExpired: 'Die Sitzung ist abgelaufen. Bitte melden Sie sich erneut an',
        TelegramIdIsIncorrect: 'Telegramm-ID ist falsch',
        ErrorWrongFileFormat: 'Fehler, falsches Dateiformat!',
        FileSizeExceededWarnings: 'Warnungen zur Überschreitung der Dateigröße!',
        BrandWithSuchNameIsAlreadyExisting: 'Marke mit solchem Namen ist bereits vorhanden!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Sie haben bereits einen Filter ausgewählt',
        AgentCreate: 'Sie sind ein neuer Agent im AskMe-System. Bitte beachten Sie, dass Sie sich erst anmelden können, nachdem Ihnen die Administratorrolle zugewiesen wurde!',
        NoStatisticsForTheAgent: 'Keine Statistikdaten für den Agenten für den ausgewählten Zeitraum',
        FieldIsRequired: 'Feld ist erforderlich',
        ChannelMustContainAtLeastOneLanguage: 'Der Kanal muss mindestens eine Sprache enthalten',
    },
    Info: {
        Copied: 'kopiert',
        PhraseBotNameAndGreetingsChanged: 'Phrasen, Bot-Name und Begrüßung geändert',
    },
    Confirm: {},
    Message: {
        Welcome: 'Willkommen bei AksMe',
        SignIn: 'Melden Sie sich im Admin-Panel an oder gehen Sie zu Ihrem',
        SignUp: 'Melden Sie sich beim Admin-Panel an oder gehen Sie zu Ihrem ',
        Register: 'Haben sie kein Konto bei uns?',
        Login: 'Haben sie bereits ein Konto?',
        Logout: 'Wollen sie wirklich gehen?',
        ForgotPassword: 'Passwort vergessen?',
        ExcelFilesDownloading: 'Herunterladen von Excel-Dateien',
        excelFileIs: 'Excel-Datei',
        Created: 'geschaffen',
        Creating: 'erschafft',
    },
    Accordions: {
        MailInboxes: 'Posteingänge',
        TelegramAccounts: 'Telegramkonten',
        SmsAccounts: 'SMS-Konten',
    },
    Controls: {
        Newbie: 'Neuling',
        Name: 'Name',
        Email: 'Email',
        Password: 'Passwort',
        RepeatPassword: 'Wiederhole das Passwort',
        Register: 'Registrieren',
        Login: 'Einloggen',
        Or: 'ODER',
        SignUp: 'Anmelden',
        Send: 'Senden',
        SearchFilter: 'Suchfilter',
        Search: 'Suche',
        SearchMessengers: 'Boten suchen',
        SearchAgent: 'Suchagent',
        SearchLanguage: 'Sprache suchen',
        SearchChannel: 'Suchkanal',
        SearchTag: 'Such-Tag',
        SearchStatus: 'Suchstatus',
        SearchBrand: 'Marke suchen',
        SearchWhiteListTags: 'Suchen Sie nach Whitelist-Tags',
        SearchSessionStatus: 'Sitzungsstatus suchen',
        SearchAccessLevel: 'Zugriffsebene suchen',
        SearchHasActiveDialogs: 'Die Suche hat aktive Dialoge',
        GroupName: 'Gruppenname*',
        Description: 'Beschreibung*',
        LinkAlias: 'Link-Alias*',
        CategoryName: 'Kategoriename*',
        BrandName: 'Markenname',
        AccessKey: 'Zugangsschlüssel',
        ClientName: 'Kundenname',
        ClientFirstName: 'Vorname des Kunden',
        ClientLastName: 'Nachname des Kunden',
        ClientPhone: 'Kundentelefon',
        ClientEmail: 'Email des Kunden',
        PleaseWrite: 'Bitte schreibe',
        MailInbox: 'Posteingang',
        SmsInbox: 'SMS-Posteingang',
        TelegramInbox: 'Telegram-Posteingang',
        ApplicationID: 'Anwendungs-ID',
        ApplicationToken: 'Anwendungstoken',
        SenderID: 'Absenderidentität',
        ChangeName: 'Namen ändern',
        ChangeURL: 'URL ändern',
        TagName: 'Verlinke den Namen',
        LinkUrl: 'URL verknüpfen*',
        Greetings: 'Grüße',
        FastPhrase: 'Schneller Satz №',
        ClickOnPaperclip: 'Klicken Sie auf das Büroklammer-Symbol, um eine Datei auszuwählen',
        UserName: 'Anmeldung',
        Domain: 'Domain*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (der Gastgeber:der Anschluss)*',
        SmtpSSL: 'Smtp SSL (der Gastgeber:der Anschluss)*',
        AttachFile: 'Datei anhängen',
        NoData: 'keine Daten',
        NoRating: 'keine Bewertung',
        IfYouWantFinishDialog: 'Wenn Sie den Dialog beenden möchten, klicken Sie auf die Schaltfläche OK',
        AllDialogsAreVisibleToAllAgents: 'Alle Dialoge sind für alle Agenten sichtbar',
        DialogueIsAssignedAutomatically: 'Der Dialog wird automatisch dem Agenten zugewiesen, der die wenigsten Dialoge hat',
        AllDialogsAreVisibleToSeveralAgents: 'Dialoge sind für mehrere Agenten mit der geringsten Anzahl von Dialogen sichtbar',
        On: 'Ein',
        Off: 'Aus',
        FirstName: 'Vorname',
        InvoiceNumber: 'Rechnungsnummer',
        LastName: 'Nachname',
        SMS: 'SMS',
        New: 'Neu',
        Active: 'Aktiv',
        Finished: 'Beendet',
        Assigned: 'Zugewiesen',
        Online: 'Online',
        Offline: 'Offline',
        Break: 'Brechen',
        Low: 'Niedrig',
        Medium: 'Mittel',
        High: 'Hoch',
        True: 'Wahr',
        False: 'Falsch',
        Date: 'Datum',
        AgentName: 'Agentenname',
        Messenger: 'Bote',
        Language: 'Sprache',
        Channel: 'Kanal',
        Tag: 'Schild',
        Status: 'Status',
        Brand: 'Marke',
        AccessLevel: 'Zugriffsebene',
        WhiteListTags: 'Whitelist-Tags',
        SessionStatus: 'Sitzungsstatus',
        LastTags: 'Letzte Tags',
        HasActiveDialogs: 'Hat aktive Dialoge',
        ChannelName: 'Kanal Name',
        WriteYourFirstName: '* Bitte schreiben Sie Ihren Vornamen',
        WriteYourLastName: '* Bitte schreiben Sie Ihren Nachnamen',
        WriteYourUserName: '* Bitte geben Sie den Benutzernamen ein',
        WriteYourPhoneNumber: '* Bitte schreiben Sie die Telefonnummer',
        WriteYourEmail: '* Bitte schreiben Sie Ihre E-Mail',
        WriteYourInvoiceNumber: '* Bitte geben Sie Ihre Rechnungsnummer an',
        WriteYourAccountNumber: '* Bitte geben Sie Ihre Kontonummer an',
        BotName: 'Bot-Name',
        Character: 'Charakter',
        Number: 'Anzahl',
        IdExample: 'ID-Beispiel',
        XCharecter: 'X-Zeichen',
        YNumber: 'Y-Nummer',
        ThisOperationCanTakeLongTime: 'Dieser Vorgang kann lange dauern!',
        ClickTiSaveExcel: 'Klicken Sie hier, um in Excel zu speichern',
        PrivacyPolicyText: 'Text der Datenschutzerklärung',
        PrivacyPolicyLink: 'Link zur Datenschutzrichtlinie',
        PrivacyPolicyLinkText: 'Linktext zur Datenschutzrichtlinie',
        AgentPhone: 'Telefon des Agenten',
        HeaderTextTop: 'Kopfzeilentext oben',
        HeaderTextBottom: 'Kopfzeilentext unten',
        LogoIconUrl: 'Logo-Symbol-URL',
        IconUrl: 'Symbol-URL',
        ChatWindowsLogo: 'Chatfenster-Logo',
        GreetingIconBotUrl: 'Bot-URL des Begrüßungssymbols',
        AgentMessageIconUrl: 'Symbol-URL für Agentennachricht',
        WriteMessagesPlaceholder: 'Platzhalter für Nachrichten schreiben',
        SendImageUrl: 'Bild-URL senden',
        ChannelURL: 'Kanal-URL',
    },
    Selects: {
        SelectAccessLevel: 'Zugriffsebene auswählen*',
        SelectChannel: 'Kanal auswählen*',
        SelectChannels: 'Kanäle auswählen',
        SelectWhiteTagsList: 'Liste der weißen Tags auswählen*',
        SelectAgents: 'Agenten auswählen',
        SelectGroups: 'Gruppen auswählen',
        SelectBrand: 'Marke auswählen',
        SelectTags: 'Tags auswählen',
        ChooseProviderCountry: 'Anbieterland auswählen',
        SelectMessengers: 'Boten auswählen',
        SelectTagStatus: 'Tag-Status auswählen',
        SelectAgent: 'Agent auswählen',
        SelectEnabledLanguages: 'Aktivierte Sprachen auswählen',
        ChannelName: 'Kanal Name',
        ChannelURL: 'Kanal-URL',
        SelectLanguageWebChat: 'Sprachen auswählen Web-Chat',
    },
    Labels: {
        NumberOfNewAgents: 'Anzahl neuer Agenten',
        TextForBulkMessaging: 'Text für Massennachrichten',
        Name: 'Name',
        LastName: 'Nachname',
        Phone: 'Telefon',
        GroupName: 'Gruppenname*',
        SelectBrand: 'Marke auswählen',
        Channel: 'Kanal*',
        Description: 'Beschreibung*',
        AccessLevel: 'Zugriffsebene*',
        WhiteTagsList: 'White-Tags-Liste*',
        BrandName: 'Markenname',
        CategoryName: 'Kategoriename*',
        ChooseProviderCountry: 'Anbieterland auswählen',
        AccessKey: 'Zugangsschlüssel',
        SelectChannels: 'Kanäle auswählen',
        SelectGroups: 'Gruppen auswählen',
        SelectAgents: 'Agenten auswählen',
        SelectTags: 'Tags auswählen',
        SelectAccessLevel: 'Zugriffsebene auswählen*',
        Password: 'Passwort*',
        Login: 'Einloggen',
        SelectMessengers: 'Boten auswählen',
        UserName: 'Nutzername',
        ClientName: 'Kundenname',
        ClientFirstName: 'Vorname des Kunden',
        ClientLastName: 'Nachname des Kunden',
        ClientPhone: 'Kundentelefon',
        ClientEmail: 'E-Mail des Kunden',
        ClientInfo: 'Kundeninfo',
        UpdateGroup: 'Gruppe aktualisieren',
        Agents: 'Agenten',
        UsefulPhrases: 'Nützliche Ausdrücke',
        Channels: 'Kanäle',
        Tags: 'Stichworte',
        AddNewPhrases: 'Fügen Sie neue Sätze hinzu',
        Phrase: 'Phrase',
        SelectCategory: 'Kategorie wählen',
        EditCategory: 'Kategorie bearbeiten',
        EditPhrase: 'Satz bearbeiten',
        Or: 'oder',
        AddNewCategory: 'Neue Kategorie hinzufügen',
        Dialogs: 'Dialoge',
        TotalDuration: 'Gesamtdauer',
        Email: 'Email',
        ClientId: 'Kunden ID',
        ClientBanType: 'Client-Sperrtyp',
        ClientBanTime: 'Client-Sperrzeit',
        Messengers: 'Boten',
        IpAddress: 'IP Adresse',
        ExternalID: 'Externe ID',
        UpdateBrand: 'Marke aktualisieren',
        CreateMailInbox: 'Posteingangskonto erstellen',
        CreateTelegramInbox: 'Telegram-Konto erstellen',
        CreateSmsInbox: 'SMS-Konto erstellen',
        MailInbox: 'Posteingang',
        TelegramInbox: 'Telegramm-Posteingang',
        SmsInbox: 'SMS-Posteingang',
        ApplicationID: 'Anwendungs-ID',
        ApplicationToken: 'Anwendungstoken',
        SenderID: 'Absenderidentität',
        ChangeName: 'Namen ändern',
        ChangeURL: 'URL ändern',
        TagName: 'Verlinke den Namen',
        SelectTagStatus: 'Tag-Status auswählen',
        SelectAgent: 'Agent auswählen',
        UpdateTag: 'Tag aktualisieren',
        CreateTag: 'Tag erstellen',
        UsefulLinks: 'Nützliche Links',
        LinkAlias: 'Link-Alias',
        LinkUrl: 'URL verknüpfen',
        Roles: 'Rollen',
        Groups: 'Gruppen',
        CreateGroups: 'Erstellen Sie Gruppen',
        TeamLead: 'Teamleiter',
        Ban: 'Ban',
        SelectEnabledLanguages: 'Aktivierte Sprachen auswählen',
        Greetings: 'Grüße',
        FastPhrase: 'Schneller Satz №',
        ClientHistory: 'Kundenhistorie',
        ChooseRequiredFields: 'Bitte wählen Sie die erforderlichen Felder aus',
        GetAllDialogs: 'Holen Sie sich alle Dialoge',
        GetCurrentDialog: 'Aktuellen Dialog abrufen',
        SaveInLocalStorage: 'In LocalStorage speichern',
        SaveInSessionStorage: 'Im Sitzungsspeicher speichern',
        InvoiceNumber: 'Rechnungsnummer',
        AccountNumber: 'Accountnummer',
        General: 'Allgemein',
        Authorization: 'Genehmigung',
        Languages: 'Sprachen',
        New: 'Neu',
        Active: 'Aktiv',
        Inactive: 'Inaktiv',
        WaitForActivation: 'auf Aktivierung warten',
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Niedrig',
        Medium: 'Mittel',
        High: 'Hoch',
        Hidden: 'Versteckt',
        Brand: 'Marke',
        Group: 'Gruppe',
        ChangeStatus: 'Status ändern',
        AuthMail: 'Auth-Mail',
        SetDomainSettings: 'Domäneneinstellungen festlegen',
        Domain: 'Domain*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (der Gastgeber:der Anschluss)*',
        SmtpSSL: 'Smtp SSL (der Gastgeber:der Anschluss)*',
        AttachFile: 'Attach File',
        ChannelSettings: 'Kanaleinstellungen',
        GeneralSettings: 'Allgemeine Einstellungen',
        WebChatConstructor: 'Web-Chat-Konstruktor',
        BackgroundColor: 'Hintergrundfarbe',
        MessageColor: 'Nachrichtenfarbe',
        URL: 'URL',
        ChannelName: 'Kanal Name',
        ChannelURL: 'Kanal-URL',
        AllowSMS: 'SMS zulassen',
        AllowShowAllClientDialogs: 'Alle Clientdialoge anzeigen zulassen',
        AllowCaptcha: 'Captcha zulassen',
        InboxSettings: 'Posteingangseinstellungen',
        DistributionOfNewDialogs: 'Verteilung neuer Dialoge zwischen Online-Agenten',
        ThereAreNoAvailableMessengers: 'Es sind keine Messenger für den Kanal verfügbar! Bitte erstelle sie für die Marke, der dieser Kanal gehört!',
        HeaderColorScheme: 'Farbschema der Kopfzeile',
        GreetingColorScheme: 'Begrüßungsfarbschema',
        QuickPhrases: 'Quick Phrases-Farbschema',
        ClientMessage: 'Farbschema für Client-Nachrichten',
        AgentMessage: 'Farbschema für Agentennachrichten',
        EndDialogModal: 'Modales Farbschema des Dialogfelds beenden',
        IconColorScheme: 'Symbol-Farbschema',
        ScrollColorScheme: 'Farbschema scrollen',
        ChatWindowColor: 'Farbschema des Chatfensters',
        FooterColorScheme: 'Farbschema der Fußzeile',
        AuthColorScheme: 'Authentisches Farbschema',
        HeaderBackground: 'Header-Hintergrund',
        HeaderBorderColor: 'Rahmenfarbe der Kopfzeile',
        ColorGreetingBotName: 'Gruß Farbe des Bot-Namens',
        MessageGreetingIcon: 'Gruß Symbol Hintergrund',
        MessageGreetingColor: 'Gruß Textfarbe',
        MessageGreetingBorderColor: 'Begrüßungsnachricht Rahmenfarbe',
        MessageGreetingBackgroundColor: 'Begrüßungsnachricht Hintergrundfarbe',
        QuickPhrasesColor: 'Schnelle Phrase Textfarbe',
        QuickPhrasesBackground: 'Schneller Phrasenhintergrund',
        QuickPhrasesBorderColor: 'Schnelle Phrase Rahmenfarbe',
        QuickPhrasesColorHover: 'Schnelle Phrase Hover-Farbe',
        QuickPhrasesBackgroundHover: 'Schneller Ausdruck Hintergrund-Hover',
        QuickPhrasesBorderColorHover: 'Schneller Ausdruck Rahmenfarbe schweben',
        ClientMessageColor: 'Client-Nachricht Textfarbe',
        ClientTimeColor: 'Farbe der Client-Zeit',
        ClientMessageBackground: 'Client-Nachricht Hintergrundfarbe',
        ClientMessageBorderColor: 'Rahmenfarbe für Kundennachricht',
        ClientLink: 'Client-Link',
        ClientLinkHover: 'Client-Link-Hover',
        ClientActiveLink: 'Aktiver Client-Link',
        ClientVisitedLink: 'Vom Kunden besuchter Link',
        ColorAgentNameAndTime: 'Agentenname und Zeitfarbe',
        MessageAgentColor: 'Farbe der Agentennachricht',
        MessageAgentBackgroundColor: 'Hintergrund der Agentennachricht',
        MessageAgentBorderColor: 'Rahmenfarbe für Agentennachricht',
        AgentLink: 'Link des Agenten',
        AgentHoverLink: 'Hover-Link des Agenten',
        AgentActiveLink: 'Agent Aktiver Link',
        AgentVisitedLink: 'Vom Agenten besuchter Link',
        MessageIconBackgroundAgent: 'Hintergrundfarbe des Nachrichtensymbols des Agenten',
        TextColor: 'Textfarbe',
        BackdropColor: 'Hintergrundfarbe',
        BackdropColorHover: 'Hintergrundfarbe schweben',
        ButtonColor: 'Knopffarbe',
        BackdropColorBtn: 'Hintergrundfarbe der Schaltfläche',
        IconBackground: 'Symbolhintergrund',
        ChatWindowsBodyBackground: 'Hintergrundfarbe des Chat-Fensters',
        ChatWindowsBorderColor: 'Rahmenfarbe für Chatfenster',
        FooterBackground: 'Hintergrundfarbe der Fußzeile',
        FooterBorderColor: 'Rahmenfarbe der Fußzeile',
        FooterBackgroundTextArea: 'Hintergrundfarbe des Fußzeilentextbereichs',
        InputBorderColor: 'Rahmenfarbe eingeben',
        RegistrationBackgroundColor: 'Hintergrundfarbe der Registrierung',
        LabelColor: 'Etikettenfarbe',
        LabelErrorColor: 'Fehlerfarbe beschriften',
        InputColor: 'Farbe eingeben',
        InputBackground: 'Hintergrund eingeben',
        SignUpColor: 'Farbe anmelden',
        SignUpBackground: 'Hintergrundfarbe anmelden',
        Chat: 'Plaudern',
        Minimized: 'Minimiert',
        ScrollBackground: 'Hintergrund scrollen',
        ScrollThumbBackground: 'Blättern Sie mit dem Daumenhintergrund',
        UniqueClientIdentifier: 'Eindeutige Client-ID',
        AgentsSettings: 'Einstellungen für Agenten',
        ThisAgentSuperAdmin: 'Dieser Agent ist Super-Admin, klicken Sie auf die Schaltfläche, um Super-Admin-Rechte zu entfernen',
        ThisAgentNotSuperAdmin: 'Dieser Agent ist kein Super-Admin. Klicken Sie auf die Schaltfläche, um ihn zum Super-Admin zu machen',
        YouCanAssignBrandTheBrand: 'Sie können die Marke des Admins zuweisen, dazu müssen Sie die Marke auswählen',
        Role: 'Rolle',
        BanningThisAgent: 'Das Sperren dieses Agenten bedeutet, dass er sich nicht bei der Plattform anmelden kann. Sie werden immer noch im System erscheinen.',
        Report: 'Prüfbericht',
        SelectLanguageWebChat: 'Sprachen auswählen Web-Chat',
        FirstName: 'Vorname',
        PhoneNumber: 'Telefonnummer',
        Info: 'Die Info',
        WebChatSettings: 'Web-Chat-Einstellungen',
        IntegrationWithOtherCRM: 'Integration mit anderen CRM',
        SettingUpInteractionBetweenSystems: 'Einrichten der Interaktion zwischen Systemen',
        QuickReplies: 'Schnelle Antworten',
        BotSettings: 'Bot-Einstellungen',
        BotName: 'Bot-Name',
        EnabledPrivacyPolicy: 'Aktivierte Datenschutzrichtlinie',
        PleaseChooseRequiredFields: 'Bitte wählen Sie die erforderlichen Felder aus',
        ChangeGreetingsAndPhrases: 'Grüße und Satz ändern, Datenschutzrichtlinie',
        ChangeSmsSettings: 'SMS-Einstellungen ändern',
        ChangeCountries: 'Länder wechseln',
        ContactsAreWorkingWithClientUniqueness: 'Kontakte arbeiten mit Kundeneindeutigkeit. Das Feld ist erforderlich, wenn die Kundeneindeutigkeit gleich ist.',
        ClientUniquenessIs: 'Client-Eindeutigkeit ist:',
        PrivacyPolicyText: 'Text der Datenschutzerklärung',
        PrivacyPolicyLink: 'Link zur Datenschutzrichtlinie',
        PrivacyPolicyLinkText: 'Linktext zur Datenschutzrichtlinie',
        AgentPhone: 'Telefon des Agenten',
        HeaderSettings: 'Header-Einstellungen',
        DisableDraggable: 'Deaktivieren Sie ziehbar',
        DisableHeaderFullScreen: 'Header-Vollbild deaktivieren',
        DisableHeaderHideChatWindow: 'Deaktivieren Sie das Ausblenden des Header-Chatfensters',
        DisableHeaderLogo: 'Header-Logo deaktivieren',
        DisableHeaderShowEndDialogModal: 'Deaktivieren Sie das Modal für das Anzeigen des Enddialogs in der Kopfzeile',
        HeaderText: 'Überschrift',
        HeaderTextTop: 'Kopfzeilentext oben',
        HeaderTextBottom: 'Kopfzeilentext unten',
        HeaderHeight: 'Header-Höhe',
        LogoIconUrl: 'Logo-Symbol-URL',
        IconUrl: 'Symbol-URL',
        IconSettings: 'Symboleinstellungen',
        ScrollSettings: 'Scrollen Sie zu den Einstellungen',
        ScrollWidth: 'Scrollbreite',
        ChatWindowsLogo: 'Chatfenster-Logo',
        BackgroundImageSize: 'Hintergrundbildgröße',
        GreetingSettings: 'Begrüßungseinstellungen',
        GreetingIconBotUrl: 'Begrüßungssymbol-Bot-URL',
        QuickPhrasesSettings: 'Quick Phrases-Einstellungen',
        ClientMessageSettings: 'Client-Nachrichteneinstellungen',
        AgentMessageSettings: 'Nachrichteneinstellungen für Agenten',
        AgentMessageIconUrl: 'Symbol-URL für Agentennachricht',
        EndDialogModalSettings: 'Dialog modale Einstellungen beenden',
        AuthWindowSettings: 'Auth-Fenster Einstellungen',
        FooterSettings: 'Fußzeileneinstellungen',
        WriteMessagesPlaceholder: 'Platzhalter für Nachrichten schreiben',
        SendImageUrl: 'Bild-URL senden',
        SendButtonIconHeight: 'Höhe des Symbols für die Schaltfläche "Senden".',
        SendButtonIconWidth: 'Symbolbreite der Sendeschaltfläche',
        ChatWindowSettings: 'Chatfenster-Einstellungen',
        ClearAllPhrase: 'Alle Sätze löschen',
        AreYouSure: 'Bist du sicher?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Diese Aktion entfernt alle Phrasen und alle Kategorien',
        AddAgentToGroup: 'Agenten zu einer Gruppe hinzufügen',
        CreateClient: 'Mandant anlegen',
        ClientsImport: 'Clients importieren',
        UploadExcelFile: 'Excel-Datei hochladen',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Klicken Sie auf das Büroklammer-Symbol, um die Excel-Datei auszuwählen',
        Contacts: 'Kontakte',
        Personal: 'Persönlich',
        FileUploadHint: 'Bitte laden Sie eine mit Telefonnummern gefüllte Excel-Datei hoch. Die Excel-Datei sollte eine Spalte mit dem Namen „Telefone“ '
            + 'enthalten und Telefonnummern sollten in diese Spalte eingegeben werden. Es können nur .xls- oder .xlsx-Dateien importiert werden.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Grüße und Satz ändern, Datenschutzrichtlinie',
    },
    AdditionalText: {
        AgentProfile: 'Agentenprofil',
        OutOf: 'aus',
    },
    Pages: {
        Dialogs: {
            Title: 'Dialoge',
            TableColumns: {
                Status: 'Status',
                Dialog: 'Dialog',
                Messenger: 'Bote',
                Channel: 'Kanal',
                Tag: 'Schild',
                Agent: 'Agent',
                Rating: 'Bewertung',
                Language: 'Sprache',
                MessagesCount: 'Nachrichten zählen',
                FirstMessage: 'Erste Nachricht',
                LastActivity: 'Letzte Aktivität',
            },
            Messages: 'Mitteilungen',
        },
        AgentGroups: {
            Title: 'Agentengruppen',
            TableColumns: {
                Groups: 'Gruppen',
                Description: 'Beschreibung',
                AccessLevel: 'Zugriffsebene',
                WhiteListTags: 'Whitelist-Tags',
                CreatedAt: 'Hergestellt in',
                UpdatedAt: 'Aktualisiert am',
            },
        },
        Agents: {
            Title: 'Agenten',
            TableColumns: {
                AgentStatus: 'Agentenstatus',
                Agents: 'Agenten',
                Email: 'Email',
                Groups: 'Gruppen',
                CreatedAt: 'Hergestellt in',
                LastActivity: 'Letzte Aktivität',
            },
        },
        Tags: {
            Title: 'Stichworte',
            TableColumns: {
                Tags: 'Stichworte',
                Dialogs: 'Dialoge',
                Agents: 'Agenten',
                UsefulLinks: 'Nützliche Links',
                Status: 'Status',
                CreatedAt: 'Hergestellt in',
                UpdatedAt: 'Aktualisiert am',
            },
        },
        Clients: {
            Title: 'Kunden',
            TableColumns: {
                SearchName: 'name',
                AgentName: 'Agentenname',
                Messenger: 'Bote',
                LastTag: 'Letztes Schild',
                ActiveDialog: 'Hat aktiven Dialog',
                Brand: 'Marke',
                InternalId: 'Interne ID',
                ExternalId: 'Externe ID',
                Name: 'Kundenname',
                FirstName: 'Vorname',
                LastName: 'Nachname',
                Email: 'Email',
                InvoiceNumber: 'Rechnungsnummer',
                DialogsCount: 'Dialoge zählen',
                FirstMessage: 'Erste Nachricht',
                LastActivity: 'Letzte Aktivität',
                Tag: 'Schild',
                Status: 'Status',
                Channel: 'Kanal',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Kanäle',
            TableColumns: {
                ChannelId: 'Kanal ID',
                ChannelKey: 'Kanaltaste',
                ChannelName: 'Kanal Name',
                Language: 'Sprache',
                Url: 'Url',
                CreatedAt: 'Hergestellt in',
                UpdatedAt: 'Aktualisiert am',
            },
        },
        Brands: {
            Title: 'Marken',
            TableColumns: {
                BrandsId: 'Marken-ID',
                BrandName: 'Markenname',
                ClientUniqueness: 'Client-Einzigartigkeit',
                BrandChannels: 'Markenkanäle',
                DialogCount: 'Dialoge zählen',
                CreatedAt: 'Hergestellt in',
                UpdatedAt: 'Aktualisiert am',
            },
        },
        Reports: {
            Title: 'Berichte',
            ByMessengersTab: {
                Title: 'Meldung durch Boten',
                Columns: {
                    Messenger: 'Bote',
                    ReceivedDialogs: 'Empfangene Dialoge',
                    PositiveDialogs: 'Positive Dialoge, %',
                    NegativeDialogs: 'Negative Dialoge, %',
                    UnratedDialogs: 'Unrated-Dialoge, %',
                    AvgResponseTime: 'Durchschnittliche Reaktionszeit',
                },
            },
            ByAgentsTab: {
                Title: 'Bericht von Agenten',
                Columns: {
                    agentFullName: 'Name des Agenten',
                    agentSystemId: 'System-ID des Agenten',
                    brandName: 'Markenname',
                    receivedDialogs: 'Empfangene Dialoge',
                    dialogsTelegram: 'Dialoge Telegram',
                    dialogsWebChat: 'Dialoge Web Chat',
                    dialogsViber: 'Dialoge Viber',
                    dialogsWhatsApp: 'Dialoge WhatsApp',
                    dialogsFacebook: 'Dialoge Facebook',
                    dialogsEmail: 'Dialoge Email',
                    dialogsSms: 'Dialoge SMS',
                    positiveDialogs: 'Positive Dialoge, %',
                    negativeDialogs: 'Negative Dialoge, %',
                    unratedDialogs: 'Unrated-Dialoge, %',
                    avgResponseTime: 'Durchschnittliche Reaktionszeit',
                },
                noDataForThisDay: 'Keine Daten für diesen Tag',
            },
            ByGroupsTab: {
                Title: 'Berichte nach Gruppen',
                Columns: {
                    groupSystemId: 'Gruppensystem-ID',
                    groupName: 'Gruppenname',
                    brandName: 'Markenname',
                    receivedDialogs: 'Empfangene Dialoge',
                    positiveDialogs: 'Positive Dialoge, %',
                    negativeDialogs: 'Negative Dialoge, %',
                    unratedDialogs: 'Unrated-Dialoge, %',
                    avgResponseTime: 'Durchschnittliche Reaktionszeit',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Filter hinzufügen',
        reset: 'Zurücksetzen',
        createClient: 'Mandant erstellen',
        importClient: 'Kunden importieren',
        createChannel: 'Kanal erstellen',
        previous: 'Vorherige',
        next: 'Nächste',
        addPhrase: 'Satz hinzufügen',
        addGroup: 'Gruppe hinzufügen',
        createBrand: 'Marke schaffen',
        addTag: 'Tag hinzufügen',
        save: 'Speichern',
        send: 'Senden',
        getStatistics: 'Statistiken abrufen',
        setCountries: 'Länder einstellen',
        changeStatus: 'Status ändern',
        upload: 'Hochladen',
        setDomainSettings: 'Domäneneinstellungen festlegen',
        login: 'Anmeldung',
        update: 'Aktualisieren',
        resetPassword: 'Passwort zurücksetzen',
        addNew: 'Neue hinzufügen',
        addNewPhrase: 'Neuen Satz hinzufügen',
        addNewCategory: 'Neue Kategorie hinzufügen',
        clearAll: 'Alles löschen',
        loginWithGoogle: 'Melden Sie sich mit Google an',
        loginWithFacebook: 'Mit Facebook einloggen',
        onPage: 'auf Seite',
        addNewUsefulLinks: 'Fügen Sie neue nützliche Links hinzu',
        backToChannels: 'Zurück zu den Kanälen',
        defaultSettings: 'Standardeinstellungen',
        upgradeChannel: 'Upgrade-Kanal',
        apply: 'Anwenden',
        remove: 'Entfernen',
        removeSuperAdmin: 'Entfernen Sie den Super-Admin',
        makeSuperAdmin: 'Super-Admin machen',
        makeBrandAdmin: 'Markenadministrator machen',
        removeBrandAdmin: 'Entfernen Sie den Markenadministrator',
        ban: 'Verbot',
        unban: 'Entbannen',
        makeTeamLead: 'Teamleiter machen',
        removeTeamLead: 'Teamleiter entfernen',
        signUp: 'Anmelden',
        ok: 'Ok',
        cancel: 'Stornieren',
        bulkMessaging: 'Massennachrichten',
        back: 'Der rücken',
    },
    Countries: {
        UnitedStates: 'Vereinigte Staaten',
        Canada: 'Kanada',
        Australia: 'Australien',
        CzechRepublic: 'Tschechien',
        Denmark: 'Dänemark',
        Hungary: 'Ungarn',
        Netherlands: 'Niederlande',
        Sweden: 'Schweden',
        UnitedKingdom: 'United Kingdom',
        France: 'Frankreich',
        Germany: 'Deutschland',
        Poland: 'Polen',
        Italy: 'Italien',
        Spain: 'Spanien',
    },
    Languages: {
        Ukrainian: 'Ukrainisch',
        Russian: 'Russisch',
        English: 'Englisch',
        Polish: 'Polieren',
        Deutsch: 'Deutsch',
        Spanish: 'Spanisch',
        Vietnamese: 'Vietnamesisch',
        Portuguese: 'Portugiesisch',
        Korean: 'Koreanisch',
        Italian: 'Italienisch',
        Arabic: 'Arabisch',
        French: 'Französisch',
        Azerbaijan: 'Aserbaidschan',
        Hungarian: 'Ungarisch',
    },
    BulkMessaging: {
        exclude: 'Ausschließen',
        typeText: 'Text eingeben',
        selectClients: 'Kunden auswählen',
        uploadClients: 'Kunden hochladen',
        sendingMessagesToNewClients: 'Senden von Nachrichten an neue Kunden',
        sendingMessagesToExistingClients: 'Senden von Nachrichten an bestehende Kunden',
        clientsWhoHaveDialogsWithFollowingStatus: 'Clients, die Dialoge mit folgendem Status haben',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Kunden, die diesen Text im Vor- oder Nachnamen oder Benutzernamen haben',
        limitQuantity: 'Menge begrenzen',
        quantityLimit: 'Mengenbegrenzung',
        limitQuantityText: 'Beschränken Sie die Menge im Broadcast auf nicht mehr als die angegebene Anzahl von Empfängern. Neuere Clients des Systems werden in die Liste aufgenommen.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Kunden, die vor der letzten Nachrichtenzeit an AskMe geschrieben haben',
    },
};
export default Messages;
