var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { icons } from '@public/images';
import { notificationActions } from '@src/redux/notification/reducer';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import Checkbox from '@src/components/Checkbox';
import { Tab, Tabs } from '@src/components/Tabs/Tabs';
import { ToggleSwitch } from '@src/components/Switch/Switch';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { processingPhraseAndGreetings } from '@src/utils/processingPhraseAndGreetings';
import { greetingScheme, notificationTypes, requiredFieldsValues, enabledLanguagesChannel, } from '@src/global-interfaces';
import { StyledChannelInput } from '@src/modules/Pages/Channels/CreateChannel/styled';
import { StyledCheckboxWrapper } from '@src/modules/Pages/Brands/BrandSettings/UpdateMailInbox/styled';
import { StyledAddNewBtn, StyledSaveBtn } from '@src/components/Button/styled';
import { StyledModalFooterOneBtnWrapper, StyledModalFooterTwoBtnWrapper } from '@src/app/styled';
import { StyledSettingsWrapper, StyledCustomizationsName, StyledSwitchWrapper, StyledPrivacyPolicyWrapper, } from '@src/modules/Pages/Channels/ChannelUpdate/styled';
import { composeValidators, lengthCreator, } from '@src/utils/validations';
import { Messages } from '@public/locales/messages';
var WebChatSettings = function (props) {
    var greeting = props.greeting, getState = props.getState, setGreeting = props.setGreeting, privacyPolicy = props.privacyPolicy, requiredFields = props.requiredFields, showNotification = props.showNotification, setPrivacyPolicy = props.setPrivacyPolicy, setRequiredFields = props.setRequiredFields, setBotNameLocales = props.setBotNameLocales, selectedLanguages = props.selectedLanguages, resetNotificationState = props.resetNotificationState;
    var minGreetingLength = lengthCreator.minGreetingLength, maxGreetingLength = lengthCreator.maxGreetingLength, minBotNameLength = lengthCreator.minBotNameLength, maxBotNameLength = lengthCreator.maxBotNameLength, minFastPhraseLength = lengthCreator.minFastPhraseLength, maxFastPhraseLength = lengthCreator.maxFastPhraseLength;
    var _a = __read(useState(greeting), 2), initialGreeting = _a[0], setInitialGreeting = _a[1];
    var _b = __read(useState(''), 2), currentTab = _b[0], setCurrentTab = _b[1];
    var onTabChangeHandler = function (tab) { return setCurrentTab(tab); };
    var showWarning = function () {
        showNotification({ msg: 'The fields are filled incorrectly', type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var showInfo = function (msg) {
        if (msg === void 0) { msg = 'The fields are filled incorrectly'; }
        showNotification({ msg: msg, type: notificationTypes.info });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var memoizedRequiredFields = useMemo(function () {
        var tempArr = [];
        for (var props_1 in requiredFields) {
            if (requiredFields[props_1]) {
                tempArr.push(props_1);
            }
        }
        return tempArr;
    }, [requiredFields]);
    var memoizedSelectedLanguages = useMemo(function () {
        if (typeof selectedLanguages[0] === 'string') {
            return selectedLanguages.map(function (elem) { return enabledLanguagesChannel.find(function (el) { return el.value === elem; }); });
        }
        return selectedLanguages;
    }, [selectedLanguages]);
    var deletePhrase = function (index) {
        var currentIndex = greeting === null || greeting === void 0 ? void 0 : greeting.findIndex(function (elem) { return elem.locale === currentTab; });
        var phrase = __spreadArray([], __read(initialGreeting === null || initialGreeting === void 0 ? void 0 : initialGreeting.find(function (elem) { return elem.locale === currentTab; }).phrases), false);
        phrase.splice(index, 1);
        var currentGreeting = __assign(__assign({}, greeting[currentIndex]), { phrases: phrase });
        var allGreeting = __spreadArray([], __read(greeting), false);
        allGreeting.splice(currentIndex, 1, currentGreeting);
        setInitialGreeting(allGreeting);
        setGreeting(allGreeting);
    };
    var addPhrase = function () {
        var currentIndex = greeting === null || greeting === void 0 ? void 0 : greeting.findIndex(function (elem) { return elem.locale === currentTab; });
        var phrase = __spreadArray([], __read(initialGreeting === null || initialGreeting === void 0 ? void 0 : initialGreeting.find(function (elem) { return elem.locale === currentTab; }).phrases), false);
        if (phrase.length >= 3)
            return;
        phrase.push('');
        var currentGreeting = __assign(__assign({}, greeting[currentIndex]), { phrases: phrase });
        var allGreeting = __spreadArray([], __read(greeting), false);
        allGreeting.splice(currentIndex, 1, currentGreeting);
        setInitialGreeting(allGreeting);
    };
    var applyPhraseAndGreetings = function (data) {
        var greetings = greetingScheme.map(function (el) {
            if (processingPhraseAndGreetings(data)[el.locale]) {
                return {
                    locale: el.locale,
                    text: processingPhraseAndGreetings(data)[el.locale].greetings || '',
                    phrases: processingPhraseAndGreetings(data)[el.locale].phrases,
                };
            }
            return el;
        });
        var tempObj = {};
        for (var prop in data) {
            if (prop.split('/')[0] === 'botNameLocales') {
                tempObj[prop.split('/')[1]] = data[prop];
            }
        }
        setBotNameLocales(tempObj);
        setGreeting(greetings);
        showInfo(Messages.Info.PhraseBotNameAndGreetingsChanged);
    };
    var handleRequiredFieldsChange = function (changeEvent) {
        var _a, _b;
        if (memoizedRequiredFields.length <= 1 && memoizedRequiredFields.includes(changeEvent.target.value)) {
            showWarning();
            return;
        }
        if (memoizedRequiredFields.includes(changeEvent.target.value)) {
            setRequiredFields(__assign(__assign({}, requiredFields), (_a = {}, _a[changeEvent.target.value] = false, _a)));
            return;
        }
        setRequiredFields(__assign(__assign({}, requiredFields), (_b = {}, _b[changeEvent.target.value] = true, _b)));
    };
    var onChangeEnabledPrivacyPolicy = function (e) {
        setPrivacyPolicy(__assign(__assign({}, privacyPolicy), { enabled: e.target.checked }));
    };
    var setDataPrivacyPolicy = function (data) {
        var _a, _b;
        var tempObj = {};
        for (var prop in data) {
            if (prop.split('policy-')[1]) {
                var policyText = {};
                var currentLanguage = prop.split('/')[1];
                for (var value in data) {
                    if (((_a = value.split('policy-')[1]) === null || _a === void 0 ? void 0 : _a.split('/')[1]) === currentLanguage) {
                        policyText[(_b = value.split('policy-')[1]) === null || _b === void 0 ? void 0 : _b.split('/')[0]] = data[value];
                    }
                }
                tempObj[prop === null || prop === void 0 ? void 0 : prop.split('/')[1]] = policyText;
            }
        }
        setPrivacyPolicy(__assign(__assign({}, privacyPolicy), { data: tempObj }));
        showInfo("".concat(Messages.Success.PrivacyPolicyChanged));
    };
    var ChannelInput = InputWithStyles(Input, StyledChannelInput);
    return (React.createElement(StyledSettingsWrapper, null,
        React.createElement(StyledCustomizationsName, null, Messages.Labels.ChangeGreetingsAndPhrasePrivacyPolicy),
        React.createElement(Tabs, { isShowBottomLine: true, callback: onTabChangeHandler, currentTab: currentTab }, memoizedSelectedLanguages === null || memoizedSelectedLanguages === void 0 ? void 0 : memoizedSelectedLanguages.map(function (el, index) {
            var _a;
            return (React.createElement(Tab, { tabKey: el.value, title: el.text, key: index },
                React.createElement(Field, { name: "greetings/".concat(el.value), label: Messages.Labels.Greetings, showWarning: showWarning, multiple: true, placeholder: Messages.Controls.Greetings, component: ChannelInput, validate: composeValidators(minGreetingLength, maxGreetingLength), maxLength: 257 }),
                React.createElement(Field, { name: "botNameLocales/".concat(el.value), label: Messages.Labels.BotName, showWarning: showWarning, placeholder: Messages.Controls.BotName, component: ChannelInput, validate: composeValidators(minBotNameLength, maxBotNameLength), maxLength: 31 }), (_a = initialGreeting === null || initialGreeting === void 0 ? void 0 : initialGreeting.find(function (elem) { return elem.locale === currentTab; })) === null || _a === void 0 ? void 0 :
                _a.phrases.map(function (el, index) { return (React.createElement(Field, { key: index, name: "fastPhrase\u2116".concat(index + 1, "/").concat(currentTab), icon: icons.trash, iconOnclick: function () { return deletePhrase(index); }, label: "".concat(Messages.Labels.FastPhrase).concat(index + 1), showWarning: showWarning, placeholder: "".concat(Messages.Controls.FastPhrase).concat(index + 1), component: ChannelInput, validate: composeValidators(minFastPhraseLength, maxFastPhraseLength), maxLength: 2001 })); }),
                React.createElement(StyledModalFooterTwoBtnWrapper, null,
                    React.createElement(StyledAddNewBtn, null,
                        React.createElement(Button, { title: "+ ".concat(Messages.Buttons.addPhrase), onClick: addPhrase })),
                    React.createElement(StyledSaveBtn, null,
                        React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return applyPhraseAndGreetings(getState().values); } }))),
                React.createElement(StyledPrivacyPolicyWrapper, null,
                    React.createElement(StyledSwitchWrapper, null,
                        React.createElement(ToggleSwitch, { label: "enabledPrivacyPolicy", onChange: onChangeEnabledPrivacyPolicy, checked: privacyPolicy.enabled }),
                        React.createElement("div", { className: "title" }, Messages.Labels.EnabledPrivacyPolicy))),
                privacyPolicy.enabled && (React.createElement(React.Fragment, null,
                    React.createElement(Field, { name: "policy-text/".concat(el.value), label: Messages.Labels.PrivacyPolicyText, showWarning: showWarning, placeholder: Messages.Controls.PrivacyPolicyText, component: ChannelInput }),
                    React.createElement(Field, { name: "policy-link/".concat(el.value), label: Messages.Labels.PrivacyPolicyLink, showWarning: showWarning, placeholder: Messages.Controls.PrivacyPolicyLink, component: ChannelInput }),
                    React.createElement(Field, { name: "policy-linkText/".concat(el.value), label: Messages.Labels.PrivacyPolicyLinkText, showWarning: showWarning, placeholder: Messages.Controls.PrivacyPolicyLinkText, component: ChannelInput }),
                    React.createElement(StyledModalFooterOneBtnWrapper, null,
                        React.createElement(StyledSaveBtn, null,
                            React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setDataPrivacyPolicy(getState().values); } })))))));
        })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.PleaseChooseRequiredFields),
        React.createElement(StyledCheckboxWrapper, null, requiredFieldsValues.map(function (el, index) { return (React.createElement(Checkbox, { isCircle: false, key: index, checked: memoizedRequiredFields.includes(el.value), label: el.text, onChange: handleRequiredFieldsChange, value: el.value })); }))));
};
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(null, mapDispatchToProps)(WebChatSettings);
