import React from 'react';
import StyledBotMessage from './StyledBotMessage';
var BotMessage = function (props) {
    var customizationGreeting = props.customizationGreeting, botNameByLocale = props.botNameByLocale, greetingByCurrentLocale = props.greetingByCurrentLocale;
    var messageGreetingColor = customizationGreeting.messageGreetingColor, colorGreetingBotName = customizationGreeting.colorGreetingBotName, messageGreetingIconUrlBot = customizationGreeting.messageGreetingIconUrlBot, messageGreetingBorderColor = customizationGreeting.messageGreetingBorderColor, messageGreetingBackgroundColor = customizationGreeting.messageGreetingBackgroundColor, messageGreetingIconBackgroundBot = customizationGreeting.messageGreetingIconBackgroundBot;
    return (React.createElement(StyledBotMessage, { messageGreetingBackgroundColor: messageGreetingBackgroundColor, messageGreetingBorderColor: messageGreetingBorderColor, messageGreetingColor: messageGreetingColor, colorGreetingBotName: colorGreetingBotName, messageGreetingIconBackgroundBot: messageGreetingIconBackgroundBot },
        React.createElement("div", { className: "bot-icon" },
            React.createElement("img", { src: messageGreetingIconUrlBot, alt: "" })),
        React.createElement("div", { className: "bot-greeting-wrap" },
            React.createElement("p", { className: "bot-name" }, botNameByLocale || 'ASK ME BOT'),
            greetingByCurrentLocale && React.createElement("div", { className: "bot-message" }, greetingByCurrentLocale || ''))));
};
export default BotMessage;
