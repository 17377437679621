var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Messages } from '@public/locales/messages';
import { TButtonTypes } from '@src/components/Button/Button';
import { notificationTypes } from '@src/global-interfaces';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import Select from '@src/components/Select';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { transformDictionary } from '@src/utils/transformDictionary';
import { composeValidators, isRequired, lengthCreator, urlCorrect, } from '@src/utils/validations';
import { dictionariesStore } from '@src/redux/dictionaries/selector';
import { notificationActions } from '@src/redux/notification/reducer';
import { StyledSaveBtn } from '@src/components/Button/styled';
import { StyledChannelInput, StyledStepWrapper } from '@src/modules/Pages/Channels/CreateChannel/styled';
import { StyledModalContentWrapper, StyledModalContentFieldsWrapper, StyledModalFooterOneBtnWrapper } from '@src/app/styled';
var StepOne = function (props) {
    var channelURL = props.channelURL, currentStep = props.currentStep, channelName = props.channelName, selectedBrand = props.selectedBrand, setChannelURL = props.setChannelURL, setCurrentStep = props.setCurrentStep, setChannelName = props.setChannelName, brandDictionary = props.brandDictionary, setSelectedBrand = props.setSelectedBrand, showNotification = props.showNotification, resetNotificationState = props.resetNotificationState;
    var minNameLength = lengthCreator.minNameLength, maxNameLength = lengthCreator.maxNameLength;
    var _a = __read(useState(true), 2), isSelectedBrand = _a[0], setIsSelectedBrand = _a[1];
    var showWarning = function () {
        showNotification({ msg: Messages.Warning.FieldIsRequired, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onSubmit = function (data) {
        var channelName = data.channelName, channelURL = data.channelURL;
        if (!selectedBrand.value) {
            setIsSelectedBrand(false);
            return;
        }
        setChannelURL(channelURL);
        setChannelName(channelName);
        setCurrentStep(currentStep + 1);
    };
    var ChannelInput = InputWithStyles(Input, StyledChannelInput);
    return (React.createElement(StyledStepWrapper, null,
        React.createElement(Form, { onSubmit: onSubmit, initialValues: { channelName: channelName, channelURL: channelURL }, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(StyledModalContentWrapper, null,
                        React.createElement(StyledModalContentFieldsWrapper, null,
                            React.createElement(Field, { name: "channelName", label: Messages.Labels.ChannelName, validate: composeValidators(isRequired, minNameLength, maxNameLength), showWarning: showWarning, placeholder: Messages.Controls.ChannelName, component: ChannelInput }),
                            React.createElement(Field, { name: "channelURL", label: Messages.Labels.ChannelURL, validate: composeValidators(isRequired, urlCorrect), showWarning: showWarning, placeholder: Messages.Controls.ChannelURL, component: ChannelInput }),
                            React.createElement(Select, { initialState: [selectedBrand] || [], label: Messages.Labels.SelectBrand, error: !isSelectedBrand, placeholder: Messages.Selects.SelectBrand, items: transformDictionary(brandDictionary, 'text', 'value'), stateCB: function (el) { return setSelectedBrand(el); }, isMultiply: false })),
                        React.createElement(StyledModalFooterOneBtnWrapper, null,
                            React.createElement(StyledSaveBtn, null,
                                React.createElement(Button, { title: Messages.Buttons.next, onClick: function () { }, type: TButtonTypes.submit }))))));
            } })));
};
var mapStateToProps = function (state) { return ({
    brandDictionary: dictionariesStore.dictionaries(state).brandDictionary,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
