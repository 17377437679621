var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Messages } from '@public/locales/messages';
import { StyledModalFooterOneBtnWrapper } from '@src/app/styled';
import Button from '@src/components/Button';
import { StyledSaveBtn } from '@src/components/Button/styled';
import ColorPickerBtn from '@src/components/ColorPickerBtn';
import ColorPicker from '@src/components/CustomColorPicker';
import CustomSlider from '@src/components/CustomSlider';
import Input from '@src/components/Input';
import { InputWithStyles } from '@src/components/Input/InputWithStyles';
import { ToggleSwitch } from '@src/components/Switch/Switch';
import { Tab, Tabs } from '@src/components/Tabs/Tabs';
import { getDefaultBotIcon, getDefaultLogo, getDefaultSendIcon } from '@src/constants/defaultCustomizations';
import { CustomizationsName, enabledLanguagesChannel, notificationTypes } from '@src/global-interfaces';
import { StyledCustomizationsBtnWrapper, StyledCustomizationsName, StyledSettingsWrapper, StyledSwitchWrapper, } from '@src/modules/Pages/Channels/ChannelUpdate/styled';
import { StyledChannelInput } from '@src/modules/Pages/Channels/CreateChannel/styled';
import { modalWindowsActions } from '@src/redux/modalWindows/reducer';
import { modalWindowsStore } from '@src/redux/modalWindows/selector';
import { modalTypes } from '@src/redux/modalWindows/toggleModal';
import { notificationActions } from '@src/redux/notification/reducer';
import { processingCustomizations } from '@src/utils/processingСustomizations';
import { urlValidation } from '@src/utils/validations';
var WebChatConstructor = function (props) {
    var getState = props.getState, toggleModal = props.toggleModal, customizations = props.customizations, showNotification = props.showNotification, isOpenColorPicker = props.isOpenColorPicker, setCustomizations = props.setCustomizations, selectedLanguages = props.selectedLanguages, resetNotificationState = props.resetNotificationState;
    var _a = __read(useState(''), 2), currentTab = _a[0], setCurrentTab = _a[1];
    var onTabChangeHandler = function (tab) { return setCurrentTab(tab); };
    var memoizedCustomizations = useMemo(function () { return processingCustomizations(customizations); }, [customizations]);
    var memoizedSelectedLanguages = useMemo(function () {
        if (typeof selectedLanguages[0] === 'string') {
            return selectedLanguages.map(function (elem) { return enabledLanguagesChannel.find(function (el) { return el.value === elem; }); });
        }
        return selectedLanguages;
    }, [selectedLanguages]);
    var showWarning = function (msg) {
        showNotification({ msg: msg, type: notificationTypes.warning });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var showInfo = function (msg) {
        if (msg === void 0) { msg = Messages.Warning.FieldIsRequired; }
        showNotification({ msg: msg, type: notificationTypes.info });
        setTimeout(function () { return resetNotificationState(); }, 1000);
    };
    var onChangeDisableDraggable = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { disableDraggable: e.target.checked }) }));
    };
    var onChangeDisableHeaderFullScreen = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { disableHeaderFullScreen: e.target.checked }) }));
    };
    var onChangeDisableHeaderHideChatWindow = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { disableHeaderHideChatWindow: e.target.checked }) }));
    };
    var onChangeDisableHeaderLogo = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { disableHeaderLogo: e.target.checked }) }));
    };
    var onChangeDisableHeaderShowEndDialogModal = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { disableHeaderShowEndDialogModal: e.target.checked }) }));
    };
    var setHeaderText = function (data) {
        var _a, _b, _c, _d;
        var tempObj = {};
        for (var prop in data) {
            if (prop.split('header-')[1]) {
                var headerText = {};
                var currentLanguage = (_a = prop.split('header-')[1]) === null || _a === void 0 ? void 0 : _a.split('/')[1];
                for (var value in data) {
                    if (((_b = value.split('header-')[1]) === null || _b === void 0 ? void 0 : _b.split('/')[1]) === currentLanguage) {
                        headerText[(_c = value.split('header-')[1]) === null || _c === void 0 ? void 0 : _c.split('/')[0]] = data[value];
                    }
                }
                tempObj[(_d = prop.split('header-')[1]) === null || _d === void 0 ? void 0 : _d.split('/')[1]] = headerText;
            }
        }
        setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { headerText: __assign(__assign({}, customizations.header.headerText), tempObj) }) }));
        showInfo(Messages.Success.HeaderTextChanged);
    };
    var onChangeHeaderHeight = function (value) {
        setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { headerHeight: "".concat(value, "px") }) }));
    };
    var setLogoIconURL = function (value) {
        if (urlValidation(value)) {
            setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { logoUrl: value }) }));
        }
        else {
            setCustomizations(__assign(__assign({}, customizations), { header: __assign(__assign({}, customizations.header), { logoUrl: getDefaultLogo() }) }));
        }
        showInfo(Messages.Success.LogoIconUrlChanged);
    };
    var setIconURL = function (value) {
        if (urlValidation(value)) {
            setCustomizations(__assign(__assign({}, customizations), { icon: __assign(__assign({}, customizations.icon), { iconUrl: value }) }));
        }
        else {
            setCustomizations(__assign(__assign({}, customizations), { icon: __assign(__assign({}, customizations.icon), { iconUrl: '' }) }));
        }
        showInfo(Messages.Success.IconUrlChanged);
    };
    var setScrollWidth = function (value) {
        setCustomizations(__assign(__assign({}, customizations), { scroll: __assign(__assign({}, customizations.scroll), { scrollWidth: "".concat(value, "px") }) }));
    };
    var setBackgroundImageURL = function (value) {
        if (urlValidation(value)) {
            setCustomizations(__assign(__assign({}, customizations), { chatWindow: __assign(__assign({}, customizations.chatWindow), { chatWindowsLogo: value }) }));
        }
        else {
            setCustomizations(__assign(__assign({}, customizations), { chatWindow: __assign(__assign({}, customizations.chatWindow), { chatWindowsLogo: '' }) }));
        }
        showInfo(Messages.Success.BackgroundImageUrlChanged);
    };
    var setGreetingImageBotURL = function (value) {
        if (urlValidation(value)) {
            setCustomizations(__assign(__assign({}, customizations), { greeting: __assign(__assign({}, customizations.greeting), { messageGreetingIconUrlBot: value }) }));
        }
        else {
            setCustomizations(__assign(__assign({}, customizations), { greeting: __assign(__assign({}, customizations.greeting), { messageGreetingIconUrlBot: getDefaultBotIcon() }) }));
        }
        showInfo(Messages.Success.GreetingImageUrlChanged);
    };
    var setMessageIconUrlAgent = function (value) {
        if (urlValidation(value)) {
            setCustomizations(__assign(__assign({}, customizations), { agentMessage: __assign(__assign({}, customizations.agentMessage), { messageIconUrlAgent: value }) }));
        }
        else {
            setCustomizations(__assign(__assign({}, customizations), { agentMessage: __assign(__assign({}, customizations.agentMessage), { messageIconUrlAgent: getDefaultBotIcon() }) }));
        }
        showInfo(Messages.Success.AgentImageUrlChanged);
    };
    var setBackgroundImageSize = function (value) {
        setCustomizations(__assign(__assign({}, customizations), { chatWindow: __assign(__assign({}, customizations.chatWindow), { chatWindowsLogoSize: "".concat(value, "px") }) }));
    };
    var setInputPlaceholder = function (value) {
        setCustomizations(__assign(__assign({}, customizations), { footer: __assign(__assign({}, customizations.footer), { inputPlaceholder: value }) }));
        showInfo(Messages.Success.InputPlaceholderChanged);
    };
    var setSendImage = function (value) {
        if (urlValidation(value)) {
            setCustomizations(__assign(__assign({}, customizations), { footer: __assign(__assign({}, customizations.footer), { sendImage: value }) }));
        }
        else {
            setCustomizations(__assign(__assign({}, customizations), { footer: __assign(__assign({}, customizations.footer), { sendImage: getDefaultSendIcon() }) }));
        }
        showInfo(Messages.Success.SendImageUrlChanged);
    };
    var setSendButtonIconHeight = function (value) {
        setCustomizations(__assign(__assign({}, customizations), { footer: __assign(__assign({}, customizations.footer), { sendButtonIconHeight: "".concat(value, "px") }) }));
    };
    var setSendButtonIconWidth = function (value) {
        setCustomizations(__assign(__assign({}, customizations), { footer: __assign(__assign({}, customizations.footer), { sendButtonIconWidth: "".concat(value, "px") }) }));
    };
    var setColor = function (color, customizationModule, customizationName) {
        var _a, _b;
        setCustomizations(__assign(__assign({}, customizations), (_a = {}, _a[customizationModule] = __assign(__assign({}, customizations[customizationModule]), (_b = {}, _b[customizationName] = color, _b)), _a)));
    };
    var onChangeDisableTextarea = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { footerElements: __assign(__assign({}, customizations.footerElements), { isDisableTextArea: e.target.checked }) }));
    };
    var onChangeDisableAttachmentFile = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { footerElements: __assign(__assign({}, customizations.footerElements), { isDisableAttachmentFile: e.target.checked }) }));
    };
    var onChangeDisableMakePhoto = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { footerElements: __assign(__assign({}, customizations.footerElements), { isDisableMakePhoto: e.target.checked }) }));
    };
    var onChangeDisableMicrophone = function (e) {
        setCustomizations(__assign(__assign({}, customizations), { footerElements: __assign(__assign({}, customizations.footerElements), { isDisableMicrophone: e.target.checked }) }));
    };
    var ChannelInput = InputWithStyles(Input, StyledChannelInput);
    return (React.createElement(StyledSettingsWrapper, null,
        React.createElement(StyledCustomizationsName, null, "Input Types"),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableTextarea", onChange: onChangeDisableTextarea, checked: customizations.footerElements.isDisableTextArea }),
            React.createElement("div", { className: "title" }, "Disable Text Input")),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableAttachmentFile", onChange: onChangeDisableAttachmentFile, checked: customizations.footerElements.isDisableAttachmentFile }),
            React.createElement("div", { className: "title" }, "Disable Files")),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableMakePhoto", onChange: onChangeDisableMakePhoto, checked: customizations.footerElements.isDisableMakePhoto }),
            React.createElement("div", { className: "title" }, "Disable Camera")),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableMicrophone", onChange: onChangeDisableMicrophone, checked: customizations.footerElements.isDisableMicrophone }),
            React.createElement("div", { className: "title" }, "Disable Audio")),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.HeaderSettings),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableDraggable", onChange: onChangeDisableDraggable, checked: customizations.header.disableDraggable }),
            React.createElement("div", { className: "title" }, Messages.Labels.DisableDraggable)),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableHeaderFullScreen", onChange: onChangeDisableHeaderFullScreen, checked: customizations.header.disableHeaderFullScreen }),
            React.createElement("div", { className: "title" }, Messages.Labels.DisableHeaderFullScreen)),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableHeaderHideChatWindow", onChange: onChangeDisableHeaderHideChatWindow, checked: customizations.header.disableHeaderHideChatWindow }),
            React.createElement("div", { className: "title" }, Messages.Labels.DisableHeaderHideChatWindow)),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableHeaderLogo", onChange: onChangeDisableHeaderLogo, checked: customizations.header.disableHeaderLogo }),
            React.createElement("div", { className: "title" }, Messages.Labels.DisableHeaderLogo)),
        React.createElement(StyledSwitchWrapper, null,
            React.createElement(ToggleSwitch, { label: "disableHeaderShowEndDialogModal", onChange: onChangeDisableHeaderShowEndDialogModal, checked: customizations.header.disableHeaderShowEndDialogModal }),
            React.createElement("div", { className: "title" }, Messages.Labels.DisableHeaderShowEndDialogModal)),
        React.createElement("div", null, Messages.Labels.HeaderText),
        React.createElement(Tabs, { isShowBottomLine: true, callback: onTabChangeHandler, currentTab: currentTab }, memoizedSelectedLanguages === null || memoizedSelectedLanguages === void 0 ? void 0 : memoizedSelectedLanguages.map(function (el, index) { return (React.createElement(Tab, { tabKey: (el === null || el === void 0 ? void 0 : el.value) ? el === null || el === void 0 ? void 0 : el.value : '', title: (el === null || el === void 0 ? void 0 : el.text) ? el === null || el === void 0 ? void 0 : el.text : '', key: index },
            React.createElement(Field, { name: "header-headerTextTop/".concat(el.value), label: Messages.Labels.HeaderTextTop, showWarning: showWarning, placeholder: Messages.Controls.HeaderTextTop, component: ChannelInput }),
            React.createElement(Field, { name: "header-headerTextBottom/".concat(el.value), label: Messages.Labels.HeaderTextBottom, showWarning: showWarning, placeholder: Messages.Controls.HeaderTextBottom, component: ChannelInput }))); })),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setHeaderText(getState().values); } }))),
        React.createElement("div", null, Messages.Labels.HeaderHeight),
        React.createElement(CustomSlider, { min: 40, max: 100, marks: { 40: '40px', 100: '100px' }, onChange: onChangeHeaderHeight, value: +customizations.header.headerHeight.split('px')[0] || 40 }),
        React.createElement(Field, { name: "logoIconURL", label: Messages.Labels.LogoIconUrl, showWarning: showWarning, placeholder: Messages.Controls.LogoIconUrl, component: ChannelInput }),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setLogoIconURL(getState().values.logoIconURL); } }))),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.header.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.IconSettings),
        React.createElement(Field, { name: "iconUrl", label: Messages.Labels.IconUrl, showWarning: showWarning, placeholder: Messages.Controls.IconUrl, component: ChannelInput }),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setIconURL(getState().values.iconUrl); } }))),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.icon.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.ScrollSettings),
        React.createElement("div", null, Messages.Labels.ScrollWidth),
        React.createElement(CustomSlider, { min: 10, max: 20, marks: { 10: '10px', 20: '20px' }, onChange: setScrollWidth, value: customizations.scroll.scrollWidth.split('px')[0] || 10 }),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.scroll.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.ChatWindowSettings),
        React.createElement(Field, { name: "chatWindowsLogo", label: Messages.Labels.ChatWindowsLogo, showWarning: showWarning, placeholder: Messages.Controls.ChatWindowsLogo, component: ChannelInput }),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setBackgroundImageURL(getState().values.chatWindowsLogo); } }))),
        React.createElement("div", null, Messages.Labels.BackgroundImageSize),
        React.createElement(CustomSlider, { min: 25, max: 300, marks: { 25: '25px', 300: '300px' }, onChange: setBackgroundImageSize, value: +customizations.chatWindow.chatWindowsLogoSize.split('px')[0] || 25 }),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.chatWindow.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.GreetingSettings),
        React.createElement(Field, { name: "messageGreetingIconUrlBot", label: Messages.Labels.GreetingIconBotUrl, showWarning: showWarning, placeholder: Messages.Controls.GreetingIconBotUrl, component: ChannelInput }),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setGreetingImageBotURL(getState().values.messageGreetingIconUrlBot); } }))),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.greeting.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.QuickPhrasesSettings),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.quickPhrases.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.ClientMessageSettings),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.clientMsg.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.AgentMessageSettings),
        React.createElement(Field, { name: "messageIconUrlAgent", label: Messages.Labels.AgentMessageIconUrl, showWarning: showWarning, placeholder: Messages.Controls.AgentMessageIconUrl, component: ChannelInput }),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setMessageIconUrlAgent(getState().values.messageIconUrlAgent); } }))),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.agentMessage.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.EndDialogModalSettings),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.endDialogModal.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.AuthWindowSettings),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.auth.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        React.createElement(StyledCustomizationsName, null, Messages.Labels.FooterSettings),
        React.createElement(Field, { name: "inputMessagesPlaceholder", label: Messages.Labels.WriteMessagesPlaceholder, showWarning: showWarning, placeholder: Messages.Controls.WriteMessagesPlaceholder, component: ChannelInput }),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setInputPlaceholder(getState().values.inputMessagesPlaceholder); } }))),
        React.createElement(Field, { name: "sendImageUrl", label: Messages.Labels.SendImageUrl, showWarning: showWarning, placeholder: Messages.Controls.SendImageUrl, component: ChannelInput }),
        React.createElement(StyledModalFooterOneBtnWrapper, null,
            React.createElement(StyledSaveBtn, null,
                React.createElement(Button, { title: Messages.Buttons.apply, onClick: function () { return setSendImage(getState().values.sendImageUrl); } }))),
        React.createElement("div", null, Messages.Labels.SendButtonIconHeight),
        React.createElement(CustomSlider, { min: 20, max: 50, marks: { 20: '20px', 50: '50px' }, onChange: setSendButtonIconHeight, value: +customizations.footer.sendButtonIconHeight.split('px')[0] || 25 }),
        React.createElement("div", null, Messages.Labels.SendButtonIconWidth),
        React.createElement(CustomSlider, { min: 20, max: 50, marks: { 20: '20px', 50: '50px' }, onChange: setSendButtonIconWidth, value: +customizations.footer.sendButtonIconWidth.split('px')[0] || 25 }),
        React.createElement(StyledCustomizationsBtnWrapper, null, memoizedCustomizations.footer.map(function (el, index) { return (React.createElement(ColorPickerBtn, { key: index, title: CustomizationsName[el.customizationName], onClick: function () {
                return toggleModal({
                    title: CustomizationsName[el.customizationName],
                    customizationModule: el.customizationModule,
                    customizationName: el.customizationName,
                    setColor: function (color) {
                        setColor(color, el.customizationModule, el.customizationName);
                    },
                });
            }, valueColor: customizations[el.customizationModule][el.customizationName] })); })),
        isOpenColorPicker && React.createElement(ColorPicker, { customizations: customizations })));
};
var mapStateToProps = function (state) { return ({
    isOpenColorPicker: modalWindowsStore.getIsModalOpen(state, modalTypes.CHANGE_COLOR_PICKER),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    toggleModal: function (data) { return dispatch(modalWindowsActions.toggleModal(modalTypes.CHANGE_COLOR_PICKER, data)); },
    showNotification: function (data) { return dispatch(notificationActions.showNotification(data)); },
    resetNotificationState: function () { return dispatch(notificationActions.resetNotificationState()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(WebChatConstructor);
