var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { slideInRight } from 'react-animations';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { scrollStyles } from '@src/app/styled';
export var StyledChannelUpdateWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    width: 100%;\n    height: 100%;\n    background: ", ";\n    position: fixed;\n    top: 0;\n    z-index: 1;\n  }\n"], ["\n  && {\n    width: 100%;\n    height: 100%;\n    background: ", ";\n    position: fixed;\n    top: 0;\n    z-index: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
export var StyledChannelUpdateContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    width: 100%;\n    height: 90%;\n    overflow: auto;\n    padding: 5px;\n    ", ";\n  }\n"], ["\n  && {\n    width: 100%;\n    height: 90%;\n    overflow: auto;\n    padding: 5px;\n    ", ";\n  }\n"])), scrollStyles);
export var StyledChannelUpdateContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  && {\n    animation: 0.3s ", " ease-in-out;\n    padding-top: 24px;\n    max-width: 1300px;\n    width: 100%;\n    margin: 0 auto;\n    display: flex;\n    justify-content: space-between;\n  }\n"], ["\n  && {\n    animation: 0.3s ", " ease-in-out;\n    padding-top: 24px;\n    max-width: 1300px;\n    width: 100%;\n    margin: 0 auto;\n    display: flex;\n    justify-content: space-between;\n  }\n"])), keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["", ""], ["", ""])), slideInRight));
export var StyledChannelSettingsWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  && {\n    max-width: 800px;\n    width: 100%;\n  }\n"], ["\n  && {\n    max-width: 800px;\n    width: 100%;\n  }\n"])));
export var StyledViewAskMeWebChat = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  && {\n    max-width: 450px;\n    bottom: 10px;\n    margin-top: ", "px;\n    padding: 25px;\n    background: ", ";\n    min-height: 750px;\n    height: max-content;\n    width: 100%;\n  }\n"], ["\n  && {\n    max-width: 450px;\n    bottom: 10px;\n    margin-top: ", "px;\n    padding: 25px;\n    background: ", ";\n    min-height: 750px;\n    height: max-content;\n    width: 100%;\n  }\n"])), function (_a) {
    var positionScroll = _a.positionScroll;
    return positionScroll;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.tableRow;
});
export var StyledAskMeWebChat = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    position: relative;\n    min-height: 596px;\n    width: 100%;\n  }\n"], ["\n  && {\n    position: relative;\n    min-height: 596px;\n    width: 100%;\n  }\n"])));
export var StyledBackToChannels = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    display: flex;\n    cursor: pointer;\n\n    img {\n      width: 20px;\n      height: 20px;\n      transform: rotate(90deg);\n      cursor: pointer;\n      filter: ", ";\n    }\n  }\n"], ["\n  && {\n    color: ", ";\n    display: flex;\n    cursor: pointer;\n\n    img {\n      width: 20px;\n      height: 20px;\n      transform: rotate(90deg);\n      cursor: pointer;\n      filter: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, function (_a) {
    var theme = _a.theme;
    return theme.invert;
});
export var StyledChannelSettingsAccordionWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  && {\n    max-width: 800px;\n    width: 100%;\n  }\n"], ["\n  && {\n    max-width: 800px;\n    width: 100%;\n  }\n"])));
export var StyledChannelSettingsBtnWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  && {\n    margin: 30px 0;\n    display: flex;\n    justify-content: space-between;\n\n    .title {\n      color: ", ";\n      font-size: 18px;\n      font-weight: 500;\n    }\n\n    .btn-wrapper {\n      display: flex;\n      justify-content: space-between;\n\n      div:nth-of-type(n) {\n        margin-left: 10px;\n      }\n    }\n  }\n"], ["\n  && {\n    margin: 30px 0;\n    display: flex;\n    justify-content: space-between;\n\n    .title {\n      color: ", ";\n      font-size: 18px;\n      font-weight: 500;\n    }\n\n    .btn-wrapper {\n      display: flex;\n      justify-content: space-between;\n\n      div:nth-of-type(n) {\n        margin-left: 10px;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledSettingsWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  && {\n    padding: 15px 40px;\n    overflow: auto;\n    position: relative;\n    color: ", ";\n    ", "\n  }\n"], ["\n  && {\n    padding: 15px 40px;\n    overflow: auto;\n    position: relative;\n    color: ", ";\n    ", "\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
}, scrollStyles);
export var StyledSelectWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  && {\n    padding-bottom: 5px;\n  }\n"], ["\n  && {\n    padding-bottom: 5px;\n  }\n"])));
export var StyledSwitchWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n\n    .title {\n      margin-left: 8px;\n    }\n  }\n"], ["\n  && {\n    color: ", ";\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n\n    .title {\n      margin-left: 8px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledLoaderWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  && {\n    position: relative;\n    width: 50px;\n    margin: 0 auto;\n    height: 50px;\n  }\n"], ["\n  && {\n    position: relative;\n    width: 50px;\n    margin: 0 auto;\n    height: 50px;\n  }\n"])));
export var StyledCustomizationsName = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  && {\n    color: ", ";\n    font-size: 15px;\n    padding: 5px 0;\n    display: flex;\n    justify-content: center;\n  }\n"], ["\n  && {\n    color: ", ";\n    font-size: 15px;\n    padding: 5px 0;\n    display: flex;\n    justify-content: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var StyledCustomizationsBtnWrapper = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  && {\n    display: flex;\n    flex-wrap: wrap;\n  }\n"], ["\n  && {\n    display: flex;\n    flex-wrap: wrap;\n  }\n"])));
export var StyledWebChatWindowWrapper = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  && {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n"], ["\n  && {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n"])));
export var StyledPrivacyPolicyWrapper = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  && {\n    padding-top: 10px;\n  }\n"], ["\n  && {\n    padding-top: 10px;\n  }\n"])));
export var StyledIntegrationCRMWrapper = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  && {\n    padding: 15px 40px;\n  }\n"], ["\n  && {\n    padding: 15px 40px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
