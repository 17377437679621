var Messages = {
    Success: {
        Success: 'Success',
        ForgotPasswordSend: 'Password changing link was send to your email',
        ResetPasswordSuccess: 'Your password has been successfully updated',
        BrandCreated: 'Brand created!',
        MailInboxCreated: 'Mail inbox created!',
        PrivacyPolicyChanged: 'Privacy policy changed!',
        SmsInboxCreated: 'Sms inbox created!',
        InboxDeleted: 'Inbox deleted!',
        StatusChanged: 'Status changed!',
        FileLoaded: 'File loaded!',
        AuthMail: 'Auth Mail!',
        DomainSettings: 'Domain Settings!',
        UpdatedSmsAccounts: 'Updated sms accounts!',
        ChanelCreated: 'Channel created!',
        DeleteMessenger: 'Delete Messenger!',
        AddMessenger: 'Add Messenger!',
        ChangedMessenger: 'Changed Messenger!',
        ClientCreated: 'Client created!',
        ClientsImports: 'Clients imports!',
        GroupCreated: 'Group created!',
        ChangedAssignedAgentsInGroups: 'Changed assigned agents in groups!',
        ChangedAssignedChannelsInGroups: 'Changed assigned channels in groups!',
        ChangedAssignedTagsInGroups: 'Changed assigned tags in groups!',
        CreateNewCategory: 'Create new category!',
        CreateNewPhrase: 'Create new phrase!',
        DeleteCategory: 'Delete category!',
        DeletePhrase: 'Delete phrase!',
        UpdatePhrase: 'Update phrase!',
        EditCategory: 'Edit category!',
        DeleteAllCategory: 'Delete all category!',
        TagCreated: 'Tag created!',
        TagUpdated: 'Tag updated!',
        TelegramInboxCreated: 'Telegram inbox created!',
        HeaderTextChanged: 'Header text changed',
        LogoIconUrlChanged: 'Logo icon url changed',
        IconUrlChanged: 'Icon url changed',
        BackgroundImageUrlChanged: 'Background image url changed',
        GreetingImageUrlChanged: 'Greeting image url changed',
        AgentImageUrlChanged: 'Agent image url changed',
        InputPlaceholderChanged: 'Input placeholder changed',
        SendImageUrlChanged: 'Send image url changed',
        AgentStatusChanged: 'Agent status changed',
        SelectedAgentTagsChanged: 'Selected agent tags changed',
        AssignedAgentsGroupsChanged: 'Assigned agents groups changed',
        TeamLeadChanged: 'Team lead changed',
        AgentRoleChanged: 'Agent role changed',
        AgentInfoChanged: 'Agent information changed',
    },
    Errors: {
        PasswordMatching: 'Passwords do not match!',
        IncorrectEmail: 'Email is incorrect',
        IncorrectPhone: 'Phone is incorrect',
        IncorrectUrl: 'Url is incorrect',
        FieldContainSpaces: 'Field contain spaces',
        FieldIsRequired: 'Field is required',
        NameValidation: 'Field length must be from 2 to 40 characters inclusive',
        NumberValidation: 'Field must contain only numbers',
        PasswordValidation: 'Password must contain alphabets and numbers and can\'t contain spaces',
        InvoiceNumberValidation: 'Invoice number must contain Latin letters, numbers and special characters',
        MinimumLength: 'Minimum length {0} symbols',
        MaximumLength: 'Maximum length {0} symbols',
        ServerError: 'Server error, please try it later!',
        ServerErrorFileDownloadTimeOut: 'Server error, file download timed out, please try it later!',
        NotValidEmailOrPassword: 'Not valid email or password',
        UserWasNotFound: 'User was not found!',
        UserIsAlreadyExist: 'User is already exist!',
        AgentWasBanned: 'Agent was banned',
        TokenExpired: 'The session has expired. Please re-login',
        TelegramIdIsIncorrect: 'Telegram id is incorrect',
        ErrorWrongFileFormat: 'Error, wrong file format!',
        FileSizeExceededWarnings: 'File size exceeded warnings!',
        BrandWithSuchNameIsAlreadyExisting: 'Brand with such name is already existing!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'You have already selected a filter',
        AgentCreate: 'You are new agent in the AskMe system. Please note that you can only log in after you have been assigned the administrator role!',
        NoStatisticsForTheAgent: 'No statistics data for the agent for the selected time period',
        FieldIsRequired: 'Field is required',
        ChannelMustContainAtLeastOneLanguage: 'Channel must contain at least one language',
    },
    Info: {
        Copied: 'copied',
        PhraseBotNameAndGreetingsChanged: 'Phrases, bot name and greeting changed',
    },
    Confirm: {},
    Message: {
        Welcome: 'Welcome to AskMe',
        SignIn: 'Log in to Admin Panel or go to your ',
        SignUp: 'Sign up to Admin Panel or go to your ',
        Register: 'Don’t have an account with us?',
        Login: 'Already have an account?',
        Logout: 'Do you really want to leave?',
        ForgotPassword: 'Forgot password?',
        ExcelFilesDownloading: 'Excel files downloading',
        excelFileIs: 'excel file',
        Created: ' is created',
        Creating: ' is creating',
    },
    Accordions: {
        MailInboxes: 'Mail inboxes',
        TelegramAccounts: 'Telegram Accounts',
        SmsAccounts: 'SMS Accounts',
    },
    Controls: {
        Newbie: 'Newbie',
        Name: 'Name',
        Email: 'Email',
        Password: 'Password',
        RepeatPassword: 'Repeat password',
        Register: 'Register',
        Login: 'Log in',
        Or: 'OR',
        SignUp: 'Sign up',
        Send: 'Send',
        SearchFilter: 'Search filters',
        Search: 'Search',
        SearchMessengers: 'Search messengers',
        SearchAgent: 'Search agent',
        SearchLanguage: 'Search language',
        SearchChannel: 'Search channel',
        SearchTag: 'Search tag',
        SearchStatus: 'Search status',
        SearchBrand: 'Search brand',
        SearchWhiteListTags: 'Search white list tags',
        SearchSessionStatus: 'Search session status',
        SearchAccessLevel: 'Search access level',
        SearchHasActiveDialogs: 'Search has active dialogs',
        GroupName: 'Group name*',
        Description: 'Description*',
        LinkAlias: 'Link alias*',
        CategoryName: 'Category name*',
        BrandName: 'Brand name',
        AccessKey: 'Access Key',
        ClientName: 'Client name',
        ClientFirstName: 'Client first name',
        ClientLastName: 'Client last name',
        ClientPhone: 'Client phone',
        ClientEmail: 'Client email',
        PleaseWrite: 'Please, write',
        MailInbox: 'Mail inbox',
        SmsInbox: 'SMS inbox',
        TelegramInbox: 'Telegram inbox',
        ApplicationID: 'Application ID',
        ApplicationToken: 'Application Token',
        SenderID: 'Sender ID',
        ChangeName: 'Change Name',
        ChangeURL: 'Change URL',
        TagName: 'Tag name',
        LinkUrl: 'Link url*',
        Greetings: 'Greetings',
        FastPhrase: 'Fast phrase №',
        ClickOnPaperclip: 'Click on the paperclip icon to select file',
        UserName: 'Login',
        Domain: 'Domain*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (host:port)*',
        SmtpSSL: 'Smtp SSL (host:port)*',
        AttachFile: 'Attach File',
        NoData: 'no data',
        NoRating: 'no rating',
        IfYouWantFinishDialog: 'If you want finish dialog click to OK button',
        AllDialogsAreVisibleToAllAgents: 'All dialogs are visible to all agents',
        DialogueIsAssignedAutomatically: 'Dialogue is assigned automatically to the agent who has the least number of dialogues',
        AllDialogsAreVisibleToSeveralAgents: 'Dialogs are visible to several agents with the least number of dialogues',
        On: 'On',
        Off: 'Off',
        FirstName: 'First name',
        InvoiceNumber: 'Invoice number',
        LastName: 'Last name',
        SMS: 'SMS',
        New: 'New',
        Active: 'Active',
        Finished: 'Finished',
        Assigned: 'Assigned',
        Online: 'Online',
        Offline: 'Offline',
        Break: 'Break',
        Low: 'Low',
        Medium: 'Medium',
        High: 'High',
        True: 'True',
        False: 'False',
        Date: 'Date',
        AgentName: 'Agent Name',
        Messenger: 'Messenger',
        Language: 'Language',
        Channel: 'Channel',
        Tag: 'Tag',
        Status: 'Status',
        Brand: 'Brand',
        AccessLevel: 'Access Level',
        WhiteListTags: 'White List Tags',
        SessionStatus: 'Session Status',
        LastTags: 'Last Tags',
        HasActiveDialogs: 'Has Active Dialogs',
        ChannelName: 'Channel Name',
        WriteYourFirstName: '* Please, write your first name',
        WriteYourLastName: '* Please, write your last name',
        WriteYourUserName: '* Please, write user name',
        WriteYourPhoneNumber: '* Please, write phone number',
        WriteYourEmail: '* Please, write your email',
        WriteYourInvoiceNumber: '* Please, write your invoice number',
        WriteYourAccountNumber: '* Please, write your account number',
        BotName: 'Bot name',
        Character: 'character',
        Number: 'number',
        IdExample: 'ID example',
        XCharecter: 'X-charecter',
        YNumber: 'Y-number',
        ThisOperationCanTakeLongTime: 'This operation can take a long time!',
        ClickTiSaveExcel: 'Click to save in Excel',
        PrivacyPolicyText: 'Privacy policy text',
        PrivacyPolicyLink: 'Privacy policy link',
        PrivacyPolicyLinkText: 'Privacy policy link text',
        AgentPhone: 'Agent phone',
        HeaderTextTop: 'Header text top',
        HeaderTextBottom: 'Header text bottom',
        LogoIconUrl: 'Logo icon URL',
        IconUrl: 'Icon URL',
        ChatWindowsLogo: 'Chat windows logo',
        GreetingIconBotUrl: 'Greeting icon bot Url',
        AgentMessageIconUrl: 'Agent Message icon URL',
        WriteMessagesPlaceholder: 'Write messages placeholder',
        SendImageUrl: 'Send image URL',
        ChannelURL: 'Channel URL',
    },
    Selects: {
        SelectAccessLevel: 'Select access level*',
        SelectChannel: 'Select channel*',
        SelectChannels: 'Select channels',
        SelectWhiteTagsList: 'Select white tags list*',
        SelectAgents: 'Select agents',
        SelectGroups: 'Select groups',
        SelectBrand: 'Select brand',
        SelectTags: 'Select tags',
        ChooseProviderCountry: 'Choose provider country',
        SelectMessengers: 'Select messengers',
        SelectTagStatus: 'Select tag status',
        SelectAgent: 'Select agent',
        SelectEnabledLanguages: 'Select enabled languages',
        ChannelName: 'Channel name',
        ChannelURL: 'Channel URL',
        SelectLanguageWebChat: 'Select languages Web Chat',
    },
    Labels: {
        NumberOfNewAgents: 'Number of new agents',
        TextForBulkMessaging: 'Text for bulk messaging',
        Name: 'Name',
        LastName: 'Last name',
        Phone: 'Phone',
        GroupName: 'Group name*',
        SelectBrand: 'Select brand',
        Channel: 'Channel*',
        Description: 'Description*',
        AccessLevel: 'Access level*',
        WhiteTagsList: 'White tags list*',
        BrandName: 'Brand name',
        CategoryName: 'Category name*',
        ChooseProviderCountry: 'Choose provider country',
        AccessKey: 'Access Key',
        SelectChannels: 'Select channels',
        SelectGroups: 'Select groups',
        SelectAgents: 'Select agents',
        SelectTags: 'Select tags',
        SelectAccessLevel: 'Select access level*',
        Password: 'Password*',
        Login: 'Log in',
        SelectMessengers: 'Select messengers',
        UserName: 'User name',
        ClientName: 'Client name',
        ClientFirstName: 'Client first name',
        ClientLastName: 'Client last name',
        ClientPhone: 'Client phone',
        ClientEmail: 'Client email',
        ClientInfo: 'Client info',
        UpdateGroup: 'Update Group',
        Agents: 'Agents',
        UsefulPhrases: 'Useful phrases',
        Channels: 'Channels',
        Tags: 'Tags',
        AddNewPhrases: 'Add new phrases',
        Phrase: 'Phrase',
        SelectCategory: 'Select category',
        EditCategory: 'Edit category',
        EditPhrase: 'Edit phrase',
        Or: 'or',
        AddNewCategory: 'Add new category',
        Dialogs: 'Dialogs',
        TotalDuration: 'Total Duration',
        Email: 'Email',
        ClientId: 'Client Id',
        ClientBanType: 'Client ban type',
        ClientBanTime: 'Client ban time',
        Messengers: 'Messengers',
        IpAddress: 'IP address',
        ExternalID: 'External ID',
        UpdateBrand: 'Update brand',
        CreateMailInbox: 'Create mail inbox account',
        CreateTelegramInbox: 'Create Telegram account',
        CreateSmsInbox: 'Create SMS account',
        MailInbox: 'Mail inbox',
        TelegramInbox: 'Telegram inbox',
        SmsInbox: 'SMS inbox',
        ApplicationID: 'Application ID',
        ApplicationToken: 'Application Token',
        SenderID: 'Sender ID',
        ChangeName: 'Change Name',
        ChangeURL: 'Change URL',
        TagName: 'Tag name',
        SelectTagStatus: 'Select tag status',
        SelectAgent: 'Select agent',
        UpdateTag: 'Update Tag',
        CreateTag: 'Create Tag',
        UsefulLinks: 'Useful Links',
        LinkAlias: 'Link alias',
        LinkUrl: 'Link url',
        Roles: 'Roles',
        Groups: 'Groups',
        CreateGroups: 'Create Groups',
        TeamLead: 'Team lead',
        Ban: 'Ban',
        SelectEnabledLanguages: 'Select enabled languages',
        Greetings: 'Greetings',
        FastPhrase: 'Fast phrase №',
        ClientHistory: 'Client history',
        ChooseRequiredFields: 'Please choose required fields',
        GetAllDialogs: 'Get all dialogs',
        GetCurrentDialog: 'Get current dialog',
        SaveInLocalStorage: 'Save in LocalStorage',
        SaveInSessionStorage: 'Save in SessionStorage',
        InvoiceNumber: 'Invoice number',
        AccountNumber: 'Account number',
        General: 'General',
        Authorization: 'Authorization',
        Languages: 'Languages',
        New: 'New',
        Active: 'Active',
        Inactive: 'Inactive',
        WaitForActivation: 'Wait for activation',
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Low',
        Medium: 'Medium',
        High: 'High',
        Hidden: 'Hidden',
        Brand: 'Brand',
        Group: 'Group',
        ChangeStatus: 'Change status',
        AuthMail: 'Auth Mail',
        SetDomainSettings: 'Set Domain Settings',
        Domain: 'Domain*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (host:port)*',
        SmtpSSL: 'Smtp SSL (host:port)*',
        AttachFile: 'Attach File',
        ChannelSettings: 'Channel Settings',
        GeneralSettings: 'General Settings',
        WebChatConstructor: 'Web Chat Constructor',
        BackgroundColor: 'Background color',
        MessageColor: 'Message color',
        URL: 'URL',
        ChannelName: 'Channel name',
        ChannelURL: 'Channel URL',
        AllowSMS: 'Allow SMS',
        AllowShowAllClientDialogs: 'Allow show all client dialogs',
        AllowCaptcha: 'Allow Captcha',
        InboxSettings: 'Inbox Settings',
        DistributionOfNewDialogs: 'Distribution of new dialogs between online agents',
        ThereAreNoAvailableMessengers: 'There are no available messengers for the channel! Please create them for the brand that owns this channel!',
        HeaderColorScheme: 'Header color scheme',
        GreetingColorScheme: 'Greeting color scheme',
        QuickPhrases: 'Quick Phrases color scheme',
        ClientMessage: 'Client message color scheme',
        AgentMessage: 'Agent message color scheme',
        EndDialogModal: 'End dialog modal color scheme',
        IconColorScheme: 'Icon color scheme',
        ScrollColorScheme: 'Scroll color scheme',
        ChatWindowColor: 'Chat window color scheme',
        FooterColorScheme: 'Footer color scheme',
        AuthColorScheme: 'Auth color scheme',
        HeaderBackground: 'Header Background',
        HeaderBorderColor: 'Header Border color',
        ColorGreetingBotName: 'Greeting Bot name color',
        MessageGreetingIcon: 'Greeting Icon background',
        MessageGreetingColor: 'Greeting Text color',
        MessageGreetingBorderColor: 'Greeting message Border color',
        MessageGreetingBackgroundColor: 'Greeting message Background color',
        QuickPhrasesColor: 'Quick phrase Text color',
        QuickPhrasesBackground: 'Quick phrase Background',
        QuickPhrasesBorderColor: 'Quick phrase Border color',
        QuickPhrasesColorHover: 'Quick phrase Hover color',
        QuickPhrasesBackgroundHover: 'Quick phrase Background hover',
        QuickPhrasesBorderColorHover: 'Quick phrase Border color hover',
        ClientMessageColor: 'Client message Text color',
        ClientTimeColor: 'Client Time color',
        ClientMessageBackground: 'Client message Background color',
        ClientMessageBorderColor: 'Client message Border color',
        ClientLink: 'Client Link',
        ClientLinkHover: 'Client link hover',
        ClientActiveLink: 'Client Active link',
        ClientVisitedLink: 'Client Visited link',
        ColorAgentNameAndTime: 'Agent name and time color',
        MessageAgentColor: 'Agent message color',
        MessageAgentBackgroundColor: 'Agent message Background',
        MessageAgentBorderColor: 'Agent message Border color',
        AgentLink: 'Agent link',
        AgentHoverLink: 'Agent Hover link',
        AgentActiveLink: 'Agent Active link',
        AgentVisitedLink: 'Agent Visited link',
        MessageIconBackgroundAgent: 'Agent message icon background color',
        TextColor: 'Text Color',
        BackdropColor: 'Backdrop color',
        BackdropColorHover: 'Backdrop color hover',
        ButtonColor: 'Button color',
        BackdropColorBtn: 'Backdrop Button background color',
        IconBackground: 'Icon background',
        ChatWindowsBodyBackground: 'Chat windows body background color',
        ChatWindowsBorderColor: 'Chat windows border color',
        FooterBackground: 'Footer background color',
        FooterBorderColor: 'Footer border color',
        FooterBackgroundTextArea: 'Footer text area background color',
        InputBorderColor: 'Input border color',
        RegistrationBackgroundColor: 'Registration background color',
        LabelColor: 'Label color',
        LabelErrorColor: 'Label error color',
        InputColor: 'Input color',
        InputBackground: 'Input background',
        SignUpColor: 'Sign up color',
        SignUpBackground: 'Sign up background color',
        Chat: 'Chat',
        Minimized: 'Minimized',
        ScrollBackground: 'Scroll background',
        ScrollThumbBackground: 'Scroll thumb background',
        UniqueClientIdentifier: 'Unique client identifier',
        AgentsSettings: 'Agents settings',
        ThisAgentSuperAdmin: 'This agent is super admin, click the button to remove super admin rights',
        ThisAgentNotSuperAdmin: 'This agent is not a Super admin, click on the button to make him super admin',
        YouCanAssignBrandTheBrand: 'You can assign the brand of the admin for this you need to select the brand',
        Role: 'Role',
        BanningThisAgent: 'Banning this agent means they will not be able to log in to the platform. They are still going to appear in the system.',
        Report: 'Report',
        SelectLanguageWebChat: 'Select languages Web Chat',
        FirstName: 'First name',
        PhoneNumber: 'Phone number',
        Info: 'Info',
        WebChatSettings: 'Web Chat Settings',
        IntegrationWithOtherCRM: 'Integration with other CRM',
        SettingUpInteractionBetweenSystems: 'Setting up interaction between systems',
        QuickReplies: 'Quick replies',
        BotSettings: 'Bot settings',
        BotName: 'Bot name',
        EnabledPrivacyPolicy: 'Enabled Privacy Policy',
        PleaseChooseRequiredFields: 'Please choose required fields',
        ChangeGreetingsAndPhrases: 'Change Greetings and Phrase, Privacy Policy',
        ChangeSmsSettings: 'Change sms settings',
        ChangeCountries: 'Change countries',
        ContactsAreWorkingWithClientUniqueness: 'Contacts are working with client uniqueness.Field is required, if client uniqueness is equal to it.',
        ClientUniquenessIs: 'Client uniqueness is:',
        PrivacyPolicyText: 'Privacy policy text',
        PrivacyPolicyLink: 'Privacy policy link',
        PrivacyPolicyLinkText: 'Privacy policy link text',
        AgentPhone: 'Agent phone',
        HeaderSettings: 'Header settings',
        DisableDraggable: 'Disable draggable',
        DisableHeaderFullScreen: 'Disable header full screen',
        DisableHeaderHideChatWindow: 'Disable hide header chat window',
        DisableHeaderLogo: 'Disable Header Logo',
        DisableHeaderShowEndDialogModal: 'Disable header show end dialog modal',
        HeaderText: 'Header text',
        HeaderTextTop: 'Header text top',
        HeaderTextBottom: 'Header text bottom',
        HeaderHeight: 'Header height',
        LogoIconUrl: 'Logo icon URL',
        IconUrl: 'Icon URL',
        IconSettings: 'Icon Settings',
        ScrollSettings: 'Scroll Settings',
        ScrollWidth: 'Scroll width',
        ChatWindowsLogo: 'Chat windows logo',
        BackgroundImageSize: 'Background image size',
        GreetingSettings: 'Greeting Settings',
        GreetingIconBotUrl: 'Greeting icon bot URL',
        QuickPhrasesSettings: 'Quick Phrases Settings',
        ClientMessageSettings: 'Client Message Settings',
        AgentMessageSettings: 'Agent Message Settings',
        AgentMessageIconUrl: 'Agent Message icon URL',
        EndDialogModalSettings: 'End dialog modal Settings',
        AuthWindowSettings: 'Auth window Settings',
        FooterSettings: 'Footer Settings',
        WriteMessagesPlaceholder: 'Write messages placeholder',
        SendImageUrl: 'Send image URL',
        SendButtonIconHeight: 'Send button icon height',
        SendButtonIconWidth: 'Send button icon width',
        ChatWindowSettings: 'Chat window Settings',
        ClearAllPhrase: 'Clear all phrase',
        AreYouSure: 'Are you sure?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'This action will delete all phrases and all categories',
        AddAgentToGroup: 'Add agents to group',
        CreateClient: 'Create Client',
        ClientsImport: 'Clients Import',
        UploadExcelFile: 'Upload Excel file',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Click on the paperclip icon to select the Excel file',
        Contacts: 'Contacts',
        Personal: 'Personal',
        FileUploadHint: 'Please, upload an excel file filled with phone numbers. Excel file should contain a column named “Phones” and phone numbers should be entered in this column. Only .xls or .xlsx files can be imported.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Change Greetings and Phrase, Privacy Policy',
    },
    AdditionalText: {
        AgentProfile: 'Agent Profile',
        OutOf: 'out of',
    },
    Pages: {
        Dialogs: {
            Title: 'Dialogs',
            TableColumns: {
                Status: 'Status',
                Dialog: 'Dialog',
                Messenger: 'Messenger',
                Channel: 'Channel',
                Tag: 'Tag',
                Agent: 'Agent',
                Rating: 'Rating',
                Language: 'Language',
                MessagesCount: 'Messages count',
                FirstMessage: 'First message',
                LastActivity: 'Last Activity',
            },
            Messages: 'Messages',
        },
        AgentGroups: {
            Title: 'Agent Groups',
            TableColumns: {
                Groups: 'Groups',
                Description: 'Description',
                AccessLevel: 'Access level',
                WhiteListTags: 'White list tags',
                CreatedAt: 'Created at',
                UpdatedAt: 'Updated at',
            },
        },
        Agents: {
            Title: 'Agents',
            TableColumns: {
                AgentStatus: 'Agent Status',
                Agents: 'Agents',
                Email: 'Email',
                Groups: 'Groups',
                CreatedAt: 'Created at',
                LastActivity: 'Last Activity',
            },
        },
        Tags: {
            Title: 'Tags',
            TableColumns: {
                Tags: 'Tags',
                Dialogs: 'Dialogs',
                Agents: 'Agents',
                UsefulLinks: 'Useful Links',
                Status: 'Status',
                CreatedAt: 'Created at',
                UpdatedAt: 'Updated at',
            },
        },
        Clients: {
            Title: 'Clients',
            TableColumns: {
                SearchName: 'name',
                AgentName: 'Agent name',
                Messenger: 'Messenger',
                LastTag: 'Last tag',
                ActiveDialog: 'Has Active Dialog',
                Brand: 'Brand',
                InternalId: 'Internal Id',
                ExternalId: 'External Id',
                Name: 'Client name',
                FirstName: 'First name',
                LastName: 'Last name',
                Email: 'Email',
                InvoiceNumber: 'Invoice Number',
                DialogsCount: 'Dialogs Count',
                FirstMessage: 'First message',
                LastActivity: 'Last Activity',
                Tag: 'Tag',
                Status: 'Status',
                Channel: 'Channel',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Channels',
            TableColumns: {
                ChannelId: 'Channel id',
                ChannelKey: 'Channel key',
                ChannelName: 'Channel name',
                Language: 'Language',
                Url: 'Url',
                CreatedAt: 'Created at',
                UpdatedAt: 'Updated at',
            },
        },
        Brands: {
            Title: 'Brands',
            TableColumns: {
                BrandsId: 'Brand id',
                BrandName: 'Brand name',
                ClientUniqueness: 'Client uniqueness',
                BrandChannels: 'Brand channels',
                DialogCount: 'Dialogues count',
                CreatedAt: 'Created at',
                UpdatedAt: 'Updated at',
            },
        },
        Reports: {
            Title: 'Reports',
            ByMessengersTab: {
                Title: 'Report by messengers',
                Columns: {
                    Messenger: 'Messenger',
                    ReceivedDialogs: 'Received dialogs',
                    PositiveDialogs: 'Positive dialogs, %',
                    NegativeDialogs: 'Negative dialogs, %',
                    UnratedDialogs: 'Unrated dialogs, %',
                    AvgResponseTime: 'Avg Response Time',
                },
            },
            ByAgentsTab: {
                Title: 'Report by agents',
                Columns: {
                    agentFullName: 'Agent full name',
                    agentSystemId: ' Agent system id',
                    brandName: 'Brand name',
                    receivedDialogs: 'Received dialogs',
                    dialogsTelegram: 'Dialogs Telegram',
                    dialogsWebChat: 'Dialogs Web Chat',
                    dialogsViber: 'Dialogs Viber',
                    dialogsWhatsApp: 'Dialogs WhatsApp',
                    dialogsFacebook: 'Dialogs Facebook',
                    dialogsEmail: 'Dialogs Email',
                    dialogsSms: 'Dialogs SMS',
                    positiveDialogs: 'Positive dialogs, %',
                    negativeDialogs: 'Negative dialogs, %',
                    unratedDialogs: 'Unrated dialogs, %',
                    avgResponseTime: 'Avg Response Time',
                },
                noDataForThisDay: 'No data for this day',
            },
            ByGroupsTab: {
                Title: 'Report by groups',
                Columns: {
                    groupSystemId: 'Group system id',
                    groupName: 'Group name',
                    brandName: 'Brand name',
                    receivedDialogs: 'Received dialogs',
                    positiveDialogs: 'Positive dialogs, %',
                    negativeDialogs: 'Negative dialogs, %',
                    unratedDialogs: 'Unrated dialogs, %',
                    avgResponseTime: 'Avg Response Time',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Add Filter',
        reset: 'Reset',
        createClient: 'Create Client',
        importClient: 'Import Clients',
        createChannel: 'Create channel',
        previous: 'Previous',
        next: 'Next',
        addPhrase: 'Add Phrase',
        addGroup: 'Add Group',
        createBrand: 'Create brand',
        addTag: 'Add Tag',
        save: 'Save',
        send: 'Send',
        getStatistics: 'Get Statistics',
        setCountries: 'Set countries',
        changeStatus: 'Change status',
        upload: 'Upload',
        setDomainSettings: 'Set domain Settings',
        login: 'Login',
        update: 'Update',
        resetPassword: 'Reset Password',
        addNew: 'Add New',
        addNewPhrase: 'Add new phrase',
        addNewCategory: 'Add new category',
        clearAll: 'Clear All',
        loginWithGoogle: 'Login with Google',
        loginWithFacebook: 'Login with Facebook',
        onPage: 'on page',
        addNewUsefulLinks: 'Add new useful links',
        backToChannels: 'Back to Channels',
        defaultSettings: 'Default settings',
        upgradeChannel: 'Upgrade channel',
        apply: 'Apply',
        remove: 'Remove',
        removeSuperAdmin: 'Remove super admin',
        makeSuperAdmin: 'Make super admin',
        makeBrandAdmin: 'Make Brand admin',
        removeBrandAdmin: 'Remove Brand admin',
        ban: 'Ban',
        unban: 'Unban',
        makeTeamLead: 'Make Team Lead',
        removeTeamLead: 'Remove Team Lead',
        signUp: 'Sign up',
        ok: 'Ok',
        cancel: 'Cancel',
        bulkMessaging: 'Bulk Messaging',
        back: 'Back',
    },
    Countries: {
        UnitedStates: 'United States',
        Canada: 'Canada',
        Australia: 'Australia',
        CzechRepublic: 'Czech Republic',
        Denmark: 'Denmark',
        Hungary: 'Hungary',
        Netherlands: 'Netherlands',
        Sweden: 'Sweden',
        UnitedKingdom: 'United Kingdom',
        France: 'France',
        Germany: 'Germany',
        Poland: 'Poland',
        Italy: 'Italy',
        Spain: 'Spain',
    },
    Languages: {
        Ukrainian: 'Ukrainian',
        Russian: 'Russian',
        English: 'English',
        Polish: 'Polish',
        Deutsch: 'Deutsch',
        Spanish: 'Spanish',
        Vietnamese: 'Vietnamese',
        Portuguese: 'Portuguese',
        Korean: 'Korean',
        Italian: 'Italian',
        Arabic: 'Arabic',
        French: 'French',
        Azerbaijan: 'Azerbaijan',
        Hungarian: 'Hungarian',
    },
    BulkMessaging: {
        exclude: 'Exclude',
        typeText: 'Type text',
        selectClients: 'Select clients',
        uploadClients: 'Upload clients',
        sendingMessagesToNewClients: 'Sending messages to new clients',
        sendingMessagesToExistingClients: 'Sending messages to existing clients',
        clientsWhoHaveDialogsWithFollowingStatus: 'Clients, who have dialogs with following status',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Clients, who have this text in name or last name or user name',
        limitQuantity: 'Limit quantity',
        quantityLimit: 'Quantity limit',
        limitQuantityText: 'Limit the quantity in the broadcast to no more than the specified number of recipients. Newer clients of the system will get into the list.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Clients, who wrote to AskMe before last message time',
    },
};
export default Messages;
