import React from 'react';
import StyledAgentMessage from './StyledAgentMessage';
var AgentMessage = function (_a) {
    var agentMessage = _a.agentMessage;
    var colorAgentNameAndTime = agentMessage.colorAgentNameAndTime, messageAgentColor = agentMessage.messageAgentColor, messageAgentBackgroundColor = agentMessage.messageAgentBackgroundColor, messageAgentBorderColor = agentMessage.messageAgentBorderColor, agentLink = agentMessage.agentLink, agentHoverLink = agentMessage.agentHoverLink, agentActiveLink = agentMessage.agentActiveLink, agentVisitedLink = agentMessage.agentVisitedLink, messageIconUrlAgent = agentMessage.messageIconUrlAgent, messageIconBackgroundAgent = agentMessage.messageIconBackgroundAgent;
    return (React.createElement(StyledAgentMessage, { colorAgentNameAndTime: colorAgentNameAndTime, messageAgentColor: messageAgentColor, messageAgentBackgroundColor: messageAgentBackgroundColor, messageAgentBorderColor: messageAgentBorderColor, agentLink: agentLink, agentHoverLink: agentHoverLink, agentActiveLink: agentActiveLink, agentVisitedLink: agentVisitedLink, messageIconBackgroundAgent: messageIconBackgroundAgent },
        React.createElement("div", { className: "agent-message-wrap" },
            React.createElement("div", { className: "agent-icon" },
                React.createElement("img", { src: messageIconUrlAgent, alt: "" })),
            React.createElement("div", { className: "agent-greeting-wrap" },
                React.createElement("p", { className: "agent-greeting" }, "14:08 Agent Name"),
                React.createElement("div", { className: "agent-message" }, "Agent message Agent message Agent message Agent message Agent message"))),
        React.createElement("div", { className: "agent-message-wrap" },
            React.createElement("div", { className: "agent-icon" },
                React.createElement("img", { src: messageIconUrlAgent, alt: "" })),
            React.createElement("div", { className: "agent-greeting-wrap" },
                React.createElement("p", { className: "agent-greeting" }, "14:08 Agent Name"),
                React.createElement("div", { className: "agent-message" }, "Agent message message Agent message message Agent message link:")))));
};
export default AgentMessage;
