import React from 'react';
var PrivacyPolicy = function (_a) {
    var privacyPolicy = _a.privacyPolicy, isPrivacyPolicy = _a.isPrivacyPolicy, lang = _a.lang, setIsPrivacyPolicy = _a.setIsPrivacyPolicy;
    return (React.createElement("div", { className: "privacy-policy-wrap" },
        React.createElement("input", { type: "checkbox", checked: isPrivacyPolicy, onChange: function () { return setIsPrivacyPolicy(!isPrivacyPolicy); }, style: {
                backgroundColor: 'initial',
                cursor: 'default',
                appearance: 'auto',
                boxSizing: 'border-box',
                margin: '0 3px 0 4px',
                padding: 'initial',
                border: 'initial',
                height: '15px',
                width: '15px',
            } }),
        React.createElement("div", { className: "privacy-policy-wrap__text" },
            privacyPolicy.data[lang].text,
            React.createElement("a", { href: privacyPolicy.data[lang].link, target: "_blank", rel: "noreferrer" }, " ".concat(privacyPolicy.data[lang].linkText)))));
};
export default PrivacyPolicy;
