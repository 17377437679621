var Messages = {
    Success: {
        Success: 'النجاح',
        ForgotPasswordSend: 'تم إرسال رابط تغيير كلمة المرور إلى بريدك الإلكتروني',
        ResetPasswordSuccess: 'لقد تم تحديث كلمة السر الخاصة بك بنجاح',
        BrandCreated: 'تم إنشاء العلامة التجارية!',
        MailInboxCreated: 'تم إنشاء صندوق البريد الوارد!',
        PrivacyPolicyChanged: 'تم تغيير سياسة الخصوصية!',
        SmsInboxCreated: 'تم إنشاء صندوق بريد الرسائل القصيرة!',
        InboxDeleted: 'تم حذف البريد الوارد!',
        StatusChanged: 'تغير الوضع!',
        FileLoaded: 'تم تحميل الملف!',
        AuthMail: 'بريد المصادقة!',
        DomainSettings: 'إعدادات المجال!',
        UpdatedSmsAccounts: 'حسابات الرسائل القصيرة المحدثة!',
        ChanelCreated: 'تم إنشاء القناة!',
        DeleteMessenger: 'حذف Messenger!',
        AddMessenger: 'أضف Messenger!',
        ChangedMessenger: 'تغيير رسول!',
        ClientCreated: 'تم إنشاء العميل!',
        ClientsImports: 'يستورد العملاء!',
        GroupCreated: 'تم إنشاء المجموعة!',
        ChangedAssignedAgentsInGroups: 'تم تغيير الوكلاء المعينين في مجموعات!',
        ChangedAssignedChannelsInGroups: 'تغيير القنوات المعينة في مجموعات!',
        ChangedAssignedTagsInGroups: 'تم تغيير العلامات المعينة في المجموعات!',
        CreateNewCategory: 'إنشاء فئة جديدة!',
        CreateNewPhrase: 'إنشاء عبارة جديدة!',
        DeleteCategory: 'حذف الفئة!',
        DeletePhrase: 'حذف العبارة!',
        UpdatePhrase: 'تحديث العبارة!',
        EditCategory: 'تحرير الفئة!',
        DeleteAllCategory: 'حذف كل الفئات!',
        TagCreated: 'تم إنشاء العلامة!',
        TagUpdated: 'تم تحديث العلامة!',
        TelegramInboxCreated: 'تم إنشاء صندوق البريد Telegram!',
        HeaderTextChanged: 'تم تغيير نص العنوان',
        LogoIconUrlChanged: 'تم تغيير عنوان url لرمز الشعار',
        IconUrlChanged: 'تم تغيير عنوان url للرمز',
        BackgroundImageUrlChanged: 'تم تغيير عنوان url لصورة الخلفية',
        GreetingImageUrlChanged: 'تم تغيير عنوان url لصورة الترحيب',
        AgentImageUrlChanged: 'تم تغيير عنوان url لصورة الوكيل',
        InputPlaceholderChanged: 'تم تغيير العنصر النائب للإدخال',
        SendImageUrlChanged: 'تم تغيير عنوان url لإرسال الصورة',
        AgentStatusChanged: 'تغيرت حالة الوكيل',
        SelectedAgentTagsChanged: 'تم تغيير علامات الوكيل المختارة',
        AssignedAgentsGroupsChanged: 'تم تغيير مجموعات الوكلاء المعينين',
        TeamLeadChanged: 'قائد الفريق المتغير',
        AgentRoleChanged: 'تم تغيير دور الوكيل',
        AgentInfoChanged: 'تم تغيير معلومات الوكيل',
    },
    Errors: {
        PasswordMatching: 'كلمة المرور غير مطابقة!',
        IncorrectEmail: 'البريد الإلكتروني غير صحيح',
        IncorrectPhone: 'الهاتف غير صحيح',
        IncorrectUrl: 'عنوان URL غير صحيح',
        FieldContainSpaces: 'الحقل يحتوي على مسافات',
        FieldIsRequired: 'الحقل مطلوب',
        NameValidation: 'يجب أن يتراوح طول الحقل من 2 إلى 40 حرفًا',
        NumberValidation: 'يجب أن يحتوي الحقل على أرقام فقط',
        PasswordValidation: 'يجب أن تحتوي كلمة المرور على أحرف أبجدية وأرقام ولا يمكن أن تحتوي على مسافات',
        InvoiceNumberValidation: 'يجب أن يحتوي رقم الفاتورة على أحرف وأرقام وأحرف خاصة لاتينية',
        MinimumLength: 'الحد الأدنى للطول {0} رموز',
        MaximumLength: 'أقصى طول للرموز {0}',
        ServerError: 'خطأ في الخادم ، يرجى المحاولة لاحقًا!',
        ServerErrorFileDownloadTimeOut: 'خطأ في الخادم ، انتهت مهلة تنزيل الملف ، يرجى المحاولة لاحقًا!',
        NotValidEmailOrPassword: 'البريد الإلكتروني أو كلمة المرور غير صالحة',
        UserWasNotFound: 'لم يتم العثور على المستخدم!',
        UserIsAlreadyExist: 'المستخدم موجود بالفعل!',
        AgentWasBanned: 'تم حظر الوكيل',
        TokenExpired: 'انتهت الجلسة. الرجاء إعادة تسجيل الدخول',
        TelegramIdIsIncorrect: 'معرف Telegram غير صحيح',
        ErrorWrongFileFormat: 'خطأ ، تنسيق ملف خاطئ!',
        FileSizeExceededWarnings: 'تجاوز حجم الملف التحذيرات!',
        BrandWithSuchNameIsAlreadyExisting: 'العلامة التجارية بهذا الاسم موجودة بالفعل!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'لقد قمت بالفعل بتحديد مرشح',
        AgentCreate: 'أنت وكيل جديد في نظام AskMe. يرجى ملاحظة أنه لا يمكنك تسجيل الدخول إلا بعد أن يتم تعيين دور المسؤول لك!',
        NoStatisticsForTheAgent: 'لا توجد بيانات إحصائية للوكيل للفترة الزمنية المحددة',
        FieldIsRequired: 'الحقل مطلوب',
        ChannelMustContainAtLeastOneLanguage: 'يجب أن تحتوي القناة على لغة واحدة على الأقل',
    },
    Info: {
        Copied: 'نسخ',
        PhraseBotNameAndGreetingsChanged: 'تم تغيير العبارات واسم الروبوت والتحية',
    },
    Confirm: {},
    Message: {
        Welcome: 'مرحبا بكم في AskMe',
        SignIn: 'قم بتسجيل الدخول إلى لوحة المسؤول أو انتقل إلى ',
        SignUp: 'قم بالتسجيل في لوحة الإدارة أو انتقل إلى ',
        Register: 'ليس لديك حساب معنا؟',
        Login: 'هل لديك حساب؟',
        Logout: 'هل تريد حقا المغادرة؟',
        ForgotPassword: 'هل نسيت كلمة السر؟',
        ExcelFilesDownloading: 'تنزيل ملفات Excel',
        excelFileIs: 'ملف اكسل',
        Created: 'أنشئ',
        Creating: 'يخلق',
    },
    Accordions: {
        MailInboxes: 'صناديق البريد الوارد',
        TelegramAccounts: 'حسابات Telegram',
        SmsAccounts: 'حسابات الرسائل القصيرة',
    },
    Controls: {
        Newbie: 'مبتدئ',
        Name: 'اسم',
        Email: 'بريد الالكتروني',
        Password: 'كلمة المرور',
        RepeatPassword: 'اعد كلمة السر',
        Register: 'يسجل',
        Login: 'يسجل دخول',
        Or: 'أو',
        SignUp: 'اشتراك',
        Send: 'إرسال',
        SearchFilter: 'مرشحات البحث',
        Search: 'بحث',
        SearchMessengers: 'البحث عن الرسل',
        SearchAgent: 'وكيل البحث',
        SearchLanguage: 'لغة البحث',
        SearchChannel: 'قناة البحث',
        SearchTag: 'علامة البحث',
        SearchStatus: 'حالة البحث',
        SearchBrand: 'البحث عن العلامة التجارية',
        SearchWhiteListTags: 'البحث في علامات القائمة البيضاء',
        SearchSessionStatus: 'البحث عن حالة الجلسة',
        SearchAccessLevel: 'مستوى الوصول إلى البحث',
        SearchHasActiveDialogs: 'البحث لديه حوارات نشطة',
        GroupName: 'أسم المجموعة*',
        Description: 'وصف*',
        LinkAlias: 'ارتباط الاسم المستعار*',
        CategoryName: 'اسم التصنيف*',
        BrandName: 'اسم العلامة التجارية',
        AccessKey: 'مفتاح الوصول',
        ClientName: 'اسم العميل',
        ClientFirstName: 'الاسم الأول للعميل',
        ClientLastName: 'الاسم الأخير للعميل',
        ClientPhone: 'هاتف العميل',
        ClientEmail: 'البريد الإلكتروني للعميل',
        PleaseWrite: 'اكتب رجاءا',
        MailInbox: 'صندوق البريد الوارد',
        SmsInbox: 'صندوق الوارد للرسائل القصيرة',
        TelegramInbox: 'صندوق بريد Telegram',
        ApplicationID: 'رقم الاستمارة',
        ApplicationToken: 'رمز التطبيق',
        SenderID: 'هوية المرسل',
        ChangeName: 'تغيير الإسم',
        ChangeURL: 'تغيير URL',
        TagName: 'اسم العلامة',
        LinkUrl: 'URL رابط*',
        Greetings: 'تحيات',
        FastPhrase: 'عبارة سريعة №',
        ClickOnPaperclip: 'انقر فوق رمز مشبك الورق لتحديد الملف',
        UserName: 'تسجيل الدخول',
        Domain: 'اختصاص*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (host:port)*',
        SmtpSSL: 'Smtp SSL (host:port)*',
        AttachFile: 'أرفق ملف',
        NoData: 'لايوجد بيانات',
        NoRating: 'لا يوجد تقييم',
        IfYouWantFinishDialog: 'إذا كنت تريد إنهاء الحوار انقر فوق الزر "موافق"',
        AllDialogsAreVisibleToAllAgents: 'جميع الحوارات مرئية لجميع الوكلاء',
        DialogueIsAssignedAutomatically: 'يتم تخصيص الحوار تلقائيًا للوكيل الذي لديه أقل عدد من الحوارات',
        AllDialogsAreVisibleToSeveralAgents: 'تكون الحوارات مرئية للعديد من الوكلاء بأقل عدد من الحوارات',
        On: 'على',
        Off: 'عن',
        FirstName: 'الاسم الأول',
        InvoiceNumber: 'رقم الفاتورة',
        LastName: 'الكنية',
        SMS: 'رسالة قصيرة',
        New: 'جديد',
        Active: 'نشيط',
        Finished: 'تم الانتهاء من',
        Assigned: 'تعيين',
        Online: 'متصل',
        Offline: 'غير متصل على الانترنت',
        Break: 'استراحة',
        Low: 'قليل',
        Medium: 'متوسط',
        High: 'متوسط',
        True: 'صحيح',
        False: 'خطأ شنيع',
        Date: 'تاريخ',
        AgentName: 'اسم العميل',
        Messenger: 'رسول',
        Language: 'لغة',
        Channel: 'قناة',
        Tag: 'بطاقة شعار',
        Status: 'حالة',
        Brand: 'ماركة',
        AccessLevel: 'مستوى الوصول',
        WhiteListTags: 'علامات القائمة البيضاء',
        SessionStatus: 'حالة الجلسة',
        LastTags: 'آخر العلامات',
        HasActiveDialogs: 'به حوارات نشطة',
        ChannelName: 'اسم القناة',
        WriteYourFirstName: '* من فضلك اكتب اسمك الأول',
        WriteYourLastName: '* من فضلك اكتب اسمك الأخير',
        WriteYourUserName: '* من فضلك اكتب اسم المستخدم',
        WriteYourPhoneNumber: '* من فضلك اكتب رقم الهاتف',
        WriteYourEmail: '* من فضلك اكتب بريدك الإلكتروني',
        WriteYourInvoiceNumber: '* من فضلك اكتب رقم الفاتورة الخاصة بك',
        WriteYourAccountNumber: '* من فضلك اكتب رقم حسابك',
        BotName: 'اسم البوت',
        Character: 'حرف',
        Number: 'رقم',
        IdExample: 'مثال معرف',
        XCharecter: 'X-charecter',
        YNumber: 'رقم ص',
        ThisOperationCanTakeLongTime: 'يمكن أن تستغرق هذه العملية وقتًا طويلاً!',
        ClickTiSaveExcel: 'انقر للحفظ في Excel',
        PrivacyPolicyText: 'نص سياسة الخصوصية',
        PrivacyPolicyLink: 'رابط سياسة الخصوصية',
        PrivacyPolicyLinkText: 'نص ارتباط سياسة الخصوصية',
        AgentPhone: 'هاتف الوكيل',
        HeaderTextTop: 'رأس نص أعلى',
        HeaderTextBottom: 'نص العنوان السفلي',
        LogoIconUrl: 'عنوان URL لرمز الشعار',
        IconUrl: 'عنوان URL للرمز',
        ChatWindowsLogo: 'شعار نوافذ الدردشة',
        GreetingIconBotUrl: 'رمز الترحيب bot Url',
        AgentMessageIconUrl: 'عنوان URL لرمز رسالة الوكيل',
        WriteMessagesPlaceholder: 'كتابة العنصر النائب للرسائل',
        SendImageUrl: 'إرسال عنوان URL للصورة',
        ChannelURL: 'قناة URL',
    },
    Selects: {
        SelectAccessLevel: 'حدد مستوى الوصول *',
        SelectChannel: 'حدد القناة *',
        SelectChannels: 'حدد القنوات',
        SelectWhiteTagsList: 'حدد قائمة العلامات البيضاء *',
        SelectAgents: 'حدد وكلاء',
        SelectGroups: 'حدد المجموعات',
        SelectBrand: 'اختر الماركة',
        SelectTags: 'حدد العلامات',
        ChooseProviderCountry: 'اختر بلد المزود',
        SelectMessengers: 'حدد الرسل',
        SelectTagStatus: 'حدد حالة العلامة',
        SelectAgent: 'حدد الوكيل',
        SelectEnabledLanguages: 'حدد اللغات الممكنة',
        ChannelName: 'اسم القناة',
        ChannelURL: 'قناة URL',
        SelectLanguageWebChat: 'حدد اللغات Web Chat',
    },
    Labels: {
        NumberOfNewAgents: 'عدد الوكلاء الجدد',
        TextForBulkMessaging: 'نص للرسائل الجماعية',
        Name: 'اسم',
        LastName: 'الكنية',
        Phone: 'هاتف',
        GroupName: 'أسم المجموعة*',
        SelectBrand: 'اختر الماركة',
        Channel: 'قناة*',
        Description: 'وصف*',
        AccessLevel: 'مستوى الوصول *',
        WhiteTagsList: 'قائمة العلامات البيضاء *',
        BrandName: 'اسم العلامة التجارية',
        CategoryName: 'اسم التصنيف*',
        ChooseProviderCountry: 'اختر بلد المزود',
        AccessKey: 'مفتاح الوصول',
        SelectChannels: 'حدد القنوات',
        SelectGroups: 'حدد المجموعات',
        SelectAgents: 'حدد وكلاء',
        SelectTags: 'حدد العلامات',
        SelectAccessLevel: 'حدد مستوى الوصول *',
        Password: 'كلمة المرور*',
        Login: 'يسجل دخول',
        SelectMessengers: 'حدد الرسل',
        UserName: 'اسم المستخدم',
        ClientName: 'اسم العميل',
        ClientFirstName: 'الاسم الأول للعميل',
        ClientLastName: 'الاسم الأخير للعميل',
        ClientPhone: 'هاتف العميل',
        ClientEmail: 'البريد الإلكتروني للعميل',
        ClientInfo: 'معلومات العميل',
        UpdateGroup: 'تحديث المجموعة',
        Agents: 'عملاء',
        UsefulPhrases: 'عبارات مفيدة',
        Channels: 'القنوات',
        Tags: 'العلامات',
        AddNewPhrases: 'أضف عبارات جديدة',
        Phrase: 'عبارة',
        SelectCategory: 'اختر الفئة',
        EditCategory: 'تحرير الفئة',
        EditPhrase: 'تحرير العبارة',
        Or: 'أو',
        AddNewCategory: 'إضافة فئة جديدة',
        Dialogs: 'الحوارات',
        TotalDuration: 'المدة الإجمالية',
        Email: 'بريد الالكتروني',
        ClientId: 'معرف العميل',
        ClientBanType: 'نوع حظر العميل',
        ClientBanTime: 'وقت حظر العميل',
        Messengers: 'رسل',
        IpAddress: 'عنوان IP',
        ExternalID: 'معرف خارجي',
        UpdateBrand: 'تحديث العلامة التجارية',
        CreateMailInbox: 'إنشاء حساب البريد الوارد',
        CreateTelegramInbox: 'قم بإنشاء حساب Telegram',
        CreateSmsInbox: 'إنشاء حساب SMS',
        MailInbox: 'صندوق البريد الوارد',
        TelegramInbox: 'صندوق بريد Telegram',
        SmsInbox: 'صندوق الوارد للرسائل القصيرة',
        ApplicationID: 'رقم الاستمارة',
        ApplicationToken: 'رمز التطبيق',
        SenderID: 'هوية المرسل',
        ChangeName: 'تغيير الإسم',
        ChangeURL: 'تغيير URL',
        TagName: 'اسم العلامة',
        SelectTagStatus: 'حدد حالة العلامة',
        SelectAgent: 'حدد الوكيل',
        UpdateTag: 'تحديث العلامة',
        CreateTag: 'إنشاء علامة',
        UsefulLinks: 'روابط مفيدة',
        LinkAlias: 'ارتباط الاسم المستعار',
        LinkUrl: 'URL رابط',
        Roles: 'الأدوار',
        CreateGroups: 'إنشاء مجموعات',
        Groups: 'مجموعات',
        TeamLead: 'قائد الفريق',
        Ban: 'حظر',
        SelectEnabledLanguages: 'حدد اللغات الممكنة',
        Greetings: 'تحيات',
        FastPhrase: 'عبارة سريعة №',
        ClientHistory: 'سجل العميل',
        ChooseRequiredFields: 'الرجاء اختيار الحقول المطلوبة',
        GetAllDialogs: 'احصل على كل الحوارات',
        GetCurrentDialog: 'احصل على مربع الحوار الحالي',
        SaveInLocalStorage: 'حفظ في LocalStorage',
        SaveInSessionStorage: 'حفظ في SessionStorage',
        InvoiceNumber: 'رقم الفاتورة',
        AccountNumber: 'رقم حساب',
        General: 'عام',
        Authorization: 'تفويض',
        Languages: 'اللغات',
        New: 'جديد',
        Active: 'نشيط',
        Inactive: 'غير نشط',
        WaitForActivation: 'انتظر التفعيل',
        ID: 'هوية شخصية',
        SMS: 'SMS',
        Low: 'قليل',
        Medium: 'متوسط',
        High: 'متوسط',
        Hidden: 'مختفي',
        Brand: 'ماركة',
        Group: 'مجموعة',
        ChangeStatus: 'تغيير الوضع',
        AuthMail: 'بريد المصادقة',
        SetDomainSettings: 'ضبط إعدادات المجال',
        Domain: 'اختصاص*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (host:port)*',
        SmtpSSL: 'Smtp SSL (host:port)*',
        AttachFile: 'أرفق ملف',
        ChannelSettings: 'اعدادات القناة',
        GeneralSettings: 'الاعدادات العامة',
        WebChatConstructor: 'منشئ دردشة الويب',
        BackgroundColor: 'لون الخلفية',
        MessageColor: 'لون الرسالة',
        URL: 'URL',
        ChannelName: 'اسم القناة',
        ChannelURL: 'قناة URL',
        AllowSMS: 'السماح بالرسائل القصيرة',
        AllowShowAllClientDialogs: 'السماح بإظهار كافة حوارات العميل',
        AllowCaptcha: 'السماح باستخدام Captcha',
        InboxSettings: 'إعدادات علبة الوارد',
        DistributionOfNewDialogs: 'توزيع حوارات جديدة بين الوكلاء عبر الإنترنت',
        ThereAreNoAvailableMessengers: 'لا يوجد رسل متاحون للقناة! يرجى إنشائها للعلامة التجارية التي تمتلك هذه القناة!',
        HeaderColorScheme: 'نظام لون الرأس',
        GreetingColorScheme: 'نظام ألوان الترحيب',
        QuickPhrases: 'نظام ألوان العبارات السريعة',
        ClientMessage: 'نظام ألوان رسالة العميل',
        AgentMessage: 'نظام ألوان رسالة الوكيل',
        EndDialogModal: 'مخطط ألوان مشروط لمربع الحوار الختامي',
        IconColorScheme: 'نظام ألوان الأيقونة',
        ScrollColorScheme: 'نظام ألوان التمرير',
        ChatWindowColor: 'نظام ألوان نافذة الدردشة',
        FooterColorScheme: 'نظام ألوان التذييل',
        AuthColorScheme: 'نظام ألوان المصادقة',
        HeaderBackground: 'خلفية العنوان',
        HeaderBorderColor: 'لون حد الرأس',
        ColorGreetingBotName: 'لون اسم بوت التحية',
        MessageGreetingIcon: 'تحية أيقونة الخلفية',
        MessageGreetingColor: 'لون نص التحية',
        MessageGreetingBorderColor: 'رسالة الترحيب لون الحد',
        MessageGreetingBackgroundColor: 'لون الخلفية لرسالة الترحيب',
        QuickPhrasesColor: 'سريع لون نص العبارة',
        QuickPhrasesBackground: 'عبارة سريعة الخلفية',
        QuickPhrasesBorderColor: 'العبارة السريعة لون الحدود',
        QuickPhrasesColorHover: 'عبارة سريعة تحوم اللون',
        QuickPhrasesBackgroundHover: 'العبارة السريعة تحوم الخلفية',
        QuickPhrasesBorderColorHover: 'عبارة سريعة حول لون الحدود',
        ClientMessageColor: 'لون نص رسالة العميل',
        ClientTimeColor: 'لون وقت العميل',
        ClientMessageBackground: 'لون الخلفية لرسالة العميل',
        ClientMessageBorderColor: 'لون الحدود رسالة العميل',
        ClientLink: 'ارتباط العميل',
        ClientLinkHover: 'تحوم فوق ارتباط العميل',
        ClientActiveLink: 'رابط العميل النشط',
        ClientVisitedLink: 'رابط زار العميل',
        ColorAgentNameAndTime: 'اسم الوكيل ولون الوقت',
        MessageAgentColor: 'لون رسالة الوكيل',
        MessageAgentBackgroundColor: 'خلفية رسالة الوكيل',
        MessageAgentBorderColor: 'رسالة الوكيل لون الحد',
        AgentLink: 'رابط الوكيل',
        AgentHoverLink: 'رابط تحوم الوكيل',
        AgentActiveLink: 'رابط نشط الوكيل',
        AgentVisitedLink: 'وكيل زار الرابط',
        MessageIconBackgroundAgent: 'وكيل رسالة رمز لون الخلفية',
        TextColor: 'لون الخط',
        BackdropColor: 'لون الخلفية',
        BackdropColorHover: 'تحوم لون الخلفية',
        ButtonColor: 'لون الزر',
        BackdropColorBtn: 'لون خلفية زر الخلفية',
        IconBackground: 'خلفية الرمز',
        ChatWindowsBodyBackground: 'لون خلفية جسم نافذة الدردشة',
        ChatWindowsBorderColor: 'لون حدود نوافذ الدردشة',
        FooterBackground: 'لون خلفية التذييل',
        FooterBorderColor: 'لون حد التذييل',
        FooterBackgroundTextArea: 'لون خلفية منطقة نص التذييل',
        InputBorderColor: 'لون حدود الإدخال',
        RegistrationBackgroundColor: 'لون خلفية التسجيل',
        LabelColor: 'لون التسمية',
        LabelErrorColor: 'لون خطأ التسمية',
        InputColor: 'لون الإدخال',
        InputBackground: 'خلفية الإدخال',
        SignUpColor: 'لون التسجيل',
        SignUpBackground: 'تسجيل لون الخلفية',
        Chat: 'دردشة',
        Minimized: 'مصغر',
        ScrollBackground: 'خلفية التمرير',
        ScrollThumbBackground: 'انتقل إلى خلفية الإبهام',
        UniqueClientIdentifier: 'معرف العميل الفريد',
        AgentsSettings: 'وكلاء الإعدادات',
        ThisAgentSuperAdmin: 'هذا الوكيل هو مشرف متميز ، انقر على الزر لإزالة حقوق المشرف المتميز',
        ThisAgentNotSuperAdmin: 'هذا الوكيل ليس مشرفًا متميزًا ، انقر على الزر لجعله مشرفًا متميزًا',
        YouCanAssignBrandTheBrand: ' يمكنك تعيين العلامة التجارية للمسؤول لهذا تحتاج إلى تحديد العلامة التجارية',
        Role: 'دور',
        BanningThisAgent: 'حظر هذا الوكيل يعني أنه لن يتمكن من تسجيل الدخول إلى النظام الأساسي. ما زالوا سيظهرون في النظام.',
        Report: 'نقل',
        SelectLanguageWebChat: 'حدد اللغات Web Chat',
        FirstName: 'الاسم الأول',
        PhoneNumber: 'رقم التليفون',
        Info: 'معلومات',
        WebChatSettings: 'إعدادات دردشة الويب',
        IntegrationWithOtherCRM: 'التكامل مع CRM الآخرين',
        SettingUpInteractionBetweenSystems: 'إقامة التفاعل بين الأنظمة',
        QuickReplies: 'ردود سريعة',
        BotSettings: 'إعدادات البوت',
        BotName: 'اسم البوت',
        EnabledPrivacyPolicy: 'سياسة الخصوصية الممكّنة',
        PleaseChooseRequiredFields: 'الرجاء اختيار الحقول المطلوبة',
        ChangeGreetingsAndPhrases: 'تغيير التحيات والعبارة وسياسة الخصوصية',
        ChangeSmsSettings: 'تغيير إعدادات الرسائل القصيرة',
        ChangeCountries: 'تغيير البلدان',
        ContactsAreWorkingWithClientUniqueness: 'تعمل جهات الاتصال مع تفرد العميل. الحقل مطلوب ، إذا كان تفرد العميل مساويًا له.',
        ClientUniquenessIs: 'تفرد العميل هو:',
        PrivacyPolicyText: 'نص سياسة الخصوصية',
        PrivacyPolicyLink: 'رابط سياسة الخصوصية',
        PrivacyPolicyLinkText: 'نص ارتباط سياسة الخصوصية',
        AgentPhone: 'هاتف الوكيل',
        HeaderSettings: 'إعدادات الرأس',
        DisableDraggable: 'تعطيل السحب',
        DisableHeaderFullScreen: 'تعطيل رأس ملء الشاشة',
        DisableHeaderHideChatWindow: 'تعطيل إخفاء نافذة الدردشة الرئيسية',
        DisableHeaderLogo: 'تعطيل شعار الرأس',
        DisableHeaderShowEndDialogModal: 'إيقاف مشروط عرض نهاية عرض الرأس',
        HeaderText: 'نص العنوان',
        HeaderTextTop: 'رأس نص أعلى',
        HeaderTextBottom: 'نص العنوان السفلي',
        HeaderHeight: 'ارتفاع الرأس',
        LogoIconUrl: 'عنوان URL لرمز الشعار',
        IconUrl: 'عنوان URL للرمز',
        IconSettings: 'إعدادات الرمز',
        ScrollSettings: 'انتقل إلى الإعدادات',
        ScrollWidth: 'عرض التمرير',
        ChatWindowsLogo: 'شعار نوافذ الدردشة',
        BackgroundImageSize: 'حجم صورة الخلفية',
        GreetingSettings: 'إعدادات الترحيب',
        GreetingIconBotUrl: 'عنوان بوت رمز الترحيب',
        QuickPhrasesSettings: 'إعدادات العبارات السريعة',
        ClientMessageSettings: 'إعدادات رسائل العميل',
        AgentMessageSettings: 'إعدادات رسالة الوكيل',
        AgentMessageIconUrl: 'عنوان URL لرمز رسالة الوكيل',
        EndDialogModalSettings: 'إعدادات صيغة حوار النهاية',
        AuthWindowSettings: 'إعدادات نافذة المصادقة',
        FooterSettings: 'إعدادات التذييل',
        WriteMessagesPlaceholder: 'كتابة العنصر النائب للرسائل',
        SendImageUrl: 'إرسال عنوان URL للصورة',
        SendButtonIconHeight: 'ارتفاع رمز زر الإرسال',
        SendButtonIconWidth: 'عرض رمز زر الإرسال',
        ChatWindowSettings: 'إعدادات نافذة الدردشة',
        ClearAllPhrase: 'مسح كل العبارة',
        AreYouSure: 'هل أنت واثق؟',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'سيؤدي هذا الإجراء إلى إزالة جميع العبارات وجميع الفئات',
        AddAgentToGroup: 'إضافة وكلاء إلى مجموعة',
        CreateClient: 'إنشاء عميل',
        ClientsImport: 'استيراد العميل',
        UploadExcelFile: 'تحميل ملف إكسل',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'انقر فوق رمز مشبك الورق لتحديد ملف Excel',
        Contacts: 'جهات الاتصال',
        Personal: 'شخصي',
        FileUploadHint: 'يرجى تحميل ملف اكسل مليء بأرقام الهواتف. يجب أن يحتوي ملف Excel على عمود يسمى "الهواتف" ويجب إدخال أرقام الهواتف في هذا العمود. يمكن استيراد ملفات .xls أو .xlsx فقط.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'تغيير التحيات والعبارة وسياسة الخصوصية',
    },
    AdditionalText: {
        AgentProfile: 'الملف الشخصي للوكيل',
        OutOf: 'بعيدا عن المكان',
    },
    Pages: {
        Dialogs: {
            Title: 'الحوارات',
            TableColumns: {
                Status: 'حالة',
                Dialog: 'حوار',
                Messenger: 'رسول',
                Channel: 'قناة',
                Tag: 'بطاقة شعار',
                Agent: 'وكيل',
                Rating: 'تقييم',
                Language: 'لغة',
                MessagesCount: 'عدد الرسائل',
                FirstMessage: 'الرسالة الأولى',
                LastActivity: 'النشاط الاخير',
            },
            Messages: 'رسائل',
        },
        AgentGroups: {
            Title: 'مجموعات الوكيل',
            TableColumns: {
                Groups: 'مجموعات',
                Description: 'وصف',
                AccessLevel: 'مستوى الوصول',
                WhiteListTags: 'علامات القائمة البيضاء',
                CreatedAt: 'أنشئت في',
                UpdatedAt: 'تم التحديث في',
            },
        },
        Agents: {
            Title: 'عملاء',
            TableColumns: {
                AgentStatus: 'حالة الوكيل',
                Agents: 'عملاء',
                Email: 'بريد الالكتروني',
                Groups: 'مجموعات',
                CreatedAt: 'أنشئت في',
                LastActivity: 'النشاط الاخير',
            },
        },
        Tags: {
            Title: 'العلامات',
            TableColumns: {
                Tags: 'العلامات',
                Dialogs: 'الحوارات',
                Agents: 'عملاء',
                UsefulLinks: 'روابط مفيدة',
                Status: 'حالة',
                CreatedAt: 'أنشئت في',
                UpdatedAt: 'تم التحديث في',
            },
        },
        Clients: {
            Title: 'العملاء',
            TableColumns: {
                SearchName: 'اسم',
                AgentName: 'اسم العميل',
                Messenger: 'رسول',
                LastTag: 'العلامة الأخيرة',
                ActiveDialog: 'لديه حوار نشط',
                Brand: 'ماركة',
                InternalId: 'معرف داخلي',
                ExternalId: 'معرف خارجي',
                Name: 'اسم العميل',
                FirstName: 'الاسم الأول',
                LastName: 'الكنية',
                Email: 'بريد الالكتروني',
                InvoiceNumber: 'رقم الفاتورة',
                DialogsCount: 'عدد الحوارات',
                FirstMessage: 'الرسالة الأولى',
                LastActivity: 'النشاط الاخير',
                Tag: 'بطاقة شعار',
                Status: 'حالة',
                Channel: 'قناة',
                WriteEmail: 'البريد الإلكتروني',
            },
        },
        Channels: {
            Title: 'القنوات',
            TableColumns: {
                ChannelId: 'معرف القناة',
                ChannelKey: 'مفتاح القناة',
                ChannelName: 'اسم القناة',
                Language: 'لغة',
                Url: 'عنوان Url',
                CreatedAt: 'أنشئت في',
                UpdatedAt: 'تم التحديث في',
            },
        },
        Brands: {
            Title: 'العلامات التجارية',
            TableColumns: {
                BrandsId: 'معرف العلامة التجارية',
                BrandName: 'اسم العلامة التجارية',
                ClientUniqueness: 'تفرد العميل',
                BrandChannels: 'قنوات العلامات التجارية',
                DialogCount: 'عدد الحوارات',
                CreatedAt: 'أنشئت في',
                UpdatedAt: 'تم التحديث في',
            },
        },
        Reports: {
            Title: 'التقارير',
            ByMessengersTab: {
                Title: 'تقرير من قبل الرسل',
                Columns: {
                    Messenger: 'رسول',
                    ReceivedDialogs: 'الحوارات المتلقاة',
                    PositiveDialogs: 'الحوارات الإيجابية ،٪',
                    NegativeDialogs: 'الحوارات السلبية ،٪',
                    UnratedDialogs: 'مربعات حوار غير مصنفة ،٪',
                    AvgResponseTime: 'متوسط وقت الاستجابة',
                },
            },
            ByAgentsTab: {
                Title: 'تقرير من قبل الوكلاء',
                Columns: {
                    agentFullName: 'الاسم الكامل للوكيل',
                    agentSystemId: 'معرف نظام الوكيل',
                    brandName: 'اسم العلامة التجارية',
                    receivedDialogs: 'الحوارات المتلقاة',
                    dialogsTelegram: 'حوارات Telegram',
                    dialogsWebChat: 'Web Chat حوارات',
                    dialogsViber: 'Viber حوارات',
                    dialogsWhatsApp: ' حوارات WhatsApp',
                    dialogsFacebook: 'حوارات Facebook',
                    dialogsEmail: 'حوارات Email',
                    dialogsSms: 'حوارات SMS',
                    positiveDialogs: 'الحوارات الإيجابية ،٪',
                    negativeDialogs: 'الحوارات السلبية ،٪',
                    unratedDialogs: 'مربعات حوار غير مصنفة ،٪',
                    avgResponseTime: 'متوسط وقت الاستجابة',
                },
                noDataForThisDay: 'لا توجد بيانات لهذا اليوم',
            },
            ByGroupsTab: {
                Title: 'تقرير من قبل المجموعات',
                Columns: {
                    groupSystemId: 'معرف نظام المجموعة',
                    groupName: 'أسم المجموعة',
                    brandName: 'اسم العلامة التجارية',
                    receivedDialogs: 'الحوارات المتلقاة',
                    positiveDialogs: 'الحوارات الإيجابية ،٪',
                    negativeDialogs: 'الحوارات السلبية ،٪',
                    unratedDialogs: 'مربعات حوار غير مصنفة ،٪',
                    avgResponseTime: 'متوسط وقت الاستجابة',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'أضف عامل تصفية',
        reset: 'إعادة ضبط',
        createClient: 'إنشاء عميل',
        importClient: 'عملاء الاستيراد',
        createChannel: 'إنشاء قناة',
        previous: 'سابق',
        next: 'التالي',
        addPhrase: 'أضف عبارة',
        addGroup: 'أضف مجموعة',
        createBrand: 'إنشاء علامة تجارية',
        addTag: 'إضافة علامة',
        save: 'يحفظ',
        send: 'إرسال',
        getStatistics: 'احصل على الإحصائيات',
        setCountries: 'مجموعة البلدان',
        changeStatus: 'تغيير الوضع',
        upload: 'تحميل',
        setDomainSettings: 'ضبط إعدادات المجال',
        login: 'تسجيل الدخول',
        update: 'تحديث',
        resetPassword: 'إعادة تعيين كلمة المرور',
        addNew: 'اضف جديد',
        addNewPhrase: 'أضف عبارة جديدة',
        addNewCategory: 'إضافة فئة جديدة',
        clearAll: 'امسح الكل',
        loginWithGoogle: 'تسجيل الدخول عبر جوجل',
        loginWithFacebook: 'تسجيل الدخول باستخدام الفيسبوك',
        onPage: 'على الصفحة',
        addNewUsefulLinks: 'أضف روابط مفيدة جديدة',
        backToChannels: 'رجوع إلى القنوات',
        defaultSettings: 'الإعدادات الافتراضية',
        upgradeChannel: 'ترقية القناة',
        apply: 'تطبيق',
        remove: 'إزالة',
        makeSuperAdmin: 'اجعل المشرف المتميز',
        removeSuperAdmin: 'إزالة المشرف المتميز',
        makeBrandAdmin: 'تعيين مسؤول العلامة التجارية',
        removeBrandAdmin: 'إزالة مسؤول العلامة التجارية',
        ban: 'حظر',
        unban: 'رفع الحظر',
        makeTeamLead: 'اصنع قائد الفريق',
        removeTeamLead: 'إزالة قائد الفريق',
        signUp: 'اشتراك',
        ok: 'نعم',
        cancel: 'يلغي',
        bulkMessaging: 'الرسائل الجماعية',
        back: 'الى الخلف',
    },
    Countries: {
        UnitedStates: 'الولايات المتحدة الأمريكية',
        Canada: 'كندا',
        Australia: 'أستراليا',
        CzechRepublic: 'جمهورية التشيك',
        Denmark: 'الدنمارك',
        Hungary: 'هنغاريا',
        Netherlands: 'هولندا',
        Sweden: 'السويد',
        UnitedKingdom: 'المملكة المتحدة',
        France: 'فرنسا',
        Germany: 'ألمانيا',
        Poland: 'بولندا',
        Italy: 'إيطاليا',
        Spain: 'إسبانيا',
    },
    Languages: {
        Ukrainian: 'الأوكرانية',
        Russian: 'الروسية',
        English: 'إنجليزي',
        Polish: 'تلميع',
        Deutsch: 'الألمانية',
        Spanish: 'الأسبانية',
        Vietnamese: 'فيتنامي',
        Portuguese: 'البرتغالية',
        Korean: 'الكورية',
        Italian: 'إيطالي',
        Arabic: 'عربي',
        French: 'الفرنسية',
        Azerbaijan: 'أذربيجان',
        Hungarian: 'الهنغارية',
    },
    BulkMessaging: {
        exclude: 'استبعاد',
        typeText: 'أكتب نصا',
        selectClients: 'حدد العملاء',
        uploadClients: 'تحميل العملاء',
        sendingMessagesToNewClients: 'إرسال الرسائل للعملاء الجدد',
        sendingMessagesToExistingClients: 'إرسال الرسائل للعملاء الحاليين',
        clientsWhoHaveDialogsWithFollowingStatus: 'العملاء ، الذين لديهم حوارات مع الحالة التالية',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'العملاء ، الذين لديهم هذا النص في الاسم أو اسم العائلة أو اسم المستخدم',
        limitQuantity: 'كمية محدودة',
        quantityLimit: 'حد الكمية',
        limitQuantityText: 'حدد الكمية في البث بما لا يزيد عن العدد المحدد من المستلمين. سيتم إدراج عملاء النظام الجدد في القائمة.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'العملاء ، الذين كتبوا إلى AskMe قبل وقت الرسالة الأخيرة',
    },
};
export default Messages;
