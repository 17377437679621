var Messages = {
    Success: {
        Success: 'Succès',
        ForgotPasswordSend: 'Le lien de changement de mot de passe a été envoyé à votre adresse e-mail',
        ResetPasswordSuccess: 'Votre mot de passe a été modifié avec succès',
        BrandCreated: 'Marque créée !',
        MailInboxCreated: 'Boîte de réception créée !',
        PrivacyPolicyChanged: 'La politique de confidentialité a changé!',
        SmsInboxCreated: 'Boîte de réception SMS créée !',
        InboxDeleted: 'Boîte de réception supprimée !',
        StatusChanged: 'Statut changé !',
        FileLoaded: 'Fichier chargé !',
        AuthMail: 'Courrier d\'authentification !',
        DomainSettings: 'Paramètres de domaine !',
        UpdatedSmsAccounts: 'Comptes SMS mis à jour !',
        ChanelCreated: 'Chaîne créée !',
        DeleteMessenger: 'Supprimer Messenger !',
        AddMessenger: 'Ajoutez Messager !',
        ChangedMessenger: 'Messager changé !',
        ClientCreated: 'Client créé !',
        ClientsImports: 'Importations de clients !',
        GroupCreated: 'Groupe créé !',
        ChangedAssignedAgentsInGroups: 'Agents affectés modifiés dans les groupes !',
        ChangedAssignedChannelsInGroups: 'Modification des canaux attribués dans les groupes !',
        ChangedAssignedTagsInGroups: 'Balises attribuées modifiées dans les groupes !',
        CreateNewCategory: 'CCréez une nouvelle catégorie !',
        CreateNewPhrase: 'Créez une nouvelle phrase !',
        DeleteCategory: 'Supprimer la catégorie !',
        DeletePhrase: 'Supprimer la phrase !',
        UpdatePhrase: 'Mettre à jour la phrase !',
        EditCategory: 'Modifier la catégorie !',
        DeleteAllCategory: 'Supprimer toutes les catégories !',
        TagCreated: 'Balise créée !',
        TagUpdated: 'Balise mise à jour !',
        TelegramInboxCreated: 'Boîte de réception Telegram créée !',
        HeaderTextChanged: 'Texte d\'en-tête modifié',
        LogoIconUrlChanged: 'L\'URL de l\'icône du logo a été modifiée',
        IconUrlChanged: 'URL de l\'icône modifiée',
        BackgroundImageUrlChanged: 'URL de l\'image d\'arrière-plan modifiée',
        GreetingImageUrlChanged: 'L\'URL de l\'image de salutation a été modifiée',
        AgentImageUrlChanged: 'URL de l\'image de l\'agent modifiée',
        InputPlaceholderChanged: 'Espace réservé d\'entrée modifié',
        SendImageUrlChanged: 'Envoyer l\'URL de l\'image modifiée',
        AgentStatusChanged: 'Statut de l\'agent modifié',
        SelectedAgentTagsChanged: 'Les balises d\'agent sélectionnées ont été modifiées',
        AssignedAgentsGroupsChanged: 'Les groupes d\'agents attribués ont été modifiés',
        TeamLeadChanged: 'Changement de chef d\'équipe',
        AgentRoleChanged: 'Rôle d\'agent modifié',
        AgentInfoChanged: 'Les informations sur l\'agent ont été modifiées',
    },
    Errors: {
        PasswordMatching: 'Les mots de passe ne correspondent pas!',
        IncorrectEmail: 'L\'e-mail est incorrect',
        IncorrectPhone: 'Le téléphone est incorrect',
        IncorrectUrl: 'L\'URL est incorrecte',
        FieldContainSpaces: 'Le champ contient des espaces',
        FieldIsRequired: 'Champ requis',
        NameValidation: 'La longueur du champ doit être comprise entre 2 et 40 caractères inclus',
        NumberValidation: 'Le champ ne doit contenir que des chiffres',
        PasswordValidation: 'Le mot de passe doit contenir des lettres et des chiffres et ne peut pas contenir d\'espaces',
        InvoiceNumberValidation: 'Le numéro de facture doit contenir des lettres latines, des chiffres et des caractères spéciaux',
        MinimumLength: 'Longueur minimale {0} symboles',
        MaximumLength: 'Longueur maximale {0} symboles',
        ServerError: 'Erreur de serveur, veuillez réessayer plus tard !',
        ServerErrorFileDownloadTimeOut: 'Erreur de serveur, le téléchargement du fichier a expiré, veuillez réessayer plus tard!',
        NotValidEmailOrPassword: 'E-mail ou mot de passe non valide',
        UserWasNotFound: 'L\'utilisateur n\'a pas été trouvé!',
        UserIsAlreadyExist: 'L\'utilisateur existe déjà!',
        AgentWasBanned: 'L\'agent a été banni',
        TokenExpired: 'La session a expiré. Veuillez vous reconnecter',
        TelegramIdIsIncorrect: 'L\'identifiant du télégramme est incorrect',
        ErrorWrongFileFormat: 'Erreur, mauvais format de fichier!',
        FileSizeExceededWarnings: 'La taille du fichier a dépassé les avertissements!',
        BrandWithSuchNameIsAlreadyExisting: 'La marque avec un tel nom existe déjà!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Vous avez déjà sélectionné un filtre',
        AgentCreate: 'Vous êtes un nouvel agent dans le système AskMe. Veuillez noter que vous ne pouvez vous connecter qu\'après avoir reçu le rôle d\'administrateur!',
        NoStatisticsForTheAgent: 'Aucune donnée statistique pour l\'agent pour la période sélectionnée',
        FieldIsRequired: 'Champ requis',
        ChannelMustContainAtLeastOneLanguage: 'La chaîne doit contenir au moins une langue',
    },
    Info: {
        Copied: 'copié',
        PhraseBotNameAndGreetingsChanged: 'Phrases, nom du bot et message d\'accueil modifiés',
    },
    Confirm: {},
    Message: {
        Welcome: 'Bienvenue sur AksMe',
        SignIn: 'Connectez-vous au panneau d\'administration ou accédez à votre',
        SignUp: 'Inscrivez-vous au panneau d\'administration ou accédez à votre ',
        Register: 'Vous n\'avez pas de compte chez nous ?',
        Login: 'Vous avez déjà un compte?',
        Logout: 'Voulez-vous vraiment partir ?',
        ForgotPassword: 'Mot de passe oublié?',
        ExcelFilesDownloading: 'Téléchargement de fichiers Excel',
        excelFileIs: 'fichier Excel',
        Created: 'est créé',
        Creating: 'crée',
    },
    Accordions: {
        MailInboxes: 'Boîtes de réception',
        TelegramAccounts: 'Comptes de télégramme',
        SmsAccounts: 'Comptes SMS',
    },
    Controls: {
        Newbie: 'Débutante',
        Name: 'Nom',
        Email: 'E-mail',
        Password: 'Mot de passe',
        RepeatPassword: 'Répéter le mot de passe',
        Register: 'S\'inscrire',
        Login: 'Connexion',
        Or: 'OU',
        SignUp: 'S\'inscrire',
        Send: 'Envoyer',
        SearchFilter: 'Filtres de recherche',
        Search: 'Chercher',
        SearchMessengers: 'Rechercher des messagers',
        SearchAgent: 'Agent de recherche',
        SearchLanguage: 'Langue de recherche',
        SearchChannel: 'Rechercher un canal',
        SearchTag: 'Balise de recherche',
        SearchStatus: 'État de la recherche',
        SearchBrand: 'Marque de recherche',
        SearchWhiteListTags: 'Rechercher des balises de liste blanche',
        SearchSessionStatus: 'Rechercher l\'état de la session',
        SearchAccessLevel: 'Niveau d\'accès à la recherche',
        SearchHasActiveDialogs: 'La recherche a des boîtes de dialogue actives',
        GroupName: 'Nom de groupe*',
        Description: 'La description*',
        LinkAlias: 'Alias de lien*',
        CategoryName: 'Nom de catégorie*',
        BrandName: 'Marque',
        AccessKey: 'Clef d\'accès',
        ClientName: 'Nom du client',
        ClientFirstName: 'Prénom du client',
        ClientLastName: 'Nom de famille du client',
        ClientPhone: 'Téléphone du client',
        ClientEmail: 'E-mail du client',
        PleaseWrite: 'Écrivez s\'il vous plait',
        MailInbox: 'Boîte de réception',
        SmsInbox: 'Boîte de réception SMS',
        TelegramInbox: 'Boîte de réception des télégrammes',
        ApplicationID: 'ID d\'application',
        ApplicationToken: 'Jeton d\'application',
        SenderID: 'ID de l\'expéditeur',
        ChangeName: 'Changer de nom',
        ChangeURL: 'Modifier l\'URL',
        TagName: 'Nom de la balise',
        LinkUrl: 'URL du lien*',
        Greetings: 'Les salutations',
        FastPhrase: 'Phrase rapide №',
        ClickOnPaperclip: 'Cliquez sur l\'icône du trombone pour sélectionner le fichier',
        UserName: 'Connexion',
        Domain: 'Domaine*',
        Imap: 'Imap (hôte:port)*',
        SmtpTLS: 'Smtp TLS (hôte:port)*',
        SmtpSSL: 'Smtp SSL (hôte:port)*',
        AttachFile: 'Pièce jointe',
        NoData: 'pas de données',
        NoRating: 'pas de notation',
        IfYouWantFinishDialog: 'Si desea finalizar el cuadro de diálogo, haga clic en el botón Aceptar',
        AllDialogsAreVisibleToAllAgents: 'Toutes les boîtes de dialogue sont visibles pour tous les agents',
        DialogueIsAssignedAutomatically: 'Le dialogue est attribué automatiquement à l\'agent qui a le moins de dialogues',
        AllDialogsAreVisibleToSeveralAgents: 'Les dialogues sont visibles par plusieurs agents avec le moins de dialogues',
        On: 'Au',
        Off: 'Désactivé',
        FirstName: 'Prénom',
        InvoiceNumber: 'Numéro de facture',
        LastName: 'Nom de famille',
        SMS: 'SMS',
        New: 'Nouveau',
        Active: 'Actif',
        Finished: 'Achevé',
        Assigned: 'Attribué',
        Online: 'En ligne',
        Offline: 'Hors ligne',
        Break: 'Se rompre',
        Low: 'Meugler',
        Medium: 'Moyen',
        High: 'Haut',
        True: 'Vrai',
        False: 'Faux',
        Date: 'Date',
        AgentName: 'Nom d\'agent',
        Messenger: 'Messager',
        Language: 'Langue',
        Channel: 'Canal',
        Tag: 'Étiqueter',
        Status: 'Statut',
        Brand: 'Marque',
        AccessLevel: 'Niveau d\'accès',
        WhiteListTags: 'Balises de la liste blanche',
        SessionStatus: 'État de la session',
        LastTags: 'Dernières balises',
        HasActiveDialogs: 'A des dialogues actifs',
        ChannelName: 'Nom du canal',
        WriteYourFirstName: '* S\'il vous plaît, écrivez votre prénom',
        WriteYourLastName: '* S\'il vous plaît, écrivez votre nom de famille',
        WriteYourUserName: '* S\'il vous plaît, écrivez le nom d\'utilisateur',
        WriteYourPhoneNumber: '* S\'il vous plaît, écrivez le numéro de téléphone',
        WriteYourEmail: '* S\'il vous plaît, écrivez votre email',
        WriteYourInvoiceNumber: '* S\'il vous plaît, écrivez votre numéro de facture',
        WriteYourAccountNumber: '* S\'il vous plaît, écrivez votre numéro de compte',
        BotName: 'Nom du robot',
        Character: 'symbole',
        Number: 'numéro',
        IdExample: 'Exemple d\'identifiant',
        XCharecter: 'X-caractère',
        YNumber: 'Numéro Y',
        ThisOperationCanTakeLongTime: 'Cette opération peut être longue !',
        ClickTiSaveExcel: 'Cliquez pour enregistrer dans Excel',
        PrivacyPolicyText: 'Texte de la politique de confidentialité',
        PrivacyPolicyLink: 'Lien politique de confidentialité',
        PrivacyPolicyLinkText: 'Texte du lien de la politique de confidentialité',
        AgentPhone: 'Téléphone de l\'agent',
        HeaderTextTop: 'Haut du texte de l\'en-tête',
        HeaderTextBottom: 'Texte d\'en-tête en bas',
        LogoIconUrl: 'URL de l\'icône du logo',
        IconUrl: 'URL de l\'icône',
        ChatWindowsLogo: 'Logo des fenêtres de chat',
        GreetingIconBotUrl: 'Url du bot de l\'icône de salutation',
        AgentMessageIconUrl: 'URL de l\'icône du message de l\'agent',
        WriteMessagesPlaceholder: 'Espace réservé pour écrire des messages',
        SendImageUrl: 'Envoyer l\'URL de l\'image',
        ChannelURL: 'URL de la chaîne',
    },
    Selects: {
        SelectAccessLevel: 'Sélectionnez le niveau d\'accès*',
        SelectChannel: 'Sélectionnez la chaîne*',
        SelectChannels: 'Sélectionnez les chaînes',
        SelectWhiteTagsList: 'Sélectionner la liste des balises blanches*',
        SelectAgents: 'Sélectionnez des agents',
        SelectGroups: 'Sélectionner des groupes',
        SelectBrand: 'Sélectionnez la marque',
        SelectTags: 'Sélectionnez les balises',
        ChooseProviderCountry: 'Choisissez le pays du fournisseur',
        SelectMessengers: 'Sélectionnez les messagers',
        SelectTagStatus: 'Sélectionnez l\'état de la balise',
        SelectAgent: 'Sélectionnez un agent',
        SelectEnabledLanguages: 'Sélectionnez les langues activées',
        ChannelName: 'Nom du canal',
        ChannelURL: 'URL de la chaîne',
        SelectLanguageWebChat: 'Sélectionner les langues Chat Web',
    },
    Labels: {
        NumberOfNewAgents: 'Nombre de nouveaux agents',
        TextForBulkMessaging: 'Texte pour la messagerie groupée',
        Name: 'Nom',
        LastName: 'Nom de famille',
        Phone: 'Téléphoner',
        GroupName: 'Nom de groupe*',
        SelectBrand: 'Sélectionnez la marque',
        Channel: 'Canaliser*',
        Description: 'La description*',
        AccessLevel: 'Niveau d\'accès*',
        WhiteTagsList: 'Liste des balises blanches*',
        BrandName: 'Marque',
        CategoryName: 'Nom de catégorie*',
        ChooseProviderCountry: 'Choisissez le pays du fournisseur',
        AccessKey: 'Clef d\'accès',
        SelectChannels: 'Sélectionnez les chaînes',
        SelectGroups: 'Sélectionner des groupes',
        SelectAgents: 'Sélectionnez des agents',
        SelectTags: 'Sélectionnez les balises',
        SelectAccessLevel: 'Sélectionnez le niveau d\'accès*',
        Password: 'Mot de passe*',
        Login: 'Connexion',
        SelectMessengers: 'Sélectionnez les messagers',
        UserName: 'Nom d\'utilisateur',
        ClientName: 'Nom du client',
        ClientFirstName: 'Prénom du client',
        ClientLastName: 'Nom de famille du client',
        ClientPhone: 'Téléphone du client',
        ClientEmail: 'E-mail du client',
        ClientInfo: 'Informations client',
        UpdateGroup: 'Groupe de mise à jour',
        Agents: 'Agents',
        UsefulPhrases: 'Phrases utiles',
        Channels: 'Canaux',
        Tags: 'Mots clés',
        AddNewPhrases: 'Ajouter de nouvelles phrases',
        Phrase: 'Phrase',
        SelectCategory: 'Choisir une catégorie',
        EditCategory: 'Modifier la catégorie',
        EditPhrase: 'Modifier l\'expression',
        Or: 'ou',
        AddNewCategory: 'Ajouter une nouvelle catégorie',
        Dialogs: 'Dialogues',
        TotalDuration: 'Durée totale',
        Email: 'E-mail',
        ClientId: 'Identité du client',
        ClientBanType: 'Type de bannissement client',
        ClientBanTime: 'Délai d\'interdiction du client',
        Messengers: 'Messagers',
        IpAddress: 'adresse IP',
        ExternalID: 'ID externe',
        UpdateBrand: 'Mettre à jour la marque',
        CreateMailInbox: 'Créer un compte de boîte de réception',
        CreateTelegramInbox: 'Créer un compte Telegram',
        CreateSmsInbox: 'Créer un compte SMS',
        MailInbox: 'Boîte de réception',
        TelegramInbox: 'Boîte de réception des télégrammes',
        SmsInbox: 'Boîte de réception SMS',
        ApplicationID: 'ID d\'application',
        ApplicationToken: 'Jeton d\'application',
        SenderID: 'ID de l\'expéditeur',
        ChangeName: 'Changer de nom',
        ChangeURL: 'Modifier l\'URL',
        TagName: 'Nom de la balise',
        SelectTagStatus: 'Sélectionnez l\'état de la balise',
        SelectAgent: 'Sélectionnez un agent',
        UpdateTag: 'Mettre à jour la balise',
        CreateTag: 'Créer une balise',
        UsefulLinks: 'Liens utiles',
        LinkAlias: 'Alias de lien',
        LinkUrl: 'URL du lien',
        Roles: 'Les rôles',
        Groups: 'Groupes',
        CreateGroups: 'Créer des groupes',
        TeamLead: 'Chef d\'équipe',
        Ban: 'Interdire',
        SelectEnabledLanguages: 'Sélectionnez les langues activées',
        Greetings: 'Les salutations',
        FastPhrase: 'Phrase rapide №',
        ClientHistory: 'Historique des clients',
        ChooseRequiredFields: 'Veuillez choisir les champs obligatoires',
        GetAllDialogs: 'Obtenir toutes les boîtes de dialogue',
        GetCurrentDialog: 'Obtenir la boîte de dialogue actuelle',
        SaveInLocalStorage: 'Enregistrer dans LocalStorage',
        SaveInSessionStorage: 'Enregistrer dans SessionStorage',
        InvoiceNumber: 'Numéro de facture',
        AccountNumber: 'Numéro de compte',
        General: 'Général',
        Authorization: 'Autorisation',
        Languages: 'Langages',
        New: 'Nouveau',
        Active: 'Actif',
        Inactive: 'Inactif',
        WaitForActivation: 'Attendre l\'activation',
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Meugler',
        Medium: 'Moyen',
        High: 'Haut',
        Hidden: 'Caché',
        Brand: 'Marque',
        Group: 'Grouper',
        ChangeStatus: 'Changer de statut',
        AuthMail: 'Courrier d\'authentification',
        SetDomainSettings: 'Définir les paramètres du domaine',
        Domain: 'Domaine*',
        Imap: 'Imap (hôte:port)*',
        SmtpTLS: 'Smtp TLS (hôte:port)*',
        SmtpSSL: 'Smtp SSL (hôte:port)*',
        AttachFile: 'Pièce jointe',
        ChannelSettings: 'Configuración de canales',
        GeneralSettings: 'Configuración general',
        WebChatConstructor: 'Constructor de chat web',
        BackgroundColor: 'Color de fondo',
        MessageColor: 'Color del mensaje',
        URL: 'URL',
        ChannelName: 'Nom du canal',
        ChannelURL: 'URL de la chaîne',
        AllowSMS: 'Autoriser les SMS',
        AllowShowAllClientDialogs: 'Autoriser l\'affichage de toutes les boîtes de dialogue client',
        AllowCaptcha: 'Autoriser Captcha',
        InboxSettings: 'Paramètres de la boîte de réception',
        DistributionOfNewDialogs: 'Répartition des nouveaux dialogues entre les agents en ligne',
        ThereAreNoAvailableMessengers: 'Il n\'y a pas de messagers disponibles pour la chaîne ! Veuillez les créer pour la marque propriétaire de cette chaîne !',
        HeaderColorScheme: 'Jeu de couleurs d\'en-tête',
        GreetingColorScheme: 'Jeu de couleurs de salutation',
        QuickPhrases: 'Schéma de couleurs des phrases rapides',
        ClientMessage: 'Jeu de couleurs des messages clients',
        AgentMessage: 'Jeu de couleurs des messages de l\'agent',
        EndDialogModal: 'Jeu de couleurs modal de fin de dialogue',
        IconColorScheme: 'Palette de couleurs des icônes',
        ScrollColorScheme: 'Jeu de couleurs de défilement',
        ChatWindowColor: 'Jeu de couleurs de la fenêtre de chat',
        FooterColorScheme: 'Palette de couleurs du pied de page',
        AuthColorScheme: 'Palette de couleurs authentique',
        HeaderBackground: 'Arrière-plan de l\'en-tête',
        HeaderBorderColor: 'Couleur de la bordure de l\'en-tête',
        ColorGreetingBotName: 'Couleur du nom du bot de salutation',
        MessageGreetingIcon: 'Fond d\'icône de salutation',
        MessageGreetingColor: 'Couleur du texte de salutation',
        MessageGreetingBorderColor: 'Message d\'accueil Couleur de la bordure',
        MessageGreetingBackgroundColor: 'Message d\'accueil Couleur de fond',
        QuickPhrasesColor: 'Phrase rapide Couleur du texte',
        QuickPhrasesBackground: 'Phrase rapide Contexte',
        QuickPhrasesBorderColor: 'Phrase rapide Couleur de la bordure',
        QuickPhrasesColorHover: 'Phrase rapide Survoler la couleur',
        QuickPhrasesBackgroundHover: 'Phrase rapide Survol d\'arrière-plan',
        QuickPhrasesBorderColorHover: 'Phrase rapide Survol de la couleur de la bordure',
        ClientMessageColor: 'Message client Couleur du texte',
        ClientTimeColor: 'Couleur de l\'heure du client',
        ClientMessageBackground: 'Message client Couleur de fond',
        ClientMessageBorderColor: 'Couleur de la bordure du message client',
        ClientLink: 'Client Link',
        ClientLinkHover: 'Survol du lien client',
        ClientActiveLink: 'Lien client actif',
        ClientVisitedLink: 'Lien visité par le client',
        ColorAgentNameAndTime: 'Nom de l\'agent et couleur de l\'heure',
        MessageAgentColor: 'Couleur des messages de l\'agent',
        MessageAgentBackgroundColor: 'Contexte du message de l\'agent',
        MessageAgentBorderColor: 'Message de l\'agent Couleur de la bordure',
        AgentLink: 'Lien de l\'agent',
        AgentHoverLink: 'Lien de survol de l\'agent',
        AgentActiveLink: 'Lien actif de l\'agent',
        AgentVisitedLink: 'Lien visité par l\'agent',
        MessageIconBackgroundAgent: 'Couleur d\'arrière-plan de l\'icône du message de l\'agent',
        TextColor: 'Couleur du texte',
        BackdropColor: 'Couleur de fond',
        BackdropColorHover: 'Survol de la couleur de fond',
        ButtonColor: 'Couleur du bouton',
        BackdropColorBtn: 'Couleur d\'arrière-plan du bouton Backdrop',
        IconBackground: 'Fond d\'icône',
        ChatWindowsBodyBackground: 'Couleur d\'arrière-plan du corps des fenêtres de chat',
        ChatWindowsBorderColor: 'Couleur de la bordure des fenêtres de chat',
        FooterBackground: 'Couleur de fond du bas de page',
        FooterBorderColor: 'Couleur de la bordure du pied de page',
        FooterBackgroundTextArea: 'Couleur d\'arrière-plan de la zone de texte du pied de page',
        InputBorderColor: 'Couleur de la bordure d\'entrée',
        RegistrationBackgroundColor: 'Couleur de fond d\'enregistrement',
        LabelColor: 'Couleur de l\'étiquette',
        LabelErrorColor: 'Couleur d\'erreur d\'étiquette',
        InputColor: 'Couleur d\'entrée',
        InputBackground: 'Arrière-plan d\'entrée',
        SignUpColor: 'Inscrivez-vous couleur',
        SignUpBackground: 'Inscrivez-vous couleur de fond',
        Chat: 'Discuter',
        Minimized: 'Minimisé',
        ScrollBackground: 'Faire défiler l\'arrière-plan',
        ScrollThumbBackground: 'Faire défiler l\'arrière-plan du pouce',
        UniqueClientIdentifier: 'Identifiant client unique',
        AgentsSettings: 'Paramètres des agents',
        ThisAgentSuperAdmin: 'Cet agent est un super administrateur, cliquez sur le bouton pour supprimer les droits de super administrateur',
        ThisAgentNotSuperAdmin: 'Cet agent n\'est pas un Super admin, cliquez sur le bouton pour le rendre super admin',
        YouCanAssignBrandTheBrand: 'Vous pouvez attribuer la marque de l\'administrateur pour cela, vous devez sélectionner la marque',
        Role: 'Rôle',
        BanningThisAgent: 'Interdire cet agent signifie qu\'il ne pourra pas se connecter à la plateforme. Ils vont encore apparaître dans le système.',
        Report: 'Reportage',
        SelectLanguageWebChat: 'Sélectionner les langues Chat Web',
        FirstName: 'Prénom',
        PhoneNumber: 'Numéro de téléphone',
        Info: 'Info',
        WebChatSettings: 'Paramètres de conversation Web',
        IntegrationWithOtherCRM: 'Intégration avec d\'autres CRM',
        SettingUpInteractionBetweenSystems: 'Mise en place d\'interaction entre les systèmes',
        QuickReplies: 'Réponses rapides',
        BotSettings: 'Paramètres du robot',
        BotName: 'Nom du robot',
        EnabledPrivacyPolicy: 'Politique de confidentialité activée',
        PleaseChooseRequiredFields: 'Veuillez choisir les champs obligatoires',
        ChangeGreetingsAndPhrases: 'Modifier les salutations et la phrase, la politique de confidentialité',
        ChangeSmsSettings: 'Modifier les paramètres SMS',
        ChangeCountries: 'Changer de pays',
        ContactsAreWorkingWithClientUniqueness: 'Les contacts fonctionnent avec l\'unicité du client. Le champ est obligatoire si l\'unicité du client lui est égale.',
        ClientUniquenessIs: 'L\'unicité du client est :',
        PrivacyPolicyText: 'Texte de la politique de confidentialité',
        PrivacyPolicyLink: 'Lien politique de confidentialité',
        PrivacyPolicyLinkText: 'Texte du lien de la politique de confidentialité',
        AgentPhone: 'Téléphone de l\'agent',
        HeaderSettings: 'Paramètres d\'en-tête',
        DisableDraggable: 'Désactiver le déplaçable',
        DisableHeaderFullScreen: 'Désactiver le plein écran de l\'en-tête',
        DisableHeaderHideChatWindow: 'Désactiver la fenêtre de chat de l\'en-tête masqué',
        DisableHeaderLogo: 'Désactiver le logo d\'en-tête',
        DisableHeaderShowEndDialogModal: 'Désactiver l\'affichage de l\'en-tête de fin de dialogue modal',
        HeaderText: 'En-tête',
        HeaderTextTop: 'Haut du texte de l\'en-tête',
        HeaderTextBottom: 'Texte d\'en-tête en bas',
        HeaderHeight: 'Hauteur d\'en-tête',
        LogoIconUrl: 'URL de l\'icône du logo',
        IconUrl: 'URL de l\'icône',
        IconSettings: 'Paramètres des icônes',
        ScrollSettings: 'Paramètres de défilement',
        ScrollWidth: 'Largeur de défilement',
        ChatWindowsLogo: 'Logo des fenêtres de chat',
        BackgroundImageSize: 'Taille de l\'image d\'arrière-plan',
        GreetingSettings: 'Paramètres d\'accueil',
        GreetingIconBotUrl: 'URL du bot de l\'icône de salutation',
        QuickPhrasesSettings: 'Paramètres des phrases rapides',
        ClientMessageSettings: 'Paramètres des messages clients',
        AgentMessageSettings: 'Paramètres des messages de l\'agent',
        AgentMessageIconUrl: 'URL de l\'icône du message de l\'agent',
        EndDialogModalSettings: 'Fin de la boîte de dialogue Paramètres modaux',
        AuthWindowSettings: 'Paramètres de la fenêtre d\'authentification',
        FooterSettings: 'Paramètres du pied de page',
        WriteMessagesPlaceholder: 'Espace réservé pour écrire des messages',
        SendImageUrl: 'Envoyer l\'URL de l\'image',
        SendButtonIconHeight: 'Hauteur de l\'icône du bouton d\'envoi',
        SendButtonIconWidth: 'Envoyer la largeur de l\'icône du bouton',
        ChatWindowSettings: 'Paramètres de la fenêtre de discussion',
        ClearAllPhrase: 'Effacer toute la phrase',
        AreYouSure: 'Êtes-vous sûr?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Cette action supprimera toutes les phrases et toutes les catégories',
        AddAgentToGroup: 'Ajouter des agents à un groupe',
        CreateClient: 'Créer un client',
        ClientsImport: 'Importation de clients',
        UploadExcelFile: 'Téléchargez le fichier Excel',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Cliquez sur l\'icône du trombone pour sélectionner le fichier Excel',
        Contacts: 'Contacts',
        Personal: 'Personnel',
        FileUploadHint: 'Veuillez télécharger un fichier Excel rempli de numéros de téléphone. Le fichier Excel doit contenir une colonne nommée "Téléphones" '
            + 'et les numéros de téléphone doivent être saisis dans cette colonne. Seuls les fichiers .xls ou .xlsx peuvent être importés.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Modifier les salutations et la phrase, la politique de confidentialité',
    },
    AdditionalText: {
        AgentProfile: 'Profil de l\'agent',
        OutOf: 'hors de',
    },
    Pages: {
        Dialogs: {
            Title: 'Dialogues',
            TableColumns: {
                Status: 'Statut',
                Dialog: 'Dialogue',
                Messenger: 'Messager',
                Channel: 'Canaliser',
                Tag: 'Étiqueter',
                Agent: 'Agent',
                Rating: 'Évaluation',
                Language: 'Langue',
                MessagesCount: 'Les messages comptent',
                FirstMessage: 'Premier message',
                LastActivity: 'Dernière Activité',
            },
            Messages: 'Messages',
        },
        AgentGroups: {
            Title: 'Groupes d\'agents',
            TableColumns: {
                Groups: 'Groupes',
                Description: 'La description',
                AccessLevel: 'Niveau d\'accès',
                WhiteListTags: 'Balises de la liste blanche',
                CreatedAt: 'Créé à',
                UpdatedAt: 'Mis à jour à',
            },
        },
        Agents: {
            Title: 'Agents',
            TableColumns: {
                AgentStatus: 'Statut de l\'agent',
                Agents: 'Agents',
                Email: 'Email',
                Groups: 'Groupes',
                CreatedAt: 'Créé à',
                LastActivity: 'Dernière Activité',
            },
        },
        Tags: {
            Title: 'Mots clés',
            TableColumns: {
                Tags: 'Mots clés',
                Dialogs: 'Dialogues',
                Agents: 'Agents',
                UsefulLinks: 'Liens utiles',
                Status: 'Statut',
                CreatedAt: 'Créé à',
                UpdatedAt: 'Mis à jour à',
            },
        },
        Clients: {
            Title: 'Clients',
            TableColumns: {
                SearchName: 'nom',
                AgentName: 'Nom d\'agent',
                Messenger: 'Messager',
                LastTag: 'Dernière balise',
                ActiveDialog: 'A une boîte de dialogue active',
                Brand: 'Marque',
                InternalId: 'Id interne',
                ExternalId: 'Id externe',
                Name: 'Nom du client',
                FirstName: 'Prénom',
                LastName: 'Nom de famille',
                Email: 'Email',
                InvoiceNumber: 'Numéro de facture',
                DialogsCount: 'Nombre de dialogues',
                FirstMessage: 'Premier message',
                LastActivity: 'Dernière Activité',
                Tag: 'Étiqueter',
                Status: 'Statut',
                Channel: 'Canaliser',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Canaux',
            TableColumns: {
                ChannelId: 'Id de la chaine',
                ChannelKey: 'Clé de canal',
                ChannelName: 'Nom du canal',
                Language: 'Langue',
                Url: 'URL',
                CreatedAt: 'Créé à',
                UpdatedAt: 'Mis à jour à',
            },
        },
        Brands: {
            Title: 'Marques',
            TableColumns: {
                BrandsId: 'Id de la marque',
                BrandName: 'Marque',
                ClientUniqueness: 'Unicité du client',
                BrandChannels: 'Chaînes de marque',
                DialogCount: 'Dialogues comptent',
                CreatedAt: 'Créé à',
                UpdatedAt: 'Mis à jour à',
            },
        },
        Reports: {
            Title: 'Rapports',
            ByMessengersTab: {
                Title: 'Rapport par messagers',
                Columns: {
                    Messenger: 'Messager',
                    ReceivedDialogs: 'Boîtes de dialogue reçues',
                    PositiveDialogs: 'Dialogues positifs, %',
                    NegativeDialogs: 'Dialogues négatifs, %',
                    UnratedDialogs: 'Boîtes de dialogue non classées, %',
                    AvgResponseTime: 'Temps de réponse moyen',
                },
            },
            ByAgentsTab: {
                Title: 'Rapport par les agents',
                Columns: {
                    agentFullName: 'Nom complet de l\'agent',
                    agentSystemId: 'ID système de l\'agent',
                    brandName: 'Marque',
                    receivedDialogs: 'Boîtes de dialogue reçues',
                    dialogsTelegram: 'Dialogues Telegram',
                    dialogsWebChat: 'Dialogues Chat Web',
                    dialogsViber: 'Dialogues Viber',
                    dialogsWhatsApp: 'Dialogues WhatsApp',
                    dialogsFacebook: 'Dialogues Facebook',
                    dialogsEmail: 'Dialogues Email',
                    dialogsSms: 'Dialogues SMS',
                    positiveDialogs: 'Dialogues positifs, %',
                    negativeDialogs: 'Dialogues négatifs, %',
                    unratedDialogs: 'Boîtes de dialogue non classées, %',
                    avgResponseTime: 'Temps de réponse moyen',
                },
                noDataForThisDay: 'Pas de données pour ce jour',
            },
            ByGroupsTab: {
                Title: 'Rapport par groupes',
                Columns: {
                    groupSystemId: 'ID système du groupe',
                    groupName: 'Nom de groupe',
                    brandName: 'Marque',
                    receivedDialogs: 'Boîtes de dialogue reçues',
                    positiveDialogs: 'Dialogues positifs, %',
                    negativeDialogs: 'Dialogues négatifs, %',
                    unratedDialogs: 'Boîtes de dialogue non classées, %',
                    avgResponseTime: 'Temps de réponse moyen',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Ajouter un filtre',
        reset: 'Réinitialiser',
        createClient: 'Créer un client',
        importClient: 'Importer des clients',
        createChannel: 'Créer une chaîne',
        previous: 'Précédent',
        next: 'Suivant',
        addPhrase: 'Ajouter une expression',
        addGroup: 'Ajouter un groupe',
        createBrand: 'Créer une marque',
        addTag: 'Ajouter une étiquette',
        save: 'Sauvegarder',
        send: 'Envoyer',
        getStatistics: 'Obtenir des statistiques',
        setCountries: 'Définir les pays',
        changeStatus: 'Changer de statut',
        upload: 'Télécharger',
        setDomainSettings: 'Définir les paramètres du domaine',
        login: 'Connexion',
        update: 'Mettre à jour',
        resetPassword: 'Réinitialiser le mot de passe',
        addNew: 'Ajouter nouveau',
        addNewPhrase: 'Ajouter une nouvelle phrase',
        addNewCategory: 'Ajouter une nouvelle catégorie',
        clearAll: 'Tout effacer',
        loginWithGoogle: 'Connectez-vous avec Google',
        loginWithFacebook: 'Se connecter avec Facebook',
        onPage: 'sur la page',
        addNewUsefulLinks: 'Ajouter de nouveaux liens utiles',
        backToChannels: 'Volver a Canales',
        defaultSettings: 'Configuración por defecto',
        upgradeChannel: 'Actualizar canal',
        apply: 'Appliquer',
        remove: 'Retirer',
        removeSuperAdmin: 'Supprimer le super administrateur',
        makeSuperAdmin: 'Faire super administrateur',
        makeBrandAdmin: 'Rendre l\'administrateur de la marque',
        removeBrandAdmin: 'Supprimer l\'administrateur de la marque',
        ban: 'Ban',
        unban: 'Débannir',
        makeTeamLead: 'Faire du chef d\'équipe',
        removeTeamLead: 'Supprimer le chef d\'équipe',
        signUp: 'S\'inscrire',
        ok: 'D\'accord',
        cancel: 'Annuler',
        bulkMessaging: 'Messagerie en masse',
        back: 'Кetour',
    },
    Countries: {
        UnitedStates: 'États-Unis',
        Canada: 'Canada',
        Australia: 'Australie',
        CzechRepublic: 'République Tchèque',
        Denmark: 'Danemark',
        Hungary: 'Hongrie',
        Netherlands: 'Pays-Bas',
        Sweden: 'La Suède',
        UnitedKingdom: 'Royaume-Uni',
        France: 'La France',
        Germany: 'Allemagne',
        Poland: 'Pologne',
        Italy: 'Italie',
        Spain: 'Espagne',
    },
    Languages: {
        Ukrainian: 'Ukrainien',
        Russian: 'Russe',
        English: 'Anglais',
        Polish: 'Polonais',
        Deutsch: 'Deutsch',
        Spanish: 'Espagnol',
        Vietnamese: 'Vietnamien',
        Portuguese: 'Portugais',
        Korean: 'Coréen',
        Italian: 'Italien',
        Arabic: 'Arabe',
        French: 'Français',
        Azerbaijan: 'Azerbaïdjan',
        Hungarian: 'Hongroise',
    },
    BulkMessaging: {
        exclude: 'Exclure',
        typeText: 'Tapez du texte',
        selectClients: 'Sélectionnez des clients',
        uploadClients: 'Télécharger des clients',
        sendingMessagesToNewClients: 'Envoi de messages aux nouveaux clients',
        sendingMessagesToExistingClients: 'Envoi de messages aux clients existants',
        clientsWhoHaveDialogsWithFollowingStatus: 'Clients, qui ont des boîtes de dialogue avec le statut suivant',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Clients, qui ont ce texte dans le nom ou le nom de famille ou le nom d\'utilisateur',
        limitQuantity: 'Quantité limite',
        quantityLimit: 'Limite de quantité',
        limitQuantityText: 'Limitez la quantité de la diffusion au nombre de destinataires spécifié. Les nouveaux clients du système entreront dans la liste.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Clients, qui ont écrit à AskMe avant l\'heure du dernier message',
    },
};
export default Messages;
