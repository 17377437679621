import React from 'react';
import { Messages } from '@public/locales/messages';
import { pageIcons } from '@public/images';
import Button from '@src/components/Button';
import Header from '@src/modules/Header';
import Toolbar from '@src/modules/ToolBar';
import CreateChannel from '@src/modules/Pages/Channels/CreateChannel';
import ChannelUpdate from '@src/modules/Pages/Channels/ChannelUpdate';
import TableComponent from '@src/modules/Pages/TableComponent';
import { StyledCreateBtnWithImg } from '@src/components/Button/styled';
import { StyledContent, StyledPageWrapper } from '@src/app/styled';
var ChannelsPage = function (props) {
    var currentPage = props.currentPage, toggleModal = props.toggleModal, isModalOpenUpdateChannel = props.isModalOpenUpdateChannel, isModalOpenCreateChannel = props.isModalOpenCreateChannel;
    return (React.createElement(StyledPageWrapper, { className: "channels-page" },
        React.createElement(Header, null),
        React.createElement(StyledContent, { currentPage: currentPage },
            React.createElement("div", { className: "page-btn-wrapper" },
                React.createElement(StyledCreateBtnWithImg, null,
                    React.createElement("img", { src: pageIcons.channel, onClick: function () { }, alt: "createChannel" }),
                    React.createElement(Button, { title: Messages.Buttons.createChannel, onClick: toggleModal }))),
            React.createElement(Toolbar, null),
            React.createElement(TableComponent, null)),
        isModalOpenUpdateChannel && React.createElement(ChannelUpdate, null),
        isModalOpenCreateChannel && React.createElement(CreateChannel, null)));
};
export default ChannelsPage;
