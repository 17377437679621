var Messages = {
    Success: {
        Success: 'Успіх',
        ForgotPasswordSend: 'Посилання для зміни пароля облікового запису було надіслано на вашу адресу електронної пошти',
        ResetPasswordSuccess: 'Ваш пароль успішно оновлено',
        BrandCreated: 'Бренд створено!',
        MailInboxCreated: 'Поштова скринька створена!',
        PrivacyPolicyChanged: 'Змінено політику конфіденційності!',
        SmsInboxCreated: 'Поштова скринька для смс створена!',
        InboxDeleted: 'Поштову скриньку видалено!',
        StatusChanged: 'Статус змінено!',
        FileLoaded: 'Файл завантажено!',
        AuthMail: 'Авторська пошта!',
        DomainSettings: 'Налаштування домену!',
        UpdatedSmsAccounts: 'Cмс акаунти оновлено!',
        ChanelCreated: 'Канал створено!',
        DeleteMessenger: 'Видалити месенджер!',
        AddMessenger: 'Додайте месенджер!',
        ChangedMessenger: 'Змінений месенджер!',
        ClientCreated: 'Клієнт створено!',
        ClientsImports: 'Імпорт клієнтів!',
        GroupCreated: 'Група створена!',
        ChangedAssignedAgentsInGroups: 'Змінено призначені агенти у групах!',
        ChangedAssignedChannelsInGroups: 'Змінено призначені канали у групах!',
        ChangedAssignedTagsInGroups: 'Змінено привласнені теги у групах!',
        CreateNewCategory: 'Створити нову категорію!',
        CreateNewPhrase: 'Створити нову фразу!',
        DeleteCategory: 'Видалити категорію!',
        DeletePhrase: 'Видалити фразу!',
        UpdatePhrase: 'Оновіть фразу!',
        EditCategory: 'Змінити категорію!',
        DeleteAllCategory: 'Видалити всі категорії!',
        TagCreated: 'Тег створено!',
        TagUpdated: 'Тег оновлено!',
        TelegramInboxCreated: 'Обліковий запис телеграм створено!',
        HeaderTextChanged: 'Текст шапки змінено',
        LogoIconUrlChanged: 'URL іконки лого змінено',
        IconUrlChanged: 'URL іконки змінено',
        BackgroundImageUrlChanged: 'URL зображення заднього фону змінено',
        GreetingImageUrlChanged: 'URL картинки привітання змінено',
        AgentImageUrlChanged: 'URL картинки агента змінено',
        InputPlaceholderChanged: 'Прототип поля введення змінено',
        SendImageUrlChanged: 'URL зображення відправки змінено',
        AgentStatusChanged: 'Статус агента змінено',
        SelectedAgentTagsChanged: 'Вибрані теги агента змінено',
        AssignedAgentsGroupsChanged: 'Назначені групи агентів зміненно',
        TeamLeadChanged: 'Змінився лідер команди',
        AgentRoleChanged: 'Роль агента змінена',
        AgentInfoChanged: 'Інформація про агента змінена',
    },
    Errors: {
        PasswordMatching: 'Паролі не співпадають',
        IncorrectEmail: 'Введіть дійсну електронну адресу',
        IncorrectPhone: 'Введіть дійсний номер телефону',
        IncorrectUrl: 'Введіть дійсну URL адресу',
        FieldContainSpaces: 'Поле містить пробіли',
        FieldIsRequired: 'Поле обов\'язкове для заповнення',
        NameValidation: 'Довжина поля має бути від 2 до 40 символів включно.',
        NumberValidation: 'Поле має містити лише цифри',
        PasswordValidation: 'Пароль повинен містити літери та цифри і не може містити пробіли.',
        InvoiceNumberValidation: 'Номер рахунку повинен містити латинські літери, цифри та спецсимволи',
        MinimumLength: 'Мінімальна довжина {0} символів',
        MaximumLength: 'Максимальна довжина {0} символів',
        ServerError: 'Помилка сервера, спробуйте пізніше!',
        ServerErrorFileDownloadTimeOut: 'Помилка сервера, час завантаження файлу минув, спробуйте пізніше!',
        NotValidEmailOrPassword: 'Недійсна адреса електронної пошти або пароль',
        UserWasNotFound: 'Користувач не знайдений!',
        UserIsAlreadyExist: 'Користувач вже існує!',
        AgentWasBanned: 'Агент був забанений',
        TokenExpired: 'Час сесії минув. Будь ласка, перезайдіть',
        TelegramIdIsIncorrect: 'Невірний ID Телеграма',
        ErrorWrongFileFormat: 'Помилка, неправильний формат файлу!',
        FileSizeExceededWarnings: 'Розмір файлу перевищує попередження!',
        BrandWithSuchNameIsAlreadyExisting: 'Бренд з такою назвою вже існує!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Ви вже вибрали фільтр',
        AgentCreate: 'Ви новий агент у системі AskMe. Зверніть увагу, що ви можете увійти в систему лише після того, як вам буде призначено роль адміністратора.',
        NoStatisticsForTheAgent: 'Немає статистичних даних щодо агента за вибраний період часу',
        FieldIsRequired: 'Поле обов\'язкове для заповнення',
        ChannelMustContainAtLeastOneLanguage: 'Канал повинен містити хоча б одну мову',
    },
    Info: {
        Copied: 'скопійовано',
        PhraseBotNameAndGreetingsChanged: 'Фрази, ім\'я бота та привітання змінені',
    },
    Confirm: {},
    Message: {
        Welcome: 'Ласкаво просимо до системи AskMe',
        SignIn: 'Увійдіть у панель адміністратора або перейдіть на ваш ',
        SignUp: 'Зареєструйтесь на панелі адміністратора або перейдіть на ваш ',
        Register: 'Нема облікового запису?',
        Login: 'Вже є обліковий запис?',
        Logout: 'Ви справді хочете вийти?',
        ForgotPassword: 'Забули пароль?',
        ExcelFilesDownloading: 'Завантаження файлів Excel',
        excelFileIs: 'файл excel',
        Created: 'створюється',
        Creating: 'створює',
    },
    Accordions: {
        MailInboxes: 'Електронна пошта',
        TelegramAccounts: 'Telegram аккаунти',
        SmsAccounts: 'SMS акаунти',
    },
    Controls: {
        Newbie: 'Новачок',
        Name: 'Ім\'я',
        Email: 'Електронна пошта',
        Password: 'Пароль',
        RepeatPassword: 'Повторіть пароль',
        Register: 'Реєстрація',
        Login: 'Вхід',
        Or: 'Або',
        SignUp: 'Реєстрація',
        Send: 'Відправити',
        SearchFilter: 'Фільтр пошуку',
        Search: 'Пошук',
        SearchMessengers: 'Пошук месенджерів',
        SearchAgent: 'Пошук агента',
        SearchLanguage: 'Пошук мови',
        SearchChannel: 'Пошук каналу',
        SearchTag: 'Пошук тега',
        SearchStatus: 'Пошук статусу',
        SearchBrand: 'Пошук бренду',
        SearchWhiteListTags: 'Пошук дозволених тегів',
        SearchSessionStatus: 'Пошук статусу сеансу',
        SearchAccessLevel: 'Пошук рівнів доступу',
        SearchHasActiveDialogs: 'Пошук наявності активних діалогів',
        GroupName: 'Назва групи*',
        Description: 'Опис*',
        LinkAlias: 'Псевдонім посилання*',
        CategoryName: 'Ім\'я категорії *',
        BrandName: 'Ім\'я бренду',
        AccessKey: 'Ключ доступу',
        ClientName: 'Ім\'я користувача клієнта',
        ClientFirstName: 'Ім\'я клієнта',
        ClientLastName: 'Прізвище клієнта',
        ClientPhone: 'Телефон клієнта',
        ClientEmail: 'Електронна пошта клієнта',
        PleaseWrite: 'Вкажіть',
        MailInbox: 'Електронна пошта',
        TelegramInbox: 'Аккаунт Telegram',
        SmsInbox: 'Акаунт SMS',
        ApplicationID: 'ID програми',
        ApplicationToken: 'Токен програми',
        SenderID: 'ID відправника',
        ChangeName: 'Змінити ім\'я',
        ChangeURL: 'Змінити URL',
        TagName: 'Назва тега',
        LinkUrl: 'URL-адреса посилання',
        Greetings: 'Вітання',
        FastPhrase: 'Швидка фраза №',
        ClickOnPaperclip: 'Клацніть по іконці скріпки для вибору файлу',
        UserName: 'Ім\'я користувача',
        Domain: 'Домен*',
        Imap: 'Imap (хост: порт) *',
        SmtpTLS: 'Smtp TLS (хост:порт)*',
        SmtpSSL: 'Smtp SSL (хост:порт)*',
        AttachFile: 'Прикріпити файл',
        NoData: 'немає даних',
        NoRating: 'немає рейтингу',
        IfYouWantFinishDialog: 'Якщо ви хочете завершити діалог, натисніть кнопку ОК',
        AllDialogsAreVisibleToAllAgents: 'Усі діалоги видно для всіх агентів',
        DialogueIsAssignedAutomatically: 'Діалог автоматично прив\'язаний до агента, у якого найменше діалогів',
        AllDialogsAreVisibleToSeveralAgents: 'Діалог видно для агентів, у яких найменше діалогів',
        On: 'Увімк',
        Off: 'Викл',
        FirstName: 'Ім\'я',
        InvoiceNumber: 'Номер рахунку',
        LastName: 'Прізвище',
        SMS: 'СМС',
        New: 'Новий',
        Active: 'Активний',
        Finished: 'Завершений',
        Assigned: 'Призначений',
        Online: 'В мережі',
        Offline: 'Поза мережею',
        Break: 'Перерва',
        Low: 'Низький',
        Medium: 'Середній',
        High: 'Високий',
        True: 'Правда',
        False: 'Брехня',
        Date: 'Дата',
        AgentName: 'Ім\'я агента',
        Messenger: 'Месенджер',
        Language: 'Мова',
        Channel: 'Канал',
        Tag: 'Тег',
        Status: 'Статус',
        Brand: 'Бренд',
        AccessLevel: 'Рівень доступу',
        WhiteListTags: 'Теги білого списку',
        SessionStatus: 'Статус сесії',
        LastTags: 'Останні теги',
        HasActiveDialogs: 'Має активні діалоги',
        ChannelName: 'Ім\'я каналу',
        WriteYourFirstName: '* Будь ласка, введіть своє ім\'я',
        WriteYourLastName: '* Будь ласка, введіть своє прізвище',
        WriteYourUserName: '* Будь ласка, введіть своє ім\'я користувача',
        WriteYourPhoneNumber: '* Будь ласка, введіть свій номер телефону',
        WriteYourEmail: '* Будь ласка, введіть свою електронну пошту',
        WriteYourInvoiceNumber: '* Будь ласка, введіть свій номер рахунку',
        WriteYourAccountNumber: '*Будь ласка, введіть свій номер облікового запису',
        BotName: 'Ім\'я бота',
        Character: 'літера',
        Number: 'число',
        IdExample: 'Приклад ID',
        XCharecter: 'X-charecter',
        YNumber: 'Y-number',
        ThisOperationCanTakeLongTime: 'Операція може тривати довгий час!',
        ClickTiSaveExcel: 'Клацніть, щоб зберегти в Excel',
        PrivacyPolicyText: 'Текст політики приватності',
        PrivacyPolicyLink: 'Посилання політики приватності',
        PrivacyPolicyLinkText: 'Текст посилання політики приватності',
        AgentPhone: 'Телефон агента',
        HeaderTextTop: 'Текст верхньої частини шапки',
        HeaderTextBottom: 'Текст нижньої частини шапки',
        LogoIconUrl: 'URL іконки логотипу',
        IconUrl: 'URL іконки',
        ChatWindowsLogo: 'Логотип вікна чату',
        GreetingIconBotUrl: 'URL іконки привітання бота',
        AgentMessageIconUrl: 'URL адреса повідомлення агента',
        WriteMessagesPlaceholder: 'Прототип поля написання повідомлення',
        SendImageUrl: 'URL картинки відправки',
        ChannelURL: 'URL канала',
    },
    Selects: {
        SelectAccessLevel: 'Виберіть рівень доступу*',
        SelectChannel: 'Виберіть канал*',
        SelectChannels: 'Виберіть канали',
        SelectWhiteTagsList: 'Виберіть доступні теги*',
        SelectAgents: 'Виберіть агентів',
        SelectBrand: 'Виберіть бренд',
        SelectGroups: 'Виберіть групи',
        SelectTags: 'Виберіть теги',
        ChooseProviderCountry: 'Виберіть країну провайдера',
        SelectMessengers: 'Виберіть месенджери',
        SelectTagStatus: 'Виберіть статус тега',
        SelectAgent: 'Виберіть агента',
        SelectEnabledLanguages: 'Виберіть доступну мову',
        ChannelName: 'Ім\'я каналу',
        ChannelURL: 'URL канала',
        SelectLanguageWebChat: 'Виберіть мову для Web Chat',
    },
    Labels: {
        NumberOfNewAgents: 'Кількість нових агентів',
        TextForBulkMessaging: 'Текст для масових повідомлень',
        Name: 'Ім\'я',
        LastName: 'Прізвище',
        Phone: 'Телефон',
        GroupName: 'Назва групи*',
        SelectBrand: 'Виберіть бренд',
        Channel: 'Канал*',
        Description: 'Опис*',
        AccessLevel: 'Рівень доступу*',
        WhiteTagsList: 'Список доступних тегів*',
        BrandName: 'Назва бренду',
        CategoryName: 'Назва категорії*',
        ChooseProviderCountry: 'Виберіть країну провайдера',
        AccessKey: 'Ключ доступу',
        SelectChannels: 'Виберіть канали',
        SelectGroups: 'Виберіть групи',
        SelectAgents: 'Виберіть агентів',
        SelectTags: 'Виберіть теги',
        SelectAccessLevel: 'Виберіть рівень доступу*',
        Password: 'Пароль',
        Login: 'Вхід',
        SelectMessengers: 'Виберіть месенджери',
        UserName: 'Ім\'я користувача',
        ClientName: 'Ім\'я користувача клієнта',
        ClientFirstName: 'Ім\'я клієнта',
        ClientLastName: 'Прізвище клієнта',
        ClientPhone: 'Телефон клієнта',
        ClientEmail: 'Електронна пошта клієнта',
        ClientInfo: 'Клієнтська інформація',
        UpdateGroup: 'Зміна групи',
        Agents: 'Агенти',
        UsefulPhrases: 'Корисні фрази',
        Channels: 'Канали',
        Tags: 'Теги',
        AddNewPhrases: 'Додати нові фрази',
        Phrase: 'Фрази',
        SelectCategory: 'Оберіть категорію',
        EditCategory: 'Редагувати категорію',
        EditPhrase: 'Редагувати фразу',
        Or: 'або',
        AddNewCategory: 'Додати нову категорію',
        Dialogs: 'Діалоги',
        TotalDuration: 'Загальна тривалість',
        Email: 'Електронна пошта',
        ClientId: 'Клієнтська ID',
        ClientBanType: 'Тип бана клієнта',
        ClientBanTime: 'Термін бана клієнта',
        Messengers: 'Месенджери',
        IpAddress: 'IP адреса',
        ExternalID: 'Зовнішній ID',
        UpdateBrand: 'Змінити бренд',
        CreateMailInbox: 'Створити обліковий запис електронної пошти',
        CreateTelegramInbox: 'Створити обліковий запис Telegram',
        CreateSmsInbox: 'Створити SMS аккаунт',
        MailInbox: 'Електронна пошта',
        TelegramInbox: 'Аккаунт Telegram',
        SmsInbox: 'Акаунт SMS',
        ApplicationID: 'ID програми',
        ApplicationToken: 'Токен програми',
        SenderID: 'ID відправника',
        ChangeName: 'Змінити ім\'я',
        ChangeURL: 'Змінити URL',
        TagName: 'Назва тега',
        SelectTagStatus: 'Вибрати статус тега',
        SelectAgent: 'Виберіть агента',
        UpdateTag: 'Змінити тег',
        CreateTag: 'Створити тэг',
        UsefulLinks: 'Корисні посилання',
        LinkAlias: 'Псевдонім посилання',
        LinkUrl: 'URL-адреса посилання',
        SelectEnabledLanguages: 'Виберіть доступну мову',
        Greetings: 'Вітання',
        FastPhrase: 'Швидка фраза №',
        ClientHistory: 'Історія клієнта',
        ChooseRequiredFields: 'Будь ласка, виберіть обов\'язкові поля',
        GetAllDialogs: 'Отримати всі діалоги',
        GetCurrentDialog: 'Отримати поточний діалог',
        SaveInLocalStorage: 'Зберегти у LocalStorage',
        SaveInSessionStorage: 'Зберегти у SessionStorage',
        InvoiceNumber: 'Номер рахунку',
        AccountNumber: 'Номер облікового запису',
        General: 'Основні',
        Authorization: 'Авторизація',
        Languages: 'Мови',
        New: 'Новий',
        Active: 'Активний',
        Inactive: 'Чи не активний',
        WaitForActivation: 'Очікує активацію',
        ID: 'ID',
        SMS: 'СМС',
        Low: 'Низький',
        Medium: 'Середній',
        High: 'Високий',
        Hidden: 'Прихований',
        Brand: 'Бренд',
        Group: 'Група',
        CreateGroups: 'Створюйте группу',
        Roles: 'Ролі',
        ChangeStatus: 'Змінити статус',
        AuthMail: 'Авторизація пошти',
        SetDomainSettings: 'Встановити налаштування домену',
        Domain: 'Домен*',
        Imap: 'Imap (хост:порт)*',
        SmtpTLS: 'Smtp TLS (хост:порт)*',
        SmtpSSL: 'Smtp SSL (хост:порт)*',
        AttachFile: 'Прикріпити файл',
        Groups: 'Групи',
        TeamLead: 'Глава команди',
        Ban: 'Бан',
        ChannelSettings: 'Налаштування каналу',
        GeneralSettings: 'Основні налаштування',
        WebChatConstructor: 'Web Chat конструктор',
        BackgroundColor: 'Колір фону',
        MessageColor: 'Колір повідомлення',
        URL: 'URL',
        ChannelName: 'Назва каналу',
        ChannelURL: 'URL канала',
        AllowSMS: 'Дозволити SMS',
        AllowShowAllClientDialogs: 'Дозволити показувати всі діалоги клієнта',
        AllowCaptcha: 'Дозволити Captcha',
        InboxSettings: 'Налаштування розділу вхідних',
        DistributionOfNewDialogs: 'Розподіл нових даілогів між агентами',
        ThereAreNoAvailableMessengers: 'Немає дсотупних месенджерів для каналу! Будь ласка, створіть їх для бренду, якому належить канал!',
        HeaderColorScheme: 'Схема кольору верхньої частини',
        GreetingColorScheme: 'Схема кольору привітання',
        QuickPhrases: 'Схема кольорів швидких фраз',
        ClientMessage: 'Схема кольору повідомлення клієнта',
        AgentMessage: 'Схема кольору повідомлення агента',
        EndDialogModal: 'Схема кольору модального вікна для кінця діалогу',
        IconColorScheme: 'Схема кольору іконок',
        ScrollColorScheme: 'Схема кольору скроллу',
        ChatWindowColor: 'Схема кольорів вікна чату',
        FooterColorScheme: 'Схема кольору нижньої частини',
        AuthColorScheme: 'Схема кольору автентифікації',
        HeaderBackground: 'Заднє фон верхньої частини',
        HeaderBorderColor: 'Колір меж верхньої частини',
        ColorGreetingBotName: 'Цвет имени бота приветствия',
        MessageGreetingIcon: 'Привітання заднього фону ікон.',
        MessageGreetingColor: 'Колір тексту привітання',
        MessageGreetingBorderColor: 'Колір кордонів для привітання',
        MessageGreetingBackgroundColor: 'Колір заднього фону повідомлення вітання',
        QuickPhrasesColor: 'Колір тексту швидких фраз',
        QuickPhrasesBackground: 'Задній фон швидких фраз',
        QuickPhrasesBorderColor: 'Колір меж швидких фраз',
        QuickPhrasesColorHover: 'Колір при наведенні на швидкі фрази',
        QuickPhrasesBackgroundHover: 'Колір заднього фону при наведенні на швидкі фрази',
        QuickPhrasesBorderColorHover: 'Колір кордонів при наведенні на швидкі фрази',
        ClientMessageColor: 'Колір повідомлення клієнта',
        ClientTimeColor: 'Колір часу клієнта',
        ClientMessageBackground: 'Колір заднього фону повідомлення клієнта',
        ClientMessageBorderColor: 'Колір кордонів повідомлення клієнта',
        ClientLink: 'Посилання клієнта',
        ClientLinkHover: 'Колір під час наведення на посилання клієнта',
        ClientActiveLink: 'Активне посилання клієнта',
        ClientVisitedLink: 'Відвідане посилання клієнта',
        ColorAgentNameAndTime: 'Колір імені та часу агента',
        MessageAgentColor: 'Колір повідомлень агента',
        MessageAgentBackgroundColor: 'Задній фон повідомлення агента',
        MessageAgentBorderColor: 'Колір кордонів повідомлення агента',
        AgentLink: 'Посилання агента',
        AgentHoverLink: 'Колір під час наведення на посилання агента',
        AgentActiveLink: 'Активне посилання агента',
        AgentVisitedLink: 'Відвідане посилання агента',
        MessageIconBackgroundAgent: 'Задній фон іконки повідомлення агента',
        TextColor: 'Колір тексту',
        BackdropColor: 'Цвет фону',
        BackdropColorHover: 'Колір фону при наведенні',
        ButtonColor: 'Колір кнопки',
        BackdropColorBtn: 'Колір задній частині кнопки фону',
        IconBackground: 'Задній фон іконок',
        ChatWindowsBodyBackground: 'Колір заднього фону тіла вікон чату',
        ChatWindowsBorderColor: 'Колір кордонів вікон чату',
        FooterBackground: 'Колір заднього фону нижній частині',
        FooterBorderColor: 'Колір кордонів нижній частини',
        FooterBackgroundTextArea: 'Колір заднього фону текстової області нижньої частини',
        InputBorderColor: 'Колір меж введення',
        RegistrationBackgroundColor: 'Колір заднього фону реєстрації',
        LabelColor: 'Колір ярлика',
        LabelErrorColor: 'Колір ярлика помилки',
        InputColor: 'Колір введення',
        InputBackground: 'Задній фон введення',
        SignUpColor: 'Колір входу',
        SignUpBackground: 'Колір заднього фону входу',
        Chat: 'Чат',
        Minimized: 'Мінімізація',
        ScrollBackground: 'Задній фон скролла',
        ScrollThumbBackground: 'Задній фон бігунка скролла',
        UniqueClientIdentifier: 'Унікальний індифікатор клієнта',
        AgentsSettings: 'Налаштування агента',
        ThisAgentSuperAdmin: 'Цей агент є суперадміністратором, натисніть кнопку, щоб видалити права суперадміністратора',
        ThisAgentNotSuperAdmin: 'Цей агент не є суперадміністратором, натисніть кнопку, щоб зробити його суперадміністратором',
        YouCanAssignBrandTheBrand: 'Ви можете призначити бренд адміну для цього вам потрібно вибрати бренд',
        Role: 'Роль',
        BanningThisAgent: 'Заборона агента означає, що він не зможе увійти на платформу. Вони все одно з\'являться у системі.',
        Report: 'Звіт',
        SelectLanguageWebChat: 'Виберіть мову для Web Chat',
        FirstName: 'Ім\'я',
        PhoneNumber: 'Номер телефону',
        Info: 'Інфо',
        WebChatSettings: 'Налаштування Web Chat',
        IntegrationWithOtherCRM: 'Інтеграція з іншими CRM',
        SettingUpInteractionBetweenSystems: 'Налагодження взаємодії між системами',
        QuickReplies: 'Швидкі відповіді',
        BotSettings: 'Налаштування бота',
        BotName: 'Ім\'я бота',
        EnabledPrivacyPolicy: 'Дозволити політику конфіденційності',
        PleaseChooseRequiredFields: 'Будь ласка, виберіть обов\'язкові поля',
        ChangeGreetingsAndPhrases: 'Змінити привітання, фрази та політику конфіденційності',
        ChangeSmsSettings: 'Змінити налаштування СМС',
        ChangeCountries: 'Змінити країни',
        ContactsAreWorkingWithClientUniqueness: 'Контакти залежать від унікальності клієнта. Поле обов\'язково у разі, якщо унікальність збігається з ним.',
        ClientUniquenessIs: 'Унікальність клієнта це:',
        PrivacyPolicyText: 'Текст політики приватності',
        PrivacyPolicyLink: 'Посилання політики приватності',
        PrivacyPolicyLinkText: 'Текст посилання політики приватності',
        AgentPhone: 'Телефон агента',
        HeaderSettings: 'Налаштування заголовка',
        DisableDraggable: 'Вимкнути перетягування',
        DisableHeaderFullScreen: 'Вимкнути повноекранний заголовок',
        DisableHeaderHideChatWindow: 'Вимкнути приховування вікна чату в заголовку',
        DisableHeaderLogo: 'Вимкнути логотип заголовка',
        DisableHeaderShowEndDialogModal: 'Вимкнути відображення заголовка наприкінці модального вікна',
        HeaderText: 'Текст заголовка',
        HeaderTextTop: 'Текст верхньої частини заголовка',
        HeaderTextBottom: 'Текст нижньої частини заголовка',
        HeaderHeight: 'Висота заголовка',
        LogoIconUrl: 'URL іконки логотипу',
        IconUrl: 'URL іконки',
        IconSettings: 'Налаштування іконки',
        ScrollSettings: 'Налаштування скролла',
        ScrollWidth: 'Ширина скролла',
        ChatWindowsLogo: 'Лого вікна чату',
        BackgroundImageSize: 'Розмір зображення заднього фону',
        GreetingSettings: 'Налаштування привітання',
        GreetingIconBotUrl: 'URL іконки привітання бота',
        QuickPhrasesSettings: 'Налаштування швидких фраз',
        ClientMessageSettings: 'Налаштування повідомлень клієнта',
        AgentMessageSettings: 'Налаштування повідомлень агента',
        AgentMessageIconUrl: 'URL-адреса повідомлення агента',
        EndDialogModalSettings: 'Налаштування модального вікна закінчення діалогу',
        AuthWindowSettings: 'Налаштування вікна автентифікації',
        FooterSettings: 'Налаштування нижнього колонтитулу',
        WriteMessagesPlaceholder: 'Прототип поля написання повідомлення',
        SendImageUrl: 'URL картинки відправки',
        SendButtonIconHeight: 'Висота іконки кнопки надсилання',
        SendButtonIconWidth: 'Ширина значок кнопки надсилання',
        ChatWindowSettings: 'Налаштування вікна чату',
        ClearAllPhrase: 'Очистить всі фрази',
        AreYouSure: 'Ви впевненні?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Ця дія видаляє всі фрази та всі категорії',
        AddAgentToGroup: 'Додати агентів до групи',
        CreateClient: 'Створити клієнта',
        ClientsImport: 'Імпорт клієнтів',
        UploadExcelFile: 'Завантажте файл Excel',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Натисніть на піктограму скріпки, щоб вибрати файл Excel',
        Contacts: 'Контакти',
        Personal: 'Персональні',
        FileUploadHint: 'Будь ласка, завантажте файл Excel із номерами телефонів. Файл Excel має містити стовпець з назвою «Телефони», і в цей стовпець потрібно ввести номери телефонів. Можна імпортувати лише файли .xls або .xlsx.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Змінити вітання та бистрі фрази, Політика конфіденційності',
    },
    AdditionalText: {
        AgentProfile: 'Профіль Агента',
        OutOf: 'з',
    },
    Pages: {
        Dialogs: {
            Title: 'Діалоги',
            TableColumns: {
                Status: 'Статус',
                Dialog: 'Діалог',
                Messenger: 'Месенджер',
                Channel: 'Канал',
                Tag: 'Тег',
                Agent: 'Агент',
                Rating: 'Рейтинг',
                Language: 'Мова',
                MessagesCount: 'Кількість повідомлень',
                FirstMessage: 'Перше повідомлення',
                LastActivity: 'Остання відвідуваність',
            },
            Messages: 'Повідомлення',
        },
        AgentGroups: {
            Title: 'Групи агентів',
            TableColumns: {
                Groups: 'Групи',
                Description: 'Опис',
                AccessLevel: 'Рівень доступу',
                WhiteListTags: 'Доступні теги',
                CreatedAt: 'Створено в',
                UpdatedAt: 'Оновленно в',
            },
        },
        Agents: {
            Title: 'Агенти',
            TableColumns: {
                AgentStatus: 'Статус агента',
                Agents: 'Агенти',
                Email: 'Пошта',
                Groups: 'Групи',
                CreatedAt: 'Створено в',
                LastActivity: 'Остання відвідуваність',
            },
        },
        Tags: {
            Title: 'Теги',
            TableColumns: {
                Tags: 'Теги',
                Dialogs: 'Діалоги',
                Agents: 'Агенти',
                UsefulLinks: 'Корисні фрази',
                Status: 'Статус',
                CreatedAt: 'Створено в',
                UpdatedAt: 'Оновленно в',
            },
        },
        Clients: {
            Title: 'Клієнти',
            TableColumns: {
                SearchName: 'ім\'я',
                AgentName: 'Ім\'я Агента',
                Messenger: 'Месенджер',
                LastTag: 'Останній тег',
                ActiveDialog: 'Наявність активного діалогу',
                Brand: 'Бренд',
                InternalId: 'Внутрішній ID',
                ExternalId: 'Зовнішній ID',
                Name: 'Ім\'я користувача',
                FirstName: 'Ім\'я клієнта',
                LastName: 'Прізвище клієнта',
                Email: 'Пошта',
                InvoiceNumber: 'Номер рахунку',
                DialogsCount: 'Кількість діалогів',
                FirstMessage: 'Перше повідомлення',
                LastActivity: 'Остання відвідуваність',
                Tag: 'Тег',
                Status: 'Статус',
                Channel: 'Канал',
                WriteEmail: 'пошту',
            },
        },
        Channels: {
            Title: 'Канали',
            TableColumns: {
                ChannelId: 'Id',
                ChannelKey: 'Ключ',
                ChannelName: 'Канал',
                Language: 'Мова',
                Url: 'Посилання',
                CreatedAt: 'Створено в',
                UpdatedAt: 'Оновленно в',
            },
        },
        Brands: {
            Title: 'Бренди',
            TableColumns: {
                BrandsId: 'id бренда',
                BrandName: 'Ім\'я бренда',
                ClientUniqueness: 'Унікальний ключ клієнта',
                BrandChannels: 'Канали бренду',
                DialogCount: 'Кількість діалогів',
                CreatedAt: 'Створено в',
                UpdatedAt: 'Оновленно в',
            },
        },
        Reports: {
            Title: 'Звіти',
            ByMessengersTab: {
                Title: 'Звіт з месенджерів',
                Columns: {
                    Messenger: 'Месенджер',
                    ReceivedDialogs: 'Отримані діалоги',
                    PositiveDialogs: 'Позитивні діалоги, %',
                    NegativeDialogs: 'Негативні діалоги, %',
                    UnratedDialogs: 'Діалоги без рейтингу, %',
                    AvgResponseTime: 'Середній час відповіді',
                },
            },
            ByAgentsTab: {
                Title: 'Звіт агентів',
                Columns: {
                    agentFullName: 'ПІБ агента',
                    agentSystemId: 'ID системи агента',
                    brandName: 'Ім\'я бренду',
                    receivedDialogs: 'Отримані діалоги, %',
                    dialogsTelegram: 'Діалоги Telegram',
                    dialogsWebChat: 'Діалоги Web Chat',
                    dialogsViber: 'Діалоги Viber',
                    dialogsWhatsApp: 'Діалоги WhatsApp',
                    dialogsFacebook: 'Діалоги Facebook',
                    dialogsEmail: 'Діалоги Email',
                    dialogsSms: 'Діалоги SMS',
                    positiveDialogs: 'Позитивні діалоги, %',
                    negativeDialogs: 'Негативні діалоги, %',
                    unratedDialogs: 'Діалоги без рейтингу, %',
                    avgResponseTime: 'Середній час відповіді',
                },
                noDataForThisDay: 'Немає даних за цей день',
            },
            ByGroupsTab: {
                Title: 'Звіт по групам',
                Columns: {
                    groupSystemId: 'Системний ідентифікатор групи',
                    groupName: 'Ім\'я групи',
                    brandName: 'Ім\'я бренда',
                    receivedDialogs: 'Полученные диалоги, %',
                    positiveDialogs: 'Позитивні діалоги, %',
                    negativeDialogs: 'Негативні діалоги, %',
                    unratedDialogs: 'Діалоги без рейтингу, %',
                    avgResponseTime: 'Середній час відповіді',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Фільтр',
        reset: 'Скидання',
        createClient: 'Створити клієнта',
        importClient: 'Імпортувати клієнтів',
        createChannel: 'Створити канал',
        next: 'Наступний',
        previous: 'Попередній',
        addPhrase: 'Додати фразу',
        createBrand: 'Створити бренд',
        addTag: 'Створити тэг',
        addGroup: 'Створити группу',
        save: 'Зберегти',
        send: 'Відправити',
        getStatistics: 'Отримати статистику',
        setCountries: 'Вибрати країни',
        changeStatus: 'Змінити статус',
        upload: 'Завантажити',
        setDomainSettings: 'Встановити налаштування домену',
        login: 'Вхід',
        update: 'Оновити',
        resetPassword: 'Скинути пароль',
        addNew: 'Додати',
        addNewPhrase: 'Додати нову фразу',
        addNewCategory: 'Додати нову категорію',
        clearAll: 'Очистити Все',
        loginWithGoogle: 'Увійти через Google',
        loginWithFacebook: 'Увійти через Facebook',
        onPage: 'на сторінці',
        addNewUsefulLinks: 'Додати корисні посилання',
        backToChannels: 'Вернуться к каналам',
        defaultSettings: 'Скинути налаштування',
        upgradeChannel: 'Оновити канал',
        apply: 'Прийняти',
        remove: 'Прибрати',
        removeSuperAdmin: 'Видалити суперадміністратора',
        makeSuperAdmin: 'Зробити суперадміністратором',
        makeBrandAdmin: 'Зробити адміністратором бренду',
        removeBrandAdmin: 'Видалити адміністратора бренду',
        ban: 'Забанити',
        unban: 'Розбанити',
        makeTeamLead: 'Зробити тимлідом',
        removeTeamLead: 'Видалити роль тимліда',
        signUp: 'Увійти',
        ok: 'Ok',
        cancel: 'Скасувати',
        bulkMessaging: 'Масові повідомлення',
        back: 'Назад',
    },
    Countries: {
        UnitedStates: 'Сполучені Штати Америки',
        Canada: 'Канада',
        Australia: 'Австралія',
        CzechRepublic: 'Чехія',
        Denmark: 'Данія',
        Hungary: 'Угорщина',
        Netherlands: 'Нідерланди',
        Sweden: 'Швеція',
        UnitedKingdom: 'Великобританія',
        France: 'Франція',
        Germany: 'Німеччина',
        Poland: 'Польща',
        Italy: 'Італія',
        Spain: 'Іспанія',
        Ukraine: 'Україна',
    },
    Languages: {
        Ukrainian: 'Українська',
        Russian: 'Російська',
        English: 'Англійська',
        Polish: 'Польський',
        Deutsch: 'Німецька',
        Spanish: 'Іспанська',
        Vietnamese: 'В\'єтнамська',
        Portuguese: 'Португальська',
        Korean: 'Корейська',
        Italian: 'Італійська',
        Arabic: 'Арабська',
        French: 'Французька',
        Azerbaijan: 'Азербайджанський',
        Hungarian: 'Угорський',
    },
    BulkMessaging: {
        exclude: 'Виключити',
        typeText: 'Введіть текст',
        selectClients: 'Виберіть клієнтів',
        uploadClients: 'Завантажте клієнтів',
        sendingMessagesToNewClients: 'Надсилання повідомлень новим клієнтам',
        sendingMessagesToExistingClients: 'Відправка повідомлень існуючим клієнтам',
        clientsWhoHaveDialogsWithFollowingStatus: 'Клієнти, які мають діалоги з наступним статусом',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Клієнти, які мають цей текст в імені чи прізвищи чи імені користувача',
        limitQuantity: 'Обмежити кількість',
        quantityLimit: 'Обмеження кількості',
        limitQuantityText: 'Обмежте кількість у трансляції не більше, ніж зазначена кількість одержувачів. До списку потраплять новіші клієнти системи.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Клієнти, які написали в AskMe до останнього повідомлення',
    },
};
export default Messages;
