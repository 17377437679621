var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { arrayToString } from '@src/utils/validations';
import { Messages } from '@public/locales/messages';
export var mapChannelData = function (channels) { return channels.map(function (channel, index) { return (__assign(__assign({}, channel), { name: channel.name || Messages.Controls.NoData, enabledLang: arrayToString(channel.enabledLang) || Messages.Controls.NoData, url: channel.url || Messages.Controls.NoData, id: channel.id || Messages.Controls.NoData, key: channel.key || Messages.Controls.NoData, index: ++index || Messages.Controls.NoData, createdAt: channel.createdAt || Messages.Controls.NoData, updatedAt: channel.updatedAt || Messages.Controls.NoData, settings: channel.id })); }); };
