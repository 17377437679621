var Messages = {
    Success: {
        Success: 'Powodzenie',
        ForgotPasswordSend: 'Link do zmiany hasła został wysłany na Twój adres e-mail',
        ResetPasswordSuccess: 'Twoje hasło zostało pomyślnie zaktualizowane',
        BrandCreated: 'Marka stworzona!',
        MailInboxCreated: 'Utworzono skrzynkę pocztową!',
        PrivacyPolicyChanged: 'Polityka prywatności została zmieniona!',
        SmsInboxCreated: 'Utworzono skrzynkę sms!',
        InboxDeleted: 'Skrzynka odbiorcza usunięta!',
        StatusChanged: 'Zmieniono status!',
        FileLoaded: 'Plik załadowany!',
        AuthMail: 'Uwierzytelnij pocztę!',
        DomainSettings: 'Ustawienia domeny!',
        UpdatedSmsAccounts: 'Zaktualizowane konta SMS!',
        ChanelCreated: 'Kanał utworzony!',
        DeleteMessenger: 'Usuń Messengera!',
        AddMessenger: 'Dodaj Messengera!',
        ChangedMessenger: 'Zmieniony Posłaniec!',
        ClientCreated: 'Klient stworzony!',
        ClientsImports: 'Grupa utworzona!',
        GroupCreated: 'Grupa utworzona!',
        ChangedAssignedAgentsInGroups: 'Zmieniono przypisanych agentów w grupach!',
        ChangedAssignedChannelsInGroups: 'Zmieniono przypisane kanały w grupach!',
        ChangedAssignedTagsInGroups: 'Zmieniono przypisane tagi w grupach!',
        CreateNewCategory: 'Utwórz nową kategorię!',
        CreateNewPhrase: 'Utwórz nową frazę!',
        DeleteCategory: 'Usuń kategorię!',
        DeletePhrase: 'Usuń frazę!',
        UpdatePhrase: 'Zaktualizuj frazę!',
        EditCategory: 'Edytuj kategorię!',
        DeleteAllCategory: 'Usuń wszystkie kategorie!',
        TagCreated: 'Utworzono tag!',
        TagUpdated: 'Zaktualizowano tag!',
        TelegramInboxCreated: 'Utworzono skrzynkę telegramu!',
        HeaderTextChanged: 'Zmieniono tekst nagłówka',
        LogoIconUrlChanged: 'Zmieniono adres URL ikony logo',
        IconUrlChanged: 'Zmieniono adres URL ikony',
        BackgroundImageUrlChanged: 'Zmieniono adres URL obrazu tła',
        GreetingImageUrlChanged: 'Zmieniono adres URL obrazu powitania',
        AgentImageUrlChanged: 'Zmieniono adres URL obrazu agenta',
        InputPlaceholderChanged: 'Zmieniono symbol zastępczy danych wejściowych',
        SendImageUrlChanged: 'Zmieniono adres URL wysyłania obrazu',
        AgentStatusChanged: 'Zmieniono status agenta',
        SelectedAgentTagsChanged: 'Zmieniono wybrane tagi agenta',
        AssignedAgentsGroupsChanged: 'Zmieniono przypisane grupy agentów',
        TeamLeadChanged: 'Zmieniony lider zespołu',
        AgentRoleChanged: 'Zmieniono rolę agenta',
        AgentInfoChanged: 'Zmieniono informacje o agencie',
    },
    Errors: {
        PasswordMatching: 'Hasła nie pasują do siebie!',
        IncorrectEmail: 'Email jest niepoprawny',
        IncorrectPhone: 'Telefon jest nieprawidłowy',
        IncorrectUrl: 'URL jest nieprawidłowy',
        FieldContainSpaces: 'Pole zawiera spacje',
        FieldIsRequired: 'To pole jest wymagane',
        NameValidation: 'Długość pola musi wynosić od 2 do 40 znaków włącznie',
        NumberValidation: 'Pole musi zawierać tylko cyfry',
        PasswordValidation: 'Hasło musi zawierać litery i cyfry oraz nie może zawierać spacji',
        InvoiceNumberValidation: 'Numer faktury musi zawierać litery łacińskie, cyfry i znaki specjalne',
        MinimumLength: 'Minimalna długość {0} symboli',
        MaximumLength: 'Maksymalna długość {0} symboli',
        ServerError: 'Błąd serwera, spróbuj później!',
        ServerErrorFileDownloadTimeOut: 'Błąd serwera, upłynął limit czasu pobierania pliku, spróbuj później!',
        NotValidEmailOrPassword: 'Nieprawidłowy adres e-mail lub hasło',
        UserWasNotFound: 'Nie znaleziono użytkownika!',
        UserIsAlreadyExist: 'Użytkownik już istnieje!',
        AgentWasBanned: 'Agent został zbanowany',
        TokenExpired: 'Sesja wygasła. Zaloguj się ponownie',
        TelegramIdIsIncorrect: 'Identyfikator telegramu jest nieprawidłowy',
        ErrorWrongFileFormat: 'Błąd, zły format pliku!',
        FileSizeExceededWarnings: 'Rozmiar pliku przekroczył ostrzeżenia!',
        BrandWithSuchNameIsAlreadyExisting: 'Marka o takiej nazwie już istnieje!',
    },
    Warning: {
        YouHaveAlreadySelectedAFilter: 'Wybrałeś już filtr',
        AgentCreate: 'Jesteś nowym agentem w systemie AskMe. Pamiętaj, że możesz zalogować się dopiero po przypisaniu roli administratora!',
        NoStatisticsForTheAgent: 'Brak danych statystycznych dla agenta dla wybranego okresu',
        FieldIsRequired: 'To pole jest wymagane',
        ChannelMustContainAtLeastOneLanguage: 'Kanał musi zawierać co najmniej jeden język',
    },
    Info: {
        Copied: 'skopiowane',
        PhraseBotNameAndGreetingsChanged: 'Zmieniono frazy, nazwę bota i powitanie',
    },
    Confirm: {},
    Message: {
        Welcome: 'Witamy w AskMe',
        SignIn: 'Zaloguj się do Panelu Administracyjnego lub przejdź do swojego ',
        SignUp: 'Zarejestruj się w panelu administracyjnym lub przejdź do swojego ',
        Register: 'Nie masz u nas konta?',
        Login: 'Posiadasz już konto?',
        Logout: 'Czy naprawdę chcesz odejść?',
        ForgotPassword: 'Zapomniałeś hasła?',
        ExcelFilesDownloading: 'Pobieranie plików Excel',
        excelFileIs: 'plik Excel',
        Created: 'jest tworzone',
        Creating: 'tworzy',
    },
    Accordions: {
        MailInboxes: 'Skrzynki pocztowe',
        TelegramAccounts: 'Konta telegramu',
        SmsAccounts: 'Konta SMS',
    },
    Controls: {
        Newbie: 'Nowicjusz',
        Name: 'Nazwa',
        Email: 'Email',
        Password: 'Hasło',
        RepeatPassword: 'Powtórz hasło',
        Register: 'Zarejestrować',
        Login: 'Zaloguj sie',
        Or: 'LUB',
        SignUp: 'Zapisz się',
        Send: 'Wysłać',
        SearchFilter: 'Filtry wyszukiwania',
        Search: 'Szukaj',
        SearchMessengers: 'Szukaj komunikatorów',
        SearchAgent: 'Szukaj agenta',
        SearchLanguage: 'Wyszukaj język',
        SearchChannel: 'Wyszukaj kanał',
        SearchTag: 'Wyszukaj tag',
        SearchStatus: 'Stan wyszukiwania',
        SearchBrand: 'Szukaj marki',
        SearchWhiteListTags: 'Wyszukaj tagi białej listy',
        SearchSessionStatus: 'Wyszukaj stan sesji',
        SearchAccessLevel: 'Wyszukaj poziom dostępu',
        SearchHasActiveDialogs: 'Wyszukiwanie ma aktywny dialog',
        GroupName: 'Nazwa grupy*',
        Description: 'Opis*',
        LinkAlias: 'Alias łącza*',
        CategoryName: 'Nazwa Kategorii*',
        BrandName: 'Nazwa handlowa',
        AccessKey: 'Klucz dostępu',
        ClientName: 'Nazwa klienta',
        ClientFirstName: 'Imię klienta',
        ClientLastName: 'Nazwisko klienta',
        ClientPhone: 'Telefon klienta',
        ClientEmail: 'Adres e-mail klienta',
        PleaseWrite: 'Proszę napisz',
        MailInbox: 'Skrzynka pocztowa',
        SmsInbox: 'Skrzynka SMS',
        TelegramInbox: 'Skrzynka odbiorcza telegramu',
        ApplicationID: 'Identyfikator aplikacji',
        ApplicationToken: 'Token aplikacji',
        SenderID: 'Identyfikator nadawcy',
        ChangeName: 'Zmień nazwę',
        ChangeURL: 'Zmień adres URL',
        TagName: 'Nazwa znacznika',
        LinkUrl: 'URL linku*',
        Greetings: 'Pozdrowienia',
        FastPhrase: 'Szybka fraza №',
        ClickOnPaperclip: 'Kliknij ikonę spinacza, aby wybrać plik',
        UserName: 'Zaloguj sie',
        Domain: 'Domena*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (host:port)*',
        SmtpSSL: 'Smtp SSL (host:port)*',
        AttachFile: 'Załącz plik',
        NoData: 'brak danych',
        NoRating: 'brak oceny',
        IfYouWantFinishDialog: 'Jeśli chcesz zakończyć okno dialogowe, kliknij przycisk OK',
        AllDialogsAreVisibleToAllAgents: 'Wszystkie okna dialogowe są widoczne dla wszystkich agentów',
        DialogueIsAssignedAutomatically: 'Dialog jest przypisywany automatycznie do agenta, który ma najmniejszą liczbę dialogów',
        AllDialogsAreVisibleToSeveralAgents: 'Dialogi są widoczne dla kilku agentów z najmniejszą liczbą dialogów',
        On: 'Włączyć',
        Off: 'Wyłączony',
        FirstName: 'Imię',
        InvoiceNumber: 'Numer faktury',
        LastName: 'Nazwisko',
        SMS: 'SMS',
        New: 'Nowy',
        Active: 'Aktywny',
        Finished: 'Skończone',
        Assigned: 'Przydzielony',
        Online: 'Online',
        Offline: 'Offline',
        Break: 'Zepsuć',
        Low: 'Niski',
        Medium: 'Średni',
        High: 'Wysoka',
        True: 'Prawdziwe',
        False: 'Fałszywe',
        Date: 'Data',
        AgentName: 'Imię agenta',
        Messenger: 'Posłaniec',
        Language: 'Język',
        Channel: 'Kanał',
        Tag: 'Etykietka',
        Status: 'Status',
        Brand: 'Marka',
        AccessLevel: 'Poziom dostępu',
        WhiteListTags: 'Tagi białej listy',
        SessionStatus: 'Stan sesji',
        LastTags: 'Ostatnie tagi',
        HasActiveDialogs: 'Ma aktywne okna dialogowe',
        ChannelName: 'Nazwa kanału',
        WriteYourFirstName: '* Proszę napisz swoje imię',
        WriteYourLastName: '* Proszę wpisać swoje nazwisko',
        WriteYourUserName: '* Proszę wpisać nazwę użytkownika',
        WriteYourPhoneNumber: '* Proszę wpisać numer telefonu',
        WriteYourEmail: '* Proszę, napisz swój e-mail',
        WriteYourInvoiceNumber: '* Proszę wpisać numer faktury',
        WriteYourAccountNumber: '* Proszę wpisać swój numer konta',
        BotName: 'Nazwa bota',
        Character: 'postać',
        Number: 'numer',
        IdExample: 'Przykład identyfikatora',
        XCharecter: 'X-znak',
        YNumber: 'Numer Y',
        ThisOperationCanTakeLongTime: 'Ta operacja może zająć dużo czasu!',
        ClickTiSaveExcel: 'Kliknij, aby zapisać w programie Excel',
        PrivacyPolicyText: 'Tekst polityki prywatności',
        PrivacyPolicyLink: 'Link do polityki prywatności',
        PrivacyPolicyLinkText: 'Tekst linku do polityki prywatności',
        AgentPhone: 'Telefon agenta',
        HeaderTextTop: 'Tekst nagłówka u góry',
        HeaderTextBottom: 'Tekst nagłówka na dole',
        LogoIconUrl: 'Adres URL ikony logo',
        IconUrl: 'URL ikony',
        ChatWindowsLogo: 'Logo okien czatu',
        GreetingIconBotUrl: 'Ikona powitania bota Url',
        AgentMessageIconUrl: 'Adres URL ikony wiadomości agenta',
        WriteMessagesPlaceholder: 'Napisz wiadomość zastępczą',
        SendImageUrl: 'Wyślij adres URL obrazu',
        ChannelURL: 'Adres URL kanału',
    },
    Selects: {
        SelectAccessLevel: 'Wybierz poziom dostępu*',
        SelectChannel: 'Wybierz kanał*',
        SelectChannels: 'Wybierz kanały',
        SelectWhiteTagsList: 'Wybierz białą listę tagów*',
        SelectAgents: 'Wybierz agentów',
        SelectGroups: 'Wybierz grupy',
        SelectBrand: 'Wybierz markę',
        SelectTags: 'Wybierz tagi',
        ChooseProviderCountry: 'Wybierz kraj dostawcy',
        SelectMessengers: 'Wybierz posłańców',
        SelectTagStatus: 'Wybierz stan tagu',
        SelectAgent: 'Wybierz agenta',
        SelectEnabledLanguages: 'Wybierz włączone języki',
        ChannelName: 'Nazwa kanału',
        ChannelURL: 'Adres URL kanału',
        SelectLanguageWebChat: 'Wybierz języki Czat internetowy',
    },
    Labels: {
        NumberOfNewAgents: 'Liczba nowych agentów',
        TextForBulkMessaging: 'Tekst do wiadomości zbiorczych',
        Name: 'Nazwa',
        LastName: 'Nazwisko',
        Phone: 'Telefon',
        GroupName: 'Nazwa grupy*',
        SelectBrand: 'Wybierz markę',
        Channel: 'Kanał*',
        Description: 'Opis*',
        AccessLevel: 'Poziom dostępu*',
        WhiteTagsList: 'Biała lista tagów*',
        BrandName: 'Nazwa handlowa',
        CategoryName: 'Nazwa Kategorii*',
        ChooseProviderCountry: 'Wybierz kraj dostawcy',
        AccessKey: 'Klucz dostępu',
        SelectChannels: 'Wybierz kanały',
        SelectGroups: 'Wybierz grupy',
        SelectAgents: 'Wybierz agentów',
        SelectTags: 'Wybierz tagi',
        SelectAccessLevel: 'Wybierz poziom dostępu*',
        Password: 'Hasło*',
        Login: 'Zaloguj sie',
        SelectMessengers: 'Wybierz posłańców',
        UserName: 'Nazwa użytkownika',
        ClientName: 'Nazwa klienta',
        ClientFirstName: 'Imię klienta',
        ClientLastName: 'Nazwisko klienta',
        ClientPhone: 'Telefon klienta',
        ClientEmail: 'Adres email klienta',
        ClientInfo: 'Informacje o kliencie',
        UpdateGroup: 'Aktualizuj grupę',
        Agents: 'Agenci',
        UsefulPhrases: 'Przydatne sformułowania',
        Channels: 'Kanały',
        Tags: 'Tagi',
        AddNewPhrases: 'Dodaj nowe frazy',
        Phrase: 'Wyrażenie',
        SelectCategory: 'Wybierz kategorię',
        EditCategory: 'Edytuj kategorię',
        EditPhrase: 'Edytuj frazę',
        Or: 'lub',
        AddNewCategory: 'Dodaj nową kategorię',
        Dialogs: 'Dialogi',
        TotalDuration: 'Całkowity czas trwania',
        Email: 'Email',
        ClientId: 'Identyfikator klienta',
        ClientBanType: 'Typ bana klienta',
        ClientBanTime: 'Czas blokady klienta',
        Messengers: 'Posłańcy',
        IpAddress: 'adres IP',
        ExternalID: 'Identyfikator zewnętrzny',
        UpdateBrand: 'Zaktualizuj markę',
        CreateMailInbox: 'Utwórz konto skrzynki pocztowej',
        CreateTelegramInbox: 'Utwórz konto Telegrama',
        CreateSmsInbox: 'Utwórz konto SMS',
        MailInbox: 'Skrzynka pocztowa',
        TelegramInbox: 'Skrzynka telegram',
        SmsInbox: 'Skrzynka SMS',
        ApplicationID: 'Identyfikator aplikacji',
        ApplicationToken: 'Token aplikacji',
        SenderID: 'Identyfikator nadawcy',
        ChangeName: 'Zmień nazwę',
        ChangeURL: 'Zmień adres URL',
        TagName: 'Nazwa znacznika',
        SelectTagStatus: 'Wybierz stan tagu',
        SelectAgent: 'Wybierz agenta',
        UpdateTag: 'Aktualizuj tag',
        CreateTag: 'Utwórz tag',
        UsefulLinks: 'Przydatne linki',
        LinkAlias: 'Alias łącza',
        LinkUrl: 'URL linku',
        Roles: 'Role',
        Groups: 'Grupy',
        CreateGroups: 'Utwórz grupy',
        TeamLead: 'Zespół ołowiu',
        Ban: 'Ban',
        SelectEnabledLanguages: 'Wybierz włączone języki',
        Greetings: 'Pozdrowienia',
        FastPhrase: 'Szybka fraza №',
        ClientHistory: 'Historia klienta',
        ChooseRequiredFields: 'Proszę wybrać wymagane pola',
        GetAllDialogs: 'Pobierz wszystkie okna dialogowe',
        GetCurrentDialog: 'Pobierz bieżące okno dialogowe',
        SaveInLocalStorage: 'Zapisz w LocalStorage',
        SaveInSessionStorage: 'Zapisz w SessionStorage',
        InvoiceNumber: 'Numer faktury',
        AccountNumber: 'Numer konta',
        General: 'Ogólny',
        Authorization: 'Upoważnienie',
        Languages: 'Języki',
        New: 'Nowy',
        Active: 'Aktywny',
        Inactive: 'Nieaktywny',
        WaitForActivation: 'Poczekaj na aktywację',
        ID: 'ID',
        SMS: 'SMS',
        Low: 'Niski',
        Medium: 'Średni',
        High: 'Wysoki',
        Hidden: 'Ukryty',
        Brand: 'Marka',
        Group: 'Grupa',
        ChangeStatus: 'Zmień status',
        AuthMail: 'Uwierzytelnij pocztę',
        SetDomainSettings: 'Ustaw ustawienia domeny',
        Domain: 'Domena*',
        Imap: 'Imap (host:port)*',
        SmtpTLS: 'Smtp TLS (host:port)*',
        SmtpSSL: 'Smtp SSL (host:port)*',
        AttachFile: 'Załącz plik',
        ChannelSettings: 'Ustawienia kanałów',
        GeneralSettings: 'Ustawienia główne',
        WebChatConstructor: 'Konstruktor czatu internetowego',
        BackgroundColor: 'Kolor tła',
        MessageColor: 'Kolor wiadomości',
        URL: 'URL',
        ChannelName: 'Nazwa kanału',
        ChannelURL: 'Adres URL kanału',
        AllowSMS: 'Zezwól na SMS-y',
        AllowShowAllClientDialogs: 'Zezwalaj na wyświetlanie wszystkich okien dialogowych klienta',
        AllowCaptcha: 'Zezwól na Captcha',
        InboxSettings: 'Ustawienia skrzynki odbiorczej',
        DistributionOfNewDialogs: 'Dystrybucja nowych dialogów między agentami online',
        ThereAreNoAvailableMessengers: 'Na kanale nie ma dostępnych komunikatorów! Utwórz je dla marki, do której należy ten kanał!',
        HeaderColorScheme: 'Schemat kolorów nagłówka',
        GreetingColorScheme: 'Powitanie schemat kolorów',
        QuickPhrases: 'Schemat kolorów szybkich fraz',
        ClientMessage: 'Schemat kolorów wiadomości klienta',
        AgentMessage: 'Schemat kolorów wiadomości agenta',
        EndDialogModal: 'Zakończ modalny schemat kolorów okna dialogowego',
        IconColorScheme: 'Schemat kolorów ikon',
        ScrollColorScheme: 'Przewiń schemat kolorów',
        ChatWindowColor: 'Schemat kolorów okna czatu',
        FooterColorScheme: 'Schemat kolorów stopki',
        AuthColorScheme: 'Schemat kolorów Auth',
        HeaderBackground: 'Tło nagłówka',
        HeaderBorderColor: 'Kolor obramowania nagłówka',
        ColorGreetingBotName: 'Powitanie kolor nazwy bota',
        MessageGreetingIcon: 'Tło ikony powitania',
        MessageGreetingColor: 'Kolor tekstu powitania',
        MessageGreetingBorderColor: 'Wiadomość powitalna Kolor obramowania',
        MessageGreetingBackgroundColor: 'Powitanie kolor tła',
        QuickPhrasesColor: 'Szybka fraza Kolor tekstu',
        QuickPhrasesBackground: 'Szybkie tło frazy',
        QuickPhrasesBorderColor: 'Szybka fraza Kolor obramowania',
        QuickPhrasesColorHover: 'Szybka fraza Kolor najechania',
        QuickPhrasesBackgroundHover: 'Szybka fraza Tło najechaniu kursorem',
        QuickPhrasesBorderColorHover: 'Szybka fraza Kolor obramowania po najechaniu kursorem',
        ClientMessageColor: 'Wiadomość od klienta Kolor tekstu',
        ClientTimeColor: 'Kolor czasu klienta',
        ClientMessageBackground: 'Wiadomość od klienta Kolor tła',
        ClientMessageBorderColor: 'Wiadomość od klienta Kolor obramowania',
        ClientLink: 'Łącze klienta',
        ClientLinkHover: 'Najechanie na link klienta',
        ClientActiveLink: 'Aktywny link klienta',
        ClientVisitedLink: 'Odwiedzony przez klienta link',
        ColorAgentNameAndTime: 'Nazwa agenta i kolor czasu',
        MessageAgentColor: 'Kolor wiadomości agenta',
        MessageAgentBackgroundColor: 'Tło wiadomości agenta',
        MessageAgentBorderColor: 'Komunikat agenta Kolor obramowania',
        AgentLink: 'Link do agenta',
        AgentHoverLink: 'Link do agenta Hover',
        AgentActiveLink: 'Aktywne łącze agenta',
        AgentVisitedLink: 'Łącze odwiedzone przez agenta',
        MessageIconBackgroundAgent: 'Kolor tła ikony wiadomości agenta',
        TextColor: 'Kolor tekstu',
        BackdropColor: 'Kolor tła',
        BackdropColorHover: 'Najechanie na kolor tła',
        ButtonColor: 'Kolor przycisku',
        BackdropColorBtn: 'Kolor tła przycisku tła',
        IconBackground: 'Tło ikony',
        ChatWindowsBodyBackground: 'Kolor tła okna czatu',
        ChatWindowsBorderColor: 'Kolor obramowania okien czatu',
        FooterBackground: 'Kolor tła stopki',
        FooterBorderColor: 'Kolor obramowania stopki',
        FooterBackgroundTextArea: 'Kolor tła obszaru tekstu stopki',
        InputBorderColor: 'Wprowadź kolor obramowania',
        RegistrationBackgroundColor: 'Kolor tła rejestracji',
        LabelColor: 'Kolor etykiety',
        LabelErrorColor: 'Kolor błędu etykiety',
        InputColor: 'Kolor wejściowy',
        InputBackground: 'Tło wejściowe',
        SignUpColor: 'Zarejestruj kolor',
        SignUpBackground: 'Zarejestruj kolor tła',
        Chat: 'Czat',
        Minimized: 'Zminimalizowane',
        ScrollBackground: 'Przewiń tło',
        ScrollThumbBackground: 'Przewiń kciuk w tle',
        UniqueClientIdentifier: 'Unikalny identyfikator klienta',
        AgentsSettings: 'Ustawienia agentów',
        ThisAgentSuperAdmin: 'Ten agent jest superadministratorem, kliknij przycisk, aby usunąć uprawnienia superadministratora',
        ThisAgentNotSuperAdmin: 'Ten agent nie jest superadministratorem, kliknij przycisk, aby uczynić go superadministratorem',
        YouCanAssignBrandTheBrand: 'Możesz przypisać markę administratora, do tego musisz wybrać markę',
        Role: 'Rola',
        BanningThisAgent: 'Zablokowanie tego agenta oznacza, że nie będzie on mógł zalogować się na platformę. Nadal będą pojawiać się w systemie.',
        Report: 'Raport',
        SelectLanguageWebChat: 'Wybierz języki Czat internetowy',
        FirstName: 'Imię',
        PhoneNumber: 'Numer telefonu',
        Info: 'Informacje',
        WebChatSettings: 'Ustawienia czatu internetowego',
        IntegrationWithOtherCRM: 'Integracja z innymi CRM',
        SettingUpInteractionBetweenSystems: 'Konfigurowanie interakcji między systemami',
        QuickReplies: 'Szybkie odpowiedzi',
        BotSettings: 'Ustawienia bota',
        BotName: 'Nazwa bota',
        EnabledPrivacyPolicy: 'Włączona polityka prywatności',
        PleaseChooseRequiredFields: 'Proszę wybrać wymagane pola',
        ChangeGreetingsAndPhrases: 'Zmień pozdrowienia i frazę, Polityka prywatności',
        ChangeSmsSettings: 'Zmień ustawienia sms',
        ChangeCountries: 'Zmień kraje',
        ContactsAreWorkingWithClientUniqueness: 'Kontakty działają z unikalnością klienta.Pole jest wymagane, jeśli unikatowość klienta jest z nim równa.',
        ClientUniquenessIs: 'Wyjątkowość klienta to:',
        PrivacyPolicyText: 'Tekst polityki prywatności',
        PrivacyPolicyLink: 'Link do polityki prywatności',
        PrivacyPolicyLinkText: 'Tekst linku do polityki prywatności',
        AgentPhone: 'Telefon agenta',
        HeaderSettings: 'Ustawienia nagłówka',
        DisableDraggable: 'Wyłącz przeciąganie',
        DisableHeaderFullScreen: 'Wyłącz pełny ekran nagłówka',
        DisableHeaderHideChatWindow: 'Wyłącz ukrywanie okna czatu nagłówka',
        DisableHeaderLogo: 'Wyłącz logo nagłówka',
        DisableHeaderShowEndDialogModal: 'Wyłącz modalne okno dialogowe zakończenia wyświetlania nagłówka',
        HeaderText: 'Tekst nagłówka',
        HeaderTextTop: 'Tekst nagłówka u góry',
        HeaderTextBottom: 'Tekst nagłówka na dole',
        HeaderHeight: 'Wysokość nagłówka',
        LogoIconUrl: 'Adres URL ikony logo',
        IconUrl: 'URL ikony',
        IconSettings: 'Ustawienia ikon',
        ScrollSettings: 'Ustawienia przewijania',
        ScrollWidth: 'Szerokość przewijania',
        ChatWindowsLogo: 'Logo okien czatu',
        BackgroundImageSize: 'Rozmiar obrazu tła',
        GreetingSettings: 'Ustawienia powitania',
        GreetingIconBotUrl: 'Ikona powitania URL bota',
        QuickPhrasesSettings: 'Ustawienia szybkich fraz',
        ClientMessageSettings: 'Ustawienia wiadomości klienta',
        AgentMessageSettings: 'Ustawienia wiadomości agenta',
        AgentMessageIconUrl: 'Adres URL ikony wiadomości agenta',
        EndDialogModalSettings: 'Zakończ okno dialogowe Ustawienia modalne',
        AuthWindowSettings: 'Ustawienia okna uwierzytelniania',
        FooterSettings: 'Ustawienia stopki',
        WriteMessagesPlaceholder: 'Napisz wiadomość zastępczą',
        SendImageUrl: 'Wyślij adres URL obrazu',
        SendButtonIconHeight: 'Wysokość ikony przycisku Wyślij',
        SendButtonIconWidth: 'Szerokość ikony przycisku Wyślij',
        ChatWindowSettings: 'Ustawienia okna czatu',
        ClearAllPhrase: 'Wyczyść wszystkie frazy',
        AreYouSure: 'Jesteś pewny?',
        ThisActionWillDeleteAllPhrasesAndAllCategories: 'Ta akcja usunie wszystkie frazy i wszystkie kategorie',
        AddAgentToGroup: 'Dodaj agentów do grupy',
        CreateClient: 'Utwórz klienta',
        ClientsImport: 'Import klientów',
        UploadExcelFile: 'Prześlij plik Excel',
        ClickOnThePaperclipIconToSelectTheExcelFile: 'Kliknij ikonę spinacza, aby wybrać plik Excel',
        Contacts: 'Łączność',
        Personal: 'Osobisty',
        FileUploadHint: 'Prześlij plik Excela wypełniony numerami telefonów. Plik Excel powinien zawierać kolumnę o nazwie „Telefony” i w tej kolumnie należy wpisać numery telefonów. Można importować tylko pliki .xls lub .xlsx.',
        ChangeGreetingsAndPhrasePrivacyPolicy: 'Zmień pozdrowienia i frazę, Polityka prywatności',
    },
    AdditionalText: {
        AgentProfile: 'Profil agenta',
        OutOf: 'poza',
    },
    Pages: {
        Dialogs: {
            Title: 'Dialogi',
            TableColumns: {
                Status: 'Status',
                Dialog: 'Dialog',
                Messenger: 'Posłaniec',
                Channel: 'Kanał',
                Tag: 'Etykietka',
                Agent: 'Agent',
                Rating: 'Ocena',
                Language: 'Język',
                MessagesCount: 'Liczba wiadomości',
                FirstMessage: 'Pierwsza wiadomość',
                LastActivity: 'Ostatnia aktywność',
            },
            Messages: 'Wiadomości',
        },
        AgentGroups: {
            Title: 'Grupy agentów',
            TableColumns: {
                Groups: 'Grupy',
                Description: 'Opis',
                AccessLevel: 'Poziom dostępu',
                WhiteListTags: 'Tagi białej listy',
                CreatedAt: 'Utworzono w',
                UpdatedAt: 'Zaktualizowano w',
            },
        },
        Agents: {
            Title: 'Agenci',
            TableColumns: {
                AgentStatus: 'Status agenta',
                Agents: 'Agenci',
                Email: 'Email',
                Groups: 'Grupy',
                CreatedAt: 'Utworzono w',
                LastActivity: 'Ostatnia aktywność',
            },
        },
        Tags: {
            Title: 'Tagi',
            TableColumns: {
                Tags: 'Tagi',
                Dialogs: 'Dialogi',
                Agents: 'Agenci',
                UsefulLinks: 'Przydatne linki',
                Status: 'Status',
                CreatedAt: 'Utworzono w',
                UpdatedAt: 'Zaktualizowano w',
            },
        },
        Clients: {
            Title: 'Klienci',
            TableColumns: {
                SearchName: 'nazwa',
                AgentName: 'Imię agenta',
                Messenger: 'Posłaniec',
                LastTag: 'Ostatni tag',
                ActiveDialog: 'Ma aktywne okno dialogowe',
                Brand: 'Marka',
                InternalId: 'Identyfikator wewnętrzny',
                ExternalId: 'Identyfikator zewnętrzny',
                Name: 'Nazwa klienta',
                FirstName: 'Imię',
                LastName: 'Nazwisko',
                Email: 'Email',
                InvoiceNumber: 'Numer faktury',
                DialogsCount: 'Liczba okien dialogowych',
                FirstMessage: 'Pierwsza wiadomość',
                LastActivity: 'Ostatnia aktywność',
                Tag: 'Etykietka',
                Status: 'Status',
                Channel: 'Kanał',
                WriteEmail: 'email',
            },
        },
        Channels: {
            Title: 'Kanały',
            TableColumns: {
                ChannelId: 'ID kanału',
                ChannelKey: 'Klawisz kanału',
                ChannelName: 'Nazwa kanału',
                Language: 'Język',
                Url: 'Url',
                CreatedAt: 'Utworzono w',
                UpdatedAt: 'Zaktualizowano w',
            },
        },
        Brands: {
            Title: 'Marki',
            TableColumns: {
                BrandsId: 'Identyfikator marki',
                BrandName: 'Nazwa handlowa',
                ClientUniqueness: 'Wyjątkowość klienta',
                BrandChannels: 'Kanały marki',
                DialogCount: 'Dialogi się liczą',
                CreatedAt: 'Utworzono w',
                UpdatedAt: 'Zaktualizowano w',
            },
        },
        Reports: {
            Title: 'Raporty',
            ByMessengersTab: {
                Title: 'Raport przez posłańców',
                Columns: {
                    Messenger: 'Posłaniec',
                    ReceivedDialogs: 'Otrzymane okna dialogowe',
                    PositiveDialogs: 'Pozytywne dialogi, %',
                    NegativeDialogs: 'Negatywne okna dialogowe, %',
                    UnratedDialogs: 'Okna dialogowe bez oceny, %',
                    AvgResponseTime: 'Średni czas odpowiedzi',
                },
            },
            ByAgentsTab: {
                Title: 'Raport agentów',
                Columns: {
                    agentFullName: 'Imię i nazwisko agenta',
                    agentSystemId: 'Identyfikator systemu agenta',
                    brandName: 'Nazwa handlowa',
                    receivedDialogs: 'Otrzymane dialogi',
                    dialogsTelegram: 'Dialogi Telegram',
                    dialogsWebChat: 'Dialogi Web Chat',
                    dialogsViber: 'Dialogi Viber',
                    dialogsWhatsApp: 'Dialogi WhatsApp',
                    dialogsFacebook: 'Dialogi Facebook',
                    dialogsEmail: 'Dialogi Email',
                    dialogsSms: 'Dialogi SMS',
                    positiveDialogs: 'Pozytywne dialogi, %',
                    negativeDialogs: 'Negatywne okna dialogowe, %',
                    unratedDialogs: 'Okna dialogowe bez oceny, %',
                    avgResponseTime: 'Średni czas odpowiedzi',
                },
                noDataForThisDay: 'Brak danych na ten dzień',
            },
            ByGroupsTab: {
                Title: 'Raport według grup',
                Columns: {
                    groupSystemId: 'Identyfikator systemu grupy',
                    groupName: 'Nazwa grupy',
                    brandName: 'Nazwa handlowa',
                    receivedDialogs: 'Otrzymane dialogi',
                    positiveDialogs: 'Pozytywne dialogi, %',
                    negativeDialogs: 'Negatywne okna dialogowe, %',
                    unratedDialogs: 'Okna dialogowe bez oceny, %',
                    avgResponseTime: 'Średni czas odpowiedzi',
                },
            },
        },
    },
    Buttons: {
        addFilter: 'Dodaj filtr',
        reset: 'Resetowanie',
        createClient: 'Utwórz klienta',
        importClient: 'Importuj klientów',
        createChannel: 'Utwórz kanał',
        previous: 'Poprzedni',
        next: 'Następny',
        addPhrase: 'Dodaj frazę',
        addGroup: 'Dodaj grupę',
        createBrand: 'Stwórz markę',
        addTag: 'Dodaj tag',
        save: 'Zapisać',
        send: 'Wysłać',
        getStatistics: 'Uzyskaj statystyki',
        setCountries: 'Ustaw kraje',
        changeStatus: 'Zmień status',
        upload: 'Wgrywać',
        setDomainSettings: 'Ustaw ustawienia domeny',
        login: 'Zaloguj sie',
        update: 'Aktualizacja',
        resetPassword: 'Zresetuj hasło',
        addNew: 'Dodaj nowe',
        addNewPhrase: 'Dodaj nową frazę',
        addNewCategory: 'Dodaj nową kategorię',
        clearAll: 'Wyczyść wszystko',
        loginWithGoogle: 'Zaloguj się przez Google',
        loginWithFacebook: 'Zaloguj się przez facebook',
        onPage: 'na stronie',
        addNewUsefulLinks: 'Dodaj nowe przydatne linki',
        backToChannels: 'Powrót do kanałów',
        defaultSettings: 'Ustawienia domyślne',
        upgradeChannel: 'Uaktualnij kanał',
        apply: 'Zastosować',
        remove: 'Usunąć',
        removeSuperAdmin: 'Usuń superadministratora',
        makeSuperAdmin: 'Ustaw superadministratora',
        makeBrandAdmin: 'Ustaw administratora marki',
        removeBrandAdmin: 'Usuń administratora marki',
        ban: 'Ban',
        unban: 'Odblokuj',
        makeTeamLead: 'Zostań liderem zespołu',
        removeTeamLead: 'Usuń kierownika zespołu',
        signUp: 'Zapisz się',
        ok: 'Ok',
        cancel: 'Anulować',
        bulkMessaging: 'Wiadomości zbiorcze',
        back: 'Plecy',
    },
    Countries: {
        UnitedStates: 'Stany Zjednoczone',
        Canada: 'Kanada',
        Australia: 'Australia',
        CzechRepublic: 'Republika Czeska',
        Denmark: 'Dania',
        Hungary: 'Węgry',
        Netherlands: 'Holandia',
        Sweden: 'Szwecja',
        UnitedKingdom: 'Zjednoczone Królestwo',
        France: 'Francja',
        Germany: 'Niemcy',
        Poland: 'Polska',
        Italy: 'Włochy',
        Spain: 'Hiszpania',
    },
    Languages: {
        Ukrainian: 'Ukraiński',
        Russian: 'Rosyjski',
        English: 'Angielski',
        Polish: 'Polskie',
        Deutsch: 'Niemiecki',
        Spanish: 'Hiszpański',
        Vietnamese: 'Wietnamski',
        Portuguese: 'Portugalski',
        Korean: 'Koreański',
        Italian: 'Włoski',
        Arabic: 'Arabski',
        French: 'Francuski',
        Azerbaijan: 'Azerbejdżan',
        Hungarian: 'Język węgierski',
    },
    BulkMessaging: {
        exclude: 'Wykluczać',
        typeText: 'Wpisz tekst',
        selectClients: 'Wybierz klientów',
        uploadClients: 'Prześlij klientów',
        sendingMessagesToNewClients: 'Wysyłanie wiadomości do nowych klientów',
        sendingMessagesToExistingClients: 'Wysyłanie wiadomości do istniejących klientów',
        clientsWhoHaveDialogsWithFollowingStatus: 'Klienci, którzy mają okna dialogowe o następującym statusie',
        clientsWhoHaveThisTextInNameOrLastNameOrUserName: 'Klienci, którzy mają ten tekst w imieniu lub nazwisku lub nazwie użytkownika',
        limitQuantity: 'Limit ilości',
        quantityLimit: 'Limit ilościowy',
        limitQuantityText: 'Ogranicz ilość w audycji do nie większej niż określona liczba odbiorców. Nowi klienci systemu trafią na listę.',
        ClientsWhoWroteToAskMeBeforeLastMessageTime: 'Klienci, którzy napisali do AskMe przed ostatnią wiadomością',
    },
};
export default Messages;
